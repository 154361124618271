import {Component, OnInit} from '@angular/core';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {ToastrService} from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {HttpClient} from '@angular/common/http';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
    selector: 'app-order-report',
    templateUrl: './order-report.component.html',
    styleUrls: ['./order-report.component.css']
})
export class OrderReportComponent implements OnInit {

    pendingOrders = [];

    selectedDate: NgbDateStruct;
    date: { year: number, month: number };

    fromDate;
    toDate;

    dsr_fromDate;
    dsr_toDate;

    opr_fromDate;
    opr_toDate;

    rej_fromDate;
    rej_toDate;

    item_fromDate;
    item_toDate;

    event_fromDate;
    event_toDate;

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService,
        private toaster: ToastrService,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {

        this.dsr_fromDate = this.utilService.getTodayByTimeObj();
        this.dsr_toDate = this.utilService.getTodayByTimeObj();

        this.opr_fromDate = this.utilService.getTodayByTimeObj();
        this.opr_toDate = this.utilService.getTodayByTimeObj();

        this.rej_fromDate = this.utilService.getTodayByTimeObj();
        this.rej_toDate = this.utilService.getTodayByTimeObj();

        this.item_fromDate = this.utilService.getTodayByTimeObj();
        this.item_toDate = this.utilService.getTodayByTimeObj();

        this.event_fromDate = this.utilService.getTodayByTimeObj();
        this.event_toDate = this.utilService.getTodayByTimeObj();

        // this.getChefPendingOrders();
        this.getConstants()
    }

    commission = 0;
    delivery_charges_gst = 0;
    packaged_gst = 0;
    cooked_gst = 0;

    getConstants() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'common/getConstants').then((result) => {
            if (result.status) {
                this.commission = result.result.commission;
                this.delivery_charges_gst = result.result.delivery_charges_gst;
                this.cooked_gst = result.result.cooked_gst;
                this.packaged_gst = result.result.packaged_gst;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }


    rejectionReport() {
        let fromDate = this.rej_fromDate.year + '-' + this.rej_fromDate.month + '-' + this.rej_fromDate.day;
        let toDate = this.rej_toDate.year + '-' + this.rej_toDate.month + '-' + this.rej_toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'order/getAllRejectedOrdersByDate', post).then((result) => {
            if (result.status) {

                if (result.result.length > 0) {
                    let orders = [];
                    for (let i = 0; i < result.result.length; i++) {

                        let customer_name = '-';
                        if (result.result[i].hasOwnProperty('chef_detail') && result.result[i].chef_detail != null) {
                            customer_name = result.result[i].customer_detail.name;
                        }
                        let chef_name = '-'
                        if (result.result[i].hasOwnProperty('chef_detail') && result.result[i].chef_detail != null) {
                            chef_name = result.result[i].chef_detail.name;
                        }


                        let order = {
                            'Order No.': result.result[i].uid,
                            'Order Date': this.utilService.getDateTimeByTime(result.result[i].created_at),
                            'Transaction No.': result.result[i].razorpay_id,
                            'Chef Name': chef_name,
                            'Customer Name': customer_name,
                            'Total Price': result.result[i].total_price,
                            'Chef Amount': result.result[i].chef_price
                        }
                        orders.push(order)
                    }
                    this.exportAsExcelFileCheck(orders, 'Order Rejection, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate))
                    // this.saveReport(orders, 'Order Rejection, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'order_rejection');
                }
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })
    }

    downloadSalesReport() {
        let fromDate = this.dsr_fromDate.year + '-' + this.dsr_fromDate.month + '-' + this.dsr_fromDate.day;
        let toDate = this.dsr_toDate.year + '-' + this.dsr_toDate.month + '-' + this.dsr_toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'order/getAllOrdersByDate', post).then((result) => {
            if (result.status) {
                let orders = [];
                for (let i = 0; i < result.result.length; i++) {
                    if (result.result[i].chef_id != null) {
                        orders.push(result.result[i])
                    }
                }
                this.downloadDailyReport(orders, post);
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })

    }

    downloadDailyReport(data, post) {
        let orders = [];
        for (let i = 0; i < data.length; i++) {
            let quantity = 0;
            let itemDescription = '';
            for (let j = 0; j < data[i].orderItems.length; j++) {
                quantity += data[i].orderItems[j].quantity;
                if (j == 0) {
                    itemDescription = data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                } else {
                    itemDescription += ' , ' + data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                }
            }

            let invoicenumber = '';
            if (data[i].invoice_number != null && data[i].invoice_number != undefined && data[i].invoice_number != '') {
                invoicenumber = data[i].invoice_number;
            }

            let customer_name = '-';
            let customer_phone = '-';
            if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                customer_name = data[i].customer_detail?.name;
                customer_phone = data[i].customer_detail?.phone;
            }
            let chef_name = '-'
            let chef_phone = '-'
            if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                chef_name = data[i].chef_detail.name;
                chef_phone = data[i].chef_detail.phone;
            }

            let order = {
                'Chef Name': chef_name,
                'Customer Name': customer_name,
                'Item Description': itemDescription,
                'Order Date': this.utilService.getDateTimeByTime(data[i].created_at),
                'Delivery Date': this.utilService.getDateTimeByTime(data[i].delivery_at),
                'Order Quantity': quantity,
                'Order Id': data[i].uid,
                'Transaction Id': data[i].razorpay_id,
                'Invoice Number': invoicenumber,
                'Item Price': data[i].base_price,
                'Chef Price': data[i].chef_price,
                'Delivery Charges': data[i].delivery_charges,
                // 'GST on Delivery': data[i].delivery_service_tax,
                // 'GST': data[i].tax,
                // 'Total Price': data[i].total_price
            }


            if (data[i].hasOwnProperty('order_address') && data[i].order_address != null && data[i] != undefined
                && data[i].order_address.formatted_address.toLowerCase().includes('haryana')
            ) {
                order['Delivery IGST'] = '-';
                order['Delivery SGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)
                order['Delivery CGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)

                order['IGST'] = '-';
                order['SGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
                order['CGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
            } else {
                order['Delivery IGST'] = Number((data[i].delivery_charges * (this.delivery_charges_gst / 100)).toFixed(2));
                order['Delivery SGST'] = '-'
                order['Delivery CGST'] = '-'

                order['IGST'] = data[i].tax
                order['SGST'] = '-'
                order['CGST'] = '-'
            }

            order['Total Price'] = data[i].total_price;


            orders.push(order);
        }
        this.exportAsExcelFileCheck(orders, 'Order Sales Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate))
        // this.saveReport(orders, 'Order Sales Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'daily_sales_report');
    }

    downloadDailyItemReport(data, post) {
        // let finalOrderWithItem
        let orders = [];
        for (let i = 0; i < data.length; i++) {
            // let quantity = 0;
            // let itemDescription = '';
            for (let j = 0; j < data[i].orderItems.length; j++) {
                // quantity += data[i].orderItems[j].quantity;
                // if (j == 0) {
                let itemDescription = data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                // } else {
                //   itemDescription += ' , ' + data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                // }

                let invoicenumber = '';
                if (data[i].invoice_number != null && data[i].invoice_number != undefined && data[i].invoice_number != '') {
                    invoicenumber = data[i].invoice_number;
                }

                let customer_name = '-';
                let customer_phone = '-';
                if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                    customer_name = data[i].customer_detail?.name;
                    customer_phone = data[i].customer_detail?.phone;
                }
                let chef_name = '-'
                let chef_phone = '-'
                let chef_commission='-'
                if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                    chef_name = data[i].chef_detail.name;
                    chef_phone = data[i].chef_detail.phone;
                    chef_commission = data[i].chef_detail.commission;
                }
                console.log(data[i].orderItems[j],"commission")
                let order = {
                    'Chef Name': chef_name,
                    'Customer Name': customer_name,
                    'Item Description': itemDescription,
                    'Order Date': this.utilService.getDateTimeByTime(data[i].created_at),
                    'Delivery Date': this.utilService.getDateTimeByTime(data[i].delivery_at),
                    'Order Quantity': data[i].orderItems[j].quantity,
                    'Order Id': data[i].uid,
                    'Transaction Id': data[i].razorpay_id,
                    'Invoice Number': invoicenumber,
                    'Item Price': data[i].orderItems[j].price,
                    'Chef Price': this.utilService.getChefOrderPrice(data[i].orderItems[j].price, data[i].orderItems[j].commission),
                    'Delivery Charges': '',
                    // 'GST on Delivery': '',
                    'Delivery IGST': '',
                    'Delivery SGST': '',
                    'Delivery CGST': '',
                    'IGST': '',
                    'SGST': '',
                    'CGST': '',
                    'Total Price': '',
                    'Delivery IGST %': '',
                    'Delivery SGST %': '',
                    'Delivery CGST %': '',
                    'IGST %': '',
                    'SGST %': '',
                    'CGST %': ''
                }

                if (data[i].hasOwnProperty('order_address') && data[i].order_address != null && data[i] != undefined
                    && data[i].order_address.formatted_address.toLowerCase().includes('haryana')
                ) {


                    if (data[i].orderItems[j].category == 1) {
                        order['IGST %'] = '-';
                        order['SGST %'] = (this.packaged_gst / 2).toFixed(2)
                        order['CGST %'] = (this.packaged_gst / 2).toFixed(2)

                        order['IGST'] = '-';
                        order['SGST'] = (((data[i].orderItems[j].price) * (this.packaged_gst / 100)) / 2).toFixed(2);
                        order['CGST'] = (((data[i].orderItems[j].price) * (this.packaged_gst / 100)) / 2).toFixed(2);
                    } else {
                        order['IGST %'] = '-';
                        order['SGST %'] = (this.cooked_gst / 2).toFixed(2)
                        order['CGST %'] = (this.cooked_gst / 2).toFixed(2)

                        order['IGST'] = '-';
                        order['SGST'] = (((data[i].orderItems[j].price) * (this.cooked_gst / 100)) / 2).toFixed(2);
                        order['CGST'] = (((data[i].orderItems[j].price) * (this.cooked_gst / 100)) / 2).toFixed(2);
                    }
                } else {
                    if (data[i].orderItems[j].category == 1) {
                        order['IGST %'] = this.packaged_gst.toString();
                        order['SGST %'] = '-'
                        order['CGST %'] = '-'

                        order['IGST'] = ((data[i].orderItems[j].price) * (this.packaged_gst / 100)).toFixed(2);
                        order['SGST'] = '-';
                        order['CGST'] = '-';
                    } else {
                        order['IGST %'] = this.cooked_gst.toString();
                        order['SGST %'] = '-'
                        order['CGST %'] = '-'

                        order['IGST'] = ((data[i].orderItems[j].price) * (this.cooked_gst / 100)).toFixed(2);
                        order['SGST'] = '-';
                        order['CGST'] = '-';
                    }
                }

                if ((j + 1) == data[i].orderItems.length) {
                    order['Delivery Charges'] = data[i].delivery_charges
                    // order['GST on Delivery'] = data[i].delivery_service_tax;


                    if (data[i].hasOwnProperty('order_address') && data[i].order_address != null && data[i] != undefined
                        && data[i].order_address.formatted_address.toLowerCase().includes('haryana')
                    ) {
                        order['Delivery IGST'] = '-';
                        order['Delivery SGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)
                        order['Delivery CGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)

                        order['Total Price'] = data[i].total_price;

                        order['Delivery IGST %'] = '-';
                        order['Delivery SGST %'] = (this.delivery_charges_gst / 2).toFixed(2)
                        order['Delivery CGST %'] = (this.delivery_charges_gst / 2).toFixed(2)


                    } else {
                        order['Delivery IGST'] = (data[i].delivery_charges * (this.delivery_charges_gst / 100)).toFixed(2);
                        order['Delivery SGST'] = '-'
                        order['Delivery CGST'] = '-'

                        order['Total Price'] = data[i].total_price;

                        order['Delivery IGST %'] = this.delivery_charges_gst.toString();
                        order['Delivery SGST %'] = '-'
                        order['Delivery CGST %'] = '-'


                    }
                }


                orders.push(order);
            }
        }

        console.log('orders:-' + JSON.stringify(orders));
        this.exportAsExcelFileCheck(orders, 'Order Sales Item Wise Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate) + 'Daily_sales_item_report_' + this.utilService.getTodayDate())
        // this.saveReport(orders, 'Order Sales Item Wise Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'Daily_sales_item_report_' + this.utilService.getTodayDate())
    }

    downloadChefMasterData() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'user/getAllChefsDetails').then((result) => {
            if (result.status) {

                let chefs = [];

                for (let i = 0; i < result.result.length; i++) {
                    let accountNum = '';
                    let ifsc = '';
                    let pan = '';
                    if (result.result[i].bank_detail != null) {
                        accountNum = result.result[i].bank_detail.acc_no;
                        ifsc = result.result[i].bank_detail.ifsc_code;
                        pan = result.result[i].bank_detail.pan_card;
                    }

                    let address = '';
                    if (result.result[i].location != null) {
                        address = result.result[i].location.address;
                    }

                    let status = '';
                    if (result.result[i].status == 0) {
                        status = 'Disabled'
                    } else {
                        status = 'Enabled'
                    }
console.log(result.result[i].name,"chef name");

                    let chef = {
                        'Chef Name': result.result[i].name,
                        'Chef Address': address,
                        'Chef Phone Number': result.result[i].phone,
                        'Bank Account Number': accountNum,
                        'IFSC Code Number': ifsc,
                        'Pan Number': pan,
                        'Status': status,
                        'Chef Commission %': result.result[i].commission
                    }

                    chefs.push(chef);
                }

                this.exportAsExcelFile(chefs, 'chef_master_' + this.utilService.getTodayDate())
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    getDatetime() {
        if (this.selectedDate != null) {
            return this.selectedDate.year + '-' + this.selectedDate.month + '-' + this.selectedDate.day
        } else {
            return '-';
        }
    }

    getChefPendingOrders() {
        this.pendingOrders = [];
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefPendingOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                console.log(result)
                this.pendingOrders = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    itemWiseSalesReport() {
        let fromDate = this.item_fromDate.year + '-' + this.item_fromDate.month + '-' + this.item_fromDate.day;
        let toDate = this.item_toDate.year + '-' + this.item_toDate.month + '-' + this.item_toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'order/getAllOrdersByDate', post).then((result) => {
            if (result.status) {
                this.downloadDailyItemReport(result.result, post);
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })
    }

    downloadExel(value) {
        if (value == 'order_report') {
            if (this.pendingOrders.length == 0) {
                this.toaster.error('No Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.pendingOrders.length; i++) {

                let quantity = 0;
                for (let j = 0; j < this.pendingOrders[i].orderItems.length; j++) {
                    quantity += this.pendingOrders[i].orderItems[j].quantity;
                }

                let order = {
                    OrderNumber: this.pendingOrders[i].uid,
                    TotalItems: this.pendingOrders[i].total_items,
                    Quantity: quantity,
                    created_at: this.pendingOrders[i].created_at,
                    amount: this.pendingOrders[i].total_price,
                    net_amount: '',
                    cus_name: this.pendingOrders[i].total_price,
                    cus_order_address: this.pendingOrders[i].order_address,
                    cus_phone: this.pendingOrders[i].cus_phone,
                    delivery_at: this.pendingOrders[i].delivery_at,
                    accepted_datetime: '',
                    rejected_datetime: '',
                }

                // // this is for user type 2
                // if (this.utilService.getUserType() == '2') {
                //   if (this.pendingOrders[i].hasOwnProperty('chef_location') && this.pendingOrders[i].chef_location != null) {
                //     order['PickupAddress'] = this.pendingOrders[i].chef_location.address;
                //   } else {
                //     order['PickupAddress'] = '-'
                //   }
                // }

                // order['Chef'] = this.pendingOrders[i].chef_name;
                // order['ChefPhone'] = this.pendingOrders[i].chef_phone;
                // order['Customer'] = this.pendingOrders[i].cus_name;

                // // this is for user type 2
                // if (this.utilService.getUserType() == '2') {
                //   order['CustomerPhone'] = this.pendingOrders[i].cus_phone;
                //   if (this.pendingOrders[i].hasOwnProperty('order_address') && this.pendingOrders[i].order_address != null) {
                //     order['DeliveryAddress'] = this.pendingOrders[i].order_address.address;
                //   } else {
                //     order['DeliveryAddress'] = '-'
                //   }
                // }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'Order_report')
        }
        if (value == 'rejection_report') {
            if (this.pendingOrders.length == 0) {
                this.toaster.error('No Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.pendingOrders.length; i++) {

                let quantity = 0;
                for (let j = 0; j < this.pendingOrders[i].orderItems.length; j++) {
                    quantity += this.pendingOrders[i].orderItems[j].quantity;
                }

                let order = {
                    rejected_datetime: '',
                    amount: this.pendingOrders[i].total_price,
                    net_amount: '',
                    mode_of_payment: this.pendingOrders[i].razorpay_id,
                    transaction_no: '',
                    OrderNumber: this.pendingOrders[i].uid,
                    cus_name: this.pendingOrders[i].cus_name,
                    cus_order_address: this.pendingOrders[i].order_address,
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'rejection_report')
        }

        if (value == 'daily_sales_report') {
            if (this.pendingOrders.length == 0) {
                this.toaster.error('No Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.pendingOrders.length; i++) {

                let quantity = 0;
                let order_names = ''
                for (let j = 0; j < this.pendingOrders[i].orderItems.length; j++) {
                    quantity += this.pendingOrders[i].orderItems[j].quantity;
                    order_names += this.pendingOrders[i].orderItems[j].name + ', ';
                }

                let order = {
                    cus_name: this.pendingOrders[i].cus_name,
                    city: this.pendingOrders[i].order_address,
                    item_Des: order_names,
                    order_qty: quantity,
                    Rate: this.pendingOrders[i].total_price,
                    Value: '',
                    order_date: this.pendingOrders[i].updated_at,
                    delivery_datetime: this.pendingOrders[i].delivery_at,
                    delivery_charges: this.pendingOrders[i].delivery_charges,
                    transaction_id: '',
                    GST_breakup: '',
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'daily_sales_report')
        }

        if (value == 'cost_report') {
            if (this.pendingOrders.length == 0) {
                this.toaster.error('No Orders Found');
                return
            }
            let orders = [];
            let order_names = ''
            for (let i = 0; i < this.pendingOrders.length; i++) {

                let quantity = 0;
                for (let j = 0; j < this.pendingOrders[i].orderItems.length; j++) {
                    quantity += this.pendingOrders[i].orderItems[j].quantity;
                    order_names += this.pendingOrders[i].orderItems[j].name + ', ';
                }

                let order = {
                    cus_name: this.pendingOrders[i].cus_name,
                    city: this.pendingOrders[i].order_address,
                    item_Des: order_names,
                    order_qty: quantity,
                    Rate: this.pendingOrders[i].total_price,
                    Value: '',
                    order_date: this.pendingOrders[i].updated_at,
                    delivery_datetime: this.pendingOrders[i].delivery_at,
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'cost_report')
        }
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
    }

    getOrderPlacedData() {
        let fromDate = this.opr_fromDate.year + '-' + this.opr_fromDate.month + '-' + this.opr_fromDate.day;
        let toDate = this.opr_toDate.year + '-' + this.opr_toDate.month + '-' + this.opr_toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'order/getAllOrdersByDate', post).then((result) => {
            if (result.status) {
                if (result.result.length > 0) {
                    let orders = [];
                    for (let i = 0; i < result.result.length; i++) {
                        let customer_name = '-';
                        let customer_phone = '-';
                        if (result.result[i].hasOwnProperty('chef_detail') && result.result[i].chef_detail != null) {
                            customer_name = result.result[i].customer_detail?.name;
                            customer_phone = result.result[i].customer_detail?.phone;
                        }
                        let chef_name = '-'
                        let chef_phone = '-'
                        if (result.result[i].hasOwnProperty('chef_detail') && result.result[i].chef_detail != null) {
                            chef_name = result.result[i].chef_detail.name;
                            chef_phone = result.result[i].chef_detail.phone;
                        }
                        let order = {
                            'Order Date': this.utilService.getDateTimeByTime(result.result[i].created_at),
                            'Order No.': result.result[i].uid,
                            'Chef Name': chef_name,
                            'Chef Phone Number': chef_phone,
                            'Item Price': result.result[i].base_price,
                            'Total Price': result.result[i].total_price,
                            'Customer Name': customer_name,
                            'Customer Phone': customer_phone,
                            'Date of Delivery': this.utilService.getDateTimeByTime(result.result[i].delivery_at),
                        }
                        orders.push(order)
                    }
                    this.exportAsExcelFileCheck(orders, 'Order Placed, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate))
                    // this.exportAsExcelFile(orders, 'order_placed_report')
                    // this.saveReport(orders, 'Order Placed, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'order_placed');
                }
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })
    }
    public exportAsExcelFileCheck(json: any[], excelFileName: string): void {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFilecheck(excelBuffer, excelFileName);
    }

    private saveAsExcelFilecheck(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
    }
    saveReport(data, reportTitle, reportName) {
        let cellValue = [];
        let merge = [];


        // merge.push("A2:D2");

        cellValue.push({
            cell: 'A1',
            value: reportTitle
        })

        let keys = Object.keys(data[0]);

        merge.push('A1:' + this.utilService.getKeys(keys.length) + '1');

        for (let i = 0; i < keys.length; i++) {
            cellValue.push({
                cell: this.utilService.getKeys(i + 1) + 2,
                value: keys[i]
            })
        }

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                let index = 3 + i;
                cellValue.push({
                    cell: this.utilService.getKeys(j + 1) + index,
                    value: data[i][keys[j]]
                })
            }
        }
        this.fetchReport(merge, cellValue, reportName + '_' + this.utilService.formatReportName());
    }

    private fetchReport(merge, cellValue, name) {
        let report_name = name + this.utilService.formatReportName() + '.xlsx';

        let postData = {
            merge: merge,
            cellValues: cellValue,
            file_name: report_name
        }

        this.http.post('https://theshakehand.com/chef_php/index.php/Report/generateReportExcel', postData, {responseType: 'blob'}).subscribe(blob => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = report_name;
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    }

    eventDownloadReport() {
        let fromDate = this.event_fromDate.year + '-' + this.event_fromDate.month + '-' + this.event_fromDate.day;
        let toDate = this.event_toDate.year + '-' + this.event_toDate.month + '-' + this.event_toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'events/getAllOrdersByDate', post).then((result) => {
            if (result.status) {
                let orders = [];
                //0=order placed, 1=accepted by chef, 2= declined by chef, 3= cancelled by user, 4=cancelled by chef, 5=ready,6=delivered,7=pickup
                for (let i = 0; i < result.result.length; i++) {
                    if (result.result[i].event_id != null) {
                        orders.push(result.result[i])
                    }
                }
                if (orders.length > 0) {
                    this.downloadEventDailyReport(orders, post);
                } else {
                    this.toaster.error('No Orders Found')
                }

            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })
    }

    downloadEventDailyReport(data, post) {
        console.log(data)
        let orders = [];
        for (let i = 0; i < data.length; i++) {
            let quantity = 0;

            let invoicenumber = '';
            if (data[i].invoice_number != null && data[i].invoice_number != undefined && data[i].invoice_number != '') {
                invoicenumber = data[i].invoice_number;
            }

            let customer_name = '-';
            let customer_phone = '-';
            if (data[i].hasOwnProperty('customer_detail') && data[i].customer_detail != null) {
                customer_name = data[i].customer_detail?.name;
                customer_phone = data[i].customer_detail?.phone;
            }
            let chef_name = '-'
            let itemDescription = '-'
            if (data[i].hasOwnProperty('chef_location') && data[i].chef_location != null) {
                chef_name = data[i].chef_location.name;
                itemDescription = data[i].chef_location.description;
            }

            let order = {
                'Event Name': chef_name,
                'Customer Name': customer_name,
                'Item Description': itemDescription,
                'Order Date': this.utilService.getDateTimeByTime(data[i].created_at),
                'Delivery Date': this.utilService.getDateTimeByTime(data[i].delivery_at),
                'Order Quantity': data[i].total_items,
                'Order Id': data[i].uid,
                'Transaction Id': data[i].razorpay_id,
                'Invoice Number': invoicenumber,
                'Item Price': data[i].base_price,
                // 'Chef Price': data[i].chef_price,
                'Delivery Charges': data[i].delivery_charges,
                // 'GST on Delivery': data[i].delivery_service_tax,
                // 'GST': data[i].tax,
                // 'Total Price': data[i].total_price
            }


            if (data[i].hasOwnProperty('chef_location') && data[i].chef_location != null && data[i].chef_location != undefined) {
                if (data[i].hasOwnProperty('order_address') && data[i].order_address != null && data[i] != undefined
                    && data[i].order_address.formatted_address.toLowerCase().includes('haryana')
                ) {
                    order['Delivery IGST'] = '-';
                    order['Delivery SGST'] = ((Number(data[i].delivery_charges * (data[i].chef_location.delivey_gst / 100))) / 2).toFixed(2)
                    order['Delivery CGST'] = ((Number(data[i].delivery_charges * (data[i].chef_location.delivey_gst / 100))) / 2).toFixed(2)

                    order['IGST'] = '-';
                    order['SGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
                    order['CGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
                } else {
                    order['Delivery IGST'] = Number((data[i].delivery_charges * (data[i].chef_location.delivey_gst / 100)).toFixed(2));
                    order['Delivery SGST'] = '-'
                    order['Delivery CGST'] = '-'

                    order['IGST'] = data[i].tax
                    order['SGST'] = '-'
                    order['CGST'] = '-'
                }
            }


            order['Total Price'] = data[i].total_price;


            orders.push(order);
        }
        this.exportAsExcelFile(orders, 'Order Sales Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate))
        // this.saveReport(orders, 'Order Sales Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'event_report');
    }

}
