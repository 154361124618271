import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {EditOrderComponent} from '../../modals/edit-order/edit-order.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChefBusinessUpdateComponent} from '../../modals/chef-business-update/chef-business-update.component';
import {ChefProspectUpdateComponent} from '../../modals/chef-prospect-update/chef-prospect-update.component';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

    allAlerts = []
    declinedAlerts = []

    allTab = true;
    declinedTab = false;
    AlertCount=[]
    READ_NOTIFICATION_CSS = 'readNotification';
    UNREAD_NOTIFICATION_CSS = 'unreadNotification';

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.getAlertsByUserId();
    }

    getAlertsByUserId() {
        let user_id = '';
        if (this.utilService.getUserType() == '1') {
            user_id = this.utilService.getUserID();
        } else {
            user_id = '0';
        }
        this.declinedAlerts = [];
        this.allAlerts = [];
        this.AlertCount=[]
        this.apiService.getAPI(this.apiService.BASE_URL + 'notification/getNotificationByUserId/' + user_id).then((result) => {
            if (result.status) {
                let notifications = [];
                if (this.utilService.getUserType() == '2') {
                    for (let i = 0; i < result.result.length; i++) {
                        if (
                            result.result[i].type == 1
                            || result.result[i].type == 2
                            || result.result[i].type == 3
                            || result.result[i].type == 4
                            || result.result[i].type == 5
                            || result.result[i].type == 14
                        ) {
                            notifications.push(result.result[i])
                        }
                    }
                } else {
                    notifications = result.result;
                }

                for (let i = 0; i < notifications.length; i++) {
                    if (notifications[i].type == 3) {
                        this.declinedAlerts.push(notifications[i]);
                    }
                    this.allAlerts.push(notifications[i]);
                    if (notifications[i].read_status == 0){
                        this.AlertCount.push(notifications[i])
                    // console.log((this.AlertCount).length,"alerts");
                    }
                    this.utilService.SharingAlertData.next((this.AlertCount).length);
                }
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })

    }

    openAlert(item: any) {
        if (item.type == 11 || item.type == 12 || item.type == 13) {
            if (item.user_id == 0) {
                let data = JSON.parse(item.data);
                if (data.type == 0) {
                    this.openProspectChef(data.id)
                } else {
                    this.openBusinessChef(data.id)
                }
            }
        } else {
            const modalRef = this.modalService.open(EditOrderComponent, {
                backdrop: 'static',
                size: 'xl',
                keyboard: false,
                centered: true
            });
            modalRef.closed.subscribe((result) => {
                this.getAlertsByUserId()
            })
            modalRef.componentInstance.orderId = item.order_id;
        }

        this.setNotificationReadStatus(item);
    }

    setNotificationReadStatus(item) {
        this.apiService.getAPI(this.apiService.BASE_URL + 'notification/setNotificationReadStatus/' + item.id).then((result) => {
            if (result.status) {
                item.read_status = 1;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    openBusinessChef(id: any) {
        const modalRef = this.modalService.open(ChefBusinessUpdateComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
        })
        modalRef.componentInstance.chefStatusId = id;
    }

    openProspectChef(id: any) {
        const modalRef = this.modalService.open(ChefProspectUpdateComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
        })
        modalRef.componentInstance.chefStatusId = id;
    }

    getNotificationStyle(read_status: number) {
        if (read_status == 0) {
            return this.UNREAD_NOTIFICATION_CSS;
        } else {
            return this.READ_NOTIFICATION_CSS;
        }
    }

    openDeclinedAlerts() {
        this.allTab = false;
        this.declinedTab = true;
        this.getAlertsByUserId()
    }

    openAllAlerts() {
        this.allTab = true;
        this.declinedTab = false;
        this.getAlertsByUserId()
    }
}
