import {Component, OnInit} from '@angular/core';
import {ApiService} from './service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UtilService} from './service/util.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.utilService.setItem(this.utilService.PANEL_TYPE, this.utilService.DEFAULTSTORAGE);
        // this.utilService.setItem(this.utilService.PANEL_TYPE, this.utilService.ADMIN_PANEL);
        // this.utilService.setItem(this.utilService.PANEL_TYPE, this.utilService.CHEF_PANEL);
        if (this.utilService.isUserLoggedIn()) {
            // this.router.navigateByUrl('/dashboard')
        } else {
            this.router.navigateByUrl('/login')
        }
    }


}
