import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {UtilService} from '../../service/util.service';

declare var google;

@Component({
    selector: 'app-edit-order',
    templateUrl: './edit-order.component.html',
    styleUrls: ['./edit-order.component.css']
})
export class EditOrderComponent implements OnInit {

    @Input() orderId;
    orderDetail = null;
    default_food_image = 'assets/img/default_food.png';

    customer_name = '';
    event_name = '';
    delivery_dt = '';
    pickup_dt = '';
    total_items = '';
    comment = '';
    amount = '';
    declined_reason = '';
    decline_reason = '';

    driver_name = '';
    driver_phone = '';

    orderItems = [];
    userType = '';
    commission = 0;
    currentDate = new Date();
    newDate=""
    DateTrue:boolean =true;

    @ViewChild('gmap', {static: false}) gmapElement: ElementRef;
    map: any;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        public utilService: UtilService,
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.userType = this.utilService.getUserType();
        
    }

    ngAfterViewInit() {
        if (this.utilService.getUserType() == '2') {
            const mapOptions = {
                zoom: 15,
                center: new google.maps.LatLng(28.5272803, 77.0688997)
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
            this.map.addListener('click', (mapsMouseEvent) => {
                let latlng = mapsMouseEvent.latLng;
                console.log(latlng.lat() + ',' + latlng.lng());
                // this.getReverseGeocodingData(latlng.lat(), latlng.lng());
            });
        }
        if (this.orderId != null) {
            if (this.utilService.getUserType() == '2') {
                this.getOrderFullDetail()
            } else {
                this.getConstants()
            }
        }
    }

    getConstants() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'common/getConstants').then((result) => {
            if (result.status) {
                this.commission = result.result.commission;
                this.getOrderFullDetail();
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    getOrderFullDetail() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/getOrderById/' + this.orderId).then((result) => {
            if (result.status) {
                this.setOrder(result.result);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    setOrder(order) {
        this.orderDetail = order;
        this.orderItems = order.orderItems;

        this.customer_name = this.orderDetail.customer_detail.name;
        // try{
        //     console.log("delivery dt:-"+this.orderDetail.delivery_at);
        //     let dotDT=this.orderDetail.delivery_at.split(".")[0];
        //     let deliverydt=dotDT.split("T")[0]+" "+dotDT.split("T")[1];
        //     console.log("deliverydt:-"+deliverydt);
        //     this.delivery_dt = this.utilService.getDateTimeByTime(deliverydt);
        //     this.pickup_dt = this.utilService.get1HourBeforeDT(deliverydt);
        // }catch (e) {
        console.log('delivery dt:-' + this.orderDetail.delivery_at);
        var startTimeISOString = this.orderDetail.delivery_at;
        var startTime = new Date(startTimeISOString );
        startTime = new Date( startTime.getTime() + ( startTime.getTimezoneOffset() * 60000 ) ); 
        if (startTime < new Date()){
            // console.log(startTime,"deepak");
            // console.log(new Date(),"time");
            this.DateTrue=false;
        }

        this.delivery_dt = this.utilService.getAdminDateTimeByTime(this.orderDetail.delivery_at);
        this.pickup_dt = this.utilService.getOrder1HourBeforeDT(this.orderDetail.delivery_at);
        // }
      
        this.total_items = this.orderDetail.total_items;
        this.comment = this.orderDetail.comment;
        this.driver_name = this.orderDetail.driver_name;
        this.driver_phone = this.orderDetail.driver_phone;
        this.user_phone = this.orderDetail.customer_detail.phone;

        if(this.orderDetail.event_id!=null){
            this.event_name=this.orderDetail.chef_location.name
        }

        if(this.orderDetail.chef_id!=null){
            this.chef_name = this.orderDetail.chef_detail.name;
            this.chef_phone = this.orderDetail.chef_detail.phone;
        }
        this.declined_reason = this.orderDetail.decline_reason;
        if (this.utilService.getUserType() == '2') {
            this.amount = 'Rs ' + this.orderDetail.total_price;
        } else {
            // this.amount = 'Rs ' + this.utilService.getChefOrderPrice(this.orderDetail.base_price, this.commission);
            this.amount = 'Rs ' + this.orderDetail.chef_price;
        }

        for (let i = 0; i < this.orderItems.length; i++) {
            if (this.orderItems[i].images != null && this.orderItems[i].images != '') {
                let image = JSON.parse(this.orderItems[i].images)[0];
                if (image != null && image != undefined && image != '') {
                    let foodImage = this.apiService.BASE_IMAGE_URL + image;
                    this.orderItems[i].food_image = foodImage;
                } else {
                    this.orderItems[i].food_image = '';
                }
            } else {
                this.orderItems[i].food_image = '';
            }

            if (this.orderItems[i].qty_type != null && this.orderItems[i].qty_type != '') {
                switch (this.orderItems[i].qty_type) {
                    // case "1=gm,2=kg,3=pcs,4=l,5=ml"
                    case 1:
                        this.orderItems[i]['quantity_type'] = 'gm';
                        break;
                    case 2:
                        this.orderItems[i]['quantity_type'] = 'kg';
                        break;
                    case 3:
                        this.orderItems[i]['quantity_type'] = 'pcs';
                        break;
                    case 4:
                        this.orderItems[i]['quantity_type'] = 'l';
                        break;
                    case 5:
                        this.orderItems[i]['quantity_type'] = 'ml';
                        break;
                }
            }
        }
        if (this.utilService.getUserType() == '2' && this.orderDetail.hasOwnProperty('order_address') && this.orderDetail.order_address != null) {
            // const myLatLng = {lat: Number(this.orderDetail.order_address.lat), lng: Number(this.orderDetail.order_address.lng)};
            // let marker = new google.maps.Marker({
            //     position: myLatLng,
            //     title: this.customer_name,
            // });
            //
            // marker.setMap(this.map);
            // marker.setTitle(this.customer_name);
            // this.map.setCenter(myLatLng);
            //
            // marker.addListener('click', () => {
            //     this.map.setZoom(15);
            //     this.map.setCenter(marker.getPosition());
            // });

            this.showMarkerOnMap(Number(this.orderDetail.order_address.lat), Number(this.orderDetail.order_address.lng), 'Customer Location', 'cus');
            this.showMarkerOnMap(Number(this.orderDetail.chef_location.lat), Number(this.orderDetail.chef_location.lng), 'Chef Location', 'chef');

            this.user_address = this.orderDetail.order_address.address;
            if(this.orderDetail.chef_location.address!=null){
                this.chef_address = this.orderDetail.chef_location.address;
            }else if(this.orderDetail.chef_location.formatted_address!=null){
                this.chef_address = this.orderDetail.chef_location.formatted_address;
            }
        }
    }

    user_address = '';
    chef_address = '';
    user_phone = '';
    chef_name = '';
    chef_phone = '';

    marker;
    chefMarker;

    showMarkerOnMap(lat, lng, name, type) {
        const myLatLng = {lat: lat, lng: lng}

        let marker = new google.maps.Marker({
            position: myLatLng,
            title: 'current location',
        });

        marker.setMap(this.map);
        marker.setTitle(name);

        if (type == 'chef') {
            let chefInfo = new google.maps.InfoWindow({
                content: name,
                maxWidth: 200
            });
            chefInfo.open(this.map, marker);
        } else if (type == 'cus') {

            let markerInfo = new google.maps.InfoWindow({
                content: name,
                maxWidth: 200
            });
            markerInfo.open(this.map, marker);
        }

        marker.setMap(this.map);
        if (type == 'cus') {
            this.map.setCenter(myLatLng);
            this.marker = marker;
        } else if (type == 'chef') {
            this.chefMarker = marker;
        }

        if (this.marker != null && this.chefMarker != null) {
            // var lat = marker.getPosition().lat();
            // var lng = marker.getPosition().lng();
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(this.marker.getPosition());
            bounds.extend(this.chefMarker.getPosition());
            this.map.fitBounds(bounds);
        }
    }

    closeModal() {
        this.activeModal.close();
    }


    // selectLocationFun() {
    //     this.modalService.open(LocationEditorComponent, {
    //         backdrop: 'static',
    //         size: <any>'profiletutor',
    //         keyboard: false,
    //         centered: true
    //     }).closed.subscribe((result) => {
    //         if (result != undefined) {
    //             console.log('result:-' + JSON.stringify(result));
    //             let location = result.location;
    //             this.profile_location = location.formatted_address;
    //             this.selectedLocation = location;
    //         }
    //     });
    // }
    updateStatus(status: string) {
        if (status == '7') {
            window.confirm('are you sure want to pickup this order ?')
            if (this.driver_name == '') {
                alert('Please enter driver name');
                return;
            }
            if (this.driver_phone == '') {
                alert('Please enter driver phone');
                return;
            }
        }
        if (status == '2' && this.decline_reason == '') {
            alert('Please enter decline reason');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'order/updateOrderStatus', {
            delivery_status: status,
            id: this.orderId,
            decline_reason:this.decline_reason
        }).then((result) => {
            if (result.status) {
                this.setOrder(result.result);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    updateOrderDriverDetail() {
        if (this.driver_name == '') {
            alert('please enter driver name');
            return;
        }
        if (this.driver_phone == '') {
            alert('please enter driver phone');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'order/updateOrderDriverDetails', {
            driver_name: this.driver_name,
            driver_phone: this.driver_phone,
            id: this.orderId
        }).then((result) => {
            if (result.status) {
                // this.setOrder(result.result);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    openWhatsApp(number) {
        let link = 'https://wa.me/91' + number;
        console.log(link)
        window.open(link);
    }
}
