import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddUpdateItemComponent} from '../../modals/add-update-item/add-update-item.component';
import {UtilService} from '../../service/util.service';
import {WaitingFoodItemModalComponent} from '../../modals/waiting-food-item-modal/waiting-food-item-modal.component';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-food-items',
    templateUrl: './food-items.component.html',
    styleUrls: ['./food-items.component.css']
})
export class FoodItemsComponent implements OnInit {

    chef_id;
    items = [];
    waitItems=[];
    approvedId;
    selectedIds;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal,
        private toaster: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.chef_id = this.utilService.getUserID();
        if (this.chef_id != null && this.chef_id != undefined) {
            this.getChefItems();
        }
        this.chefFoodItemUpdateStatus();
    }

    getChefItems() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'items/getItemsByChefId', {
            chef_id: this.chef_id
        }).then((result) => {
            if (result.status) {
                this.items = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    deleteCategory(id) {

    }
    public chefFoodItemUpdateStatus() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefstatus/getChefFoodItemUpdate/' + this.chef_id + '/3').then((result) => {
            if (result.status) {
                for(let i=0;i<result.result.length;i++){
                    result.result[i]['food_item']=JSON.parse(result.result[i].data).food_item;
                }

                this.waitItems=result.result;
                this.approvedId=result.result
                
                this.selectedIds = this.approvedId.map(({ food_item }) => food_item.id);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
    addItem() {
        const modalRef = this.modalService.open(AddUpdateItemComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getChefItems();
            this.chefFoodItemUpdateStatus();
        })
        modalRef.componentInstance.chef_id = this.chef_id;
        // modalRef.componentInstance.chefCategory = category;
    }


    editItem(item: any) {
        if(!(this.selectedIds?.includes(item.id))) {
            const modalRef = this.modalService.open(AddUpdateItemComponent, {
                backdrop: 'static',
                size: 'xl',
                keyboard: false,
                centered: true
            });
            modalRef.closed.subscribe((result) => {
                console.log('dismissed:-' + JSON.stringify(result));
                this.getChefItems();
                this.chefFoodItemUpdateStatus();
            })
            modalRef.componentInstance.chef_id = this.chef_id;
            modalRef.componentInstance.itemDetail = item;
        }
        else {
            return this.toaster.error('Already Sent for Approval');
        }
        
    }

    deleteItem(id: any) {
        let text ="are you sure want to delete this item" 
        if (window.confirm(text) == true){
        this.apiService.deleteAPI(this.apiService.BASE_URL + 'items/deleteItems/' + id).then((result) => {
            if (result.status) {
                this.getChefItems()
            }
        }, (error) => {
            console.log('something went wrong');
        })
        }
    }

   

    viewWaitingFoodItem(item: any) {
        const modalRef = this.modalService.open(WaitingFoodItemModalComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getChefItems()
        })
        modalRef.componentInstance.itemStatus = item;
    }
}
