import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../../service/util.service';
import {ApiService} from '../../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    displayUsers = []
    users = []
    displayChefs = []
    chefs = []

    userTab = false;
    chefTab = true;

    fromDt = '-';
    toDt = '-';

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.userReport()
        this.chefreport()
    }

    getDatetime(datetime) {
        if (datetime != null && datetime != '-') {
            return datetime.year + '-' + datetime.month + '-' + datetime.day
        } else {
            return '-';
        }
    }

    userReport() {
        this.displayUsers = [];

        this.chefTab = false;
        this.userTab = true;

        this.apiService.getAPI(this.apiService.BASE_URL + 'UserRole/getAllUsers/' + this.getDatetime(this.fromDt) + '/' + this.getDatetime(this.toDt)).then((result) => {
            if (result.status) {
                for (let i = 0; i < result.result.length; i++) {
                    if (result.result[i].type == 0) {
                        this.displayUsers.push(result.result[i]);
                        this.users.push(result.result[i]);
                    }
                }
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    chefreport() {
        this.displayChefs = [];

        this.chefTab = true;
        this.userTab = false;

        this.apiService.getAPI(this.apiService.BASE_URL + 'UserRole/getAllChefs/' + this.getDatetime(this.fromDt) + '/' + this.getDatetime(this.toDt)).then((result) => {
            if (result.status) {
                this.displayChefs = result.result;
                this.chefs = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    onDateSelected() {

        setTimeout(() => {
            console.log('on date selected:-' + JSON.stringify(this.fromDt))
            console.log('on date selected:-' + JSON.stringify(this.toDt))
            if (this.fromDt != '-' && this.toDt != '-') {
                if (this.chefTab) {
                    this.chefreport()
                }
                if (this.userTab) {
                    this.userReport()
                }
            }
        }, 100);


    }

    removeUser(id: any) {
        let postData = {
            user_id: id
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/removeUser', postData).then((result) => {
            if (result.status) {
                this.userReport();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    removeChef(id: any) {
        let postData = {
            chef_id: id
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/removeChef', postData).then((result) => {
            if (result.status) {
                this.chefreport();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    searchUser = '';

    onSearchUsers() {
        if (this.searchUser == '') {
            this.displayUsers = this.users;
        } else {
            this.displayUsers = this.users.filter(res => {
                return (
                    res.name != null && res.name.toLowerCase().match(this.searchUser.toLowerCase())
                    || res.email != null && res.email.toLowerCase().match(this.searchUser.toLowerCase())
                    || res.phone != null && res.phone.toLowerCase().match(this.searchUser.toLowerCase())

                )
            })
        }
    }

    searchChef = '';

    onSearchChef() {
        if (this.searchChef == '') {
            this.displayChefs = this.chefs;
        } else {
            this.displayChefs = this.chefs.filter(res => {
                return (
                    res.name != null && res.name.toLowerCase().match(this.searchChef.toLowerCase())
                    || res.email != null && res.email.toLowerCase().match(this.searchChef.toLowerCase())
                    || res.phone != null && res.phone.toLowerCase().match(this.searchChef.toLowerCase())

                )
            })
        }
    }

    download() {
        if(this.userTab){
            let users=[];
            for(let i=0;i<this.displayUsers.length;i++){
                let user = {
                    'Name': this.displayUsers[i].name,
                    'Email': this.displayUsers[i].email,
                    'Phone': this.displayUsers[i].phone,
                    'address': this.displayUsers[i].address,
                    'Created At': this.utilService.getDateTimeByTime(this.displayUsers[i].created_at)
                }
                users.push(user)
            }

            this.exportAsExcelFile(users, 'user_report')

        }else{
            let users=[];
            for(let i=0;i<this.displayChefs.length;i++){
                let user = {
                    'Name': this.displayChefs[i].name,
                    'Email': this.displayChefs[i].email,
                    'Phone': this.displayChefs[i].phone,
                    'address': this.displayChefs[i].address,
                    'location': this.displayChefs[i].region,
                    'Created At': this.utilService.getDateTimeByTime(this.displayChefs[i].created_at)
                }
                users.push(user)
            }

            this.exportAsExcelFile(users, 'chef_report')
        }
    }


    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
    }
}
