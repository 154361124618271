import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EditOrderComponent} from 'app/modals/edit-order/edit-order.component';
import {UtilService} from '../../service/util.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';
import {ViewFailedOrderComponent} from '../../modals/view-failed-order/view-failed-order.component';
import jsPDF from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {HttpClient} from '@angular/common/http';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

    pending_tab: boolean = true;
    delivered_tab: boolean = false;
    declined_tab: boolean = false;
    failed_tab: boolean = false;

    displayPendingOrders = [];
    pendingOrders = [];
    displayDeclinedOrders = [];
    declinedOrders = [];
    displayFailedOrders = [];
    failedOrders = [];
    displayDeliveredOrders = [];
    deliveredOrders = [];

    selectedDate: NgbDateStruct;
    date: { year: number, month: number };
    userType = '';
    fileName = 'orders.xlsx';
    fromDate;
    toDate;

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService,
        private toaster: ToastrService,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {

        this.fromDate = this.utilService.getTodayByTimeObj();
        this.toDate = this.utilService.getTodayByTimeObj();

        // if (this.utilService.getUserType() == '2') {
        //     this.selectedDate = this.utilService.getTodayByTimeObj();
        // }
        this.userType = this.utilService.getUserType();
        // if (this.userType == '2') {
        //     this.getChefPendingOrders();
        // } else {
        this.getConstants();
        // }
    }

    commission = 0;
    delivery_charges_gst = 0;
    packaged_gst = 0;
    cooked_gst = 0;

    getConstants() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'common/getConstants').then((result) => {
            if (result.status) {
                this.commission = result.result.commission;
                this.delivery_charges_gst = result.result.delivery_charges_gst;
                this.cooked_gst = result.result.cooked_gst;
                this.packaged_gst = result.result.packaged_gst;
                this.getChefPendingOrders();
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    getDatetime() {
        if (this.selectedDate != null) {
            return this.selectedDate.year + '-' + this.selectedDate.month + '-' + this.selectedDate.day
        } else {
            return '-';
        }
    }

    getChefPendingOrders() {
        this.displayPendingOrders = [];
        this.pendingOrders = [];
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefPendingOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                this.displayPendingOrders = result.result;
                this.pendingOrders = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    getChefDeclinedOrders() {
        this.displayDeclinedOrders = [];
        this.declinedOrders = [];
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefDeclinedOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                this.displayDeclinedOrders = result.result;
                this.declinedOrders = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    getChefFailedOrders() {
        this.displayFailedOrders = [];
        this.failedOrders = [];
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefFailedOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                for (let i = 0; i < result.result.length; i++) {
                    result.result[i].reason = 'Banking Error';
                    console.log('failed reason:-' + result.result[i].failed_error);
                    if (result.result[i].failed_error != null && result.result[i].failed_error != undefined && result.result[i].failed_error != '') {
                        let reason = JSON.parse(result.result[i].failed_error);
                        // console.log("reas1:-"+reason);
                        if(reason.hasOwnProperty('error')&&reason.error!=null&&reason.error!=undefined&&reason.error.hasOwnProperty('reason')){
                            result.result[i].reason = reason.error.reason;
                            console.log('reason:-' + reason.error.reason);
                        }else{
                            result.result[i].reason = result.result[i].failed_error.description;
                        }
                    }
                }
                this.displayFailedOrders = result.result;
                this.failedOrders = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    getChefdeliveredOrder() {
        this.displayDeliveredOrders = [];
        this.deliveredOrders = [];
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefdeliveredOrder/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                this.displayDeliveredOrders = result.result;
                this.deliveredOrders = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    openpending_tab() {
        this.pending_tab = true;
        this.delivered_tab = false;
        this.declined_tab = false;
        this.failed_tab = false;
        this.getChefPendingOrders()
    }

    opendelivered_tab() {
        this.pending_tab = false;
        this.delivered_tab = true;
        this.declined_tab = false;
        this.failed_tab = false;
        this.getChefdeliveredOrder()
    }

    opendeclined_tab() {
        this.pending_tab = false;
        this.delivered_tab = false;
        this.declined_tab = true;
        this.failed_tab = false;
        this.getChefDeclinedOrders()
    }

    openfailed_tab() {
        this.pending_tab = false;
        this.delivered_tab = false;
        this.declined_tab = false;
        this.failed_tab = true;
        this.getChefFailedOrders()
    }

    editItem(orderDetail) {
        const modalRef = this.modalService.open(EditOrderComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            this.getChefPendingOrders()
        })
        modalRef.componentInstance.orderId = orderDetail.id;
    }

    viewChefFailedError(orderDetail) {
        const modalRef = this.modalService.open(ViewFailedOrderComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            this.getChefPendingOrders()
        })
        modalRef.componentInstance.orderId = orderDetail.id;
    }

    // deleteItem() {
    //     const modalRef = this.modalService.open(ConfirmationModalComponent, {
    //         backdrop: 'static',
    //         size: <any>'profiletutor',
    //         keyboard: false,
    //         centered: true
    //     });
    //     modalRef.closed.subscribe((result) => {
    //         console.log('dismissed:-' + JSON.stringify(result));
    //     })
    //     // modalRef.componentInstance.chefId = item.id;
    // }
    onDateSelected() {
        setTimeout(() => {
            console.log('on date selected:-' + JSON.stringify(this.selectedDate))
            this.getChefPendingOrders()
            this.getChefdeliveredOrder()
            this.getChefDeclinedOrders()
            this.getChefFailedOrders()
        }, 100);
    }

    downloadExel() {
        if (this.pending_tab) {
            // this.exportexcel('pending_orders')
            if (this.displayPendingOrders.length == 0) {
                this.toaster.error('No Pending Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.displayPendingOrders.length; i++) {

                let quantity = 0;
                for (let j = 0; j < this.displayPendingOrders[i].orderItems.length; j++) {
                    quantity += this.displayPendingOrders[i].orderItems[j].quantity;
                }

                let order = {
                    OrderNumber: this.displayPendingOrders[i].uid,
                    TotalItems: this.displayPendingOrders[i].total_items,
                    Quantity: quantity,
                    PickupTime: this.utilService.getDateTimeByTime(this.displayPendingOrders[i].delivery_at),

                    // Price: this.deliveredOrders[i].total_price,
                }
                if (this.utilService.getUserType() == '2') {
                    if (this.displayPendingOrders[i].hasOwnProperty('chef_location') && this.displayPendingOrders[i].chef_location != null) {
                        order['PickupAddress'] = this.displayPendingOrders[i].chef_location.address;
                    } else {
                        order['PickupAddress'] = '-'
                    }
                }

                order['Chef'] = this.displayPendingOrders[i].chef_name;
                order['ChefPhone'] = this.displayPendingOrders[i].chef_phone;
                order['Customer'] = this.displayPendingOrders[i].cus_name;


                if (this.utilService.getUserType() == '2') {
                    order['CustomerPhone'] = this.displayPendingOrders[i].cus_phone;
                    if (this.displayPendingOrders[i].hasOwnProperty('order_address') && this.displayPendingOrders[i].order_address != null) {
                        order['DeliveryAddress'] = this.displayPendingOrders[i].order_address.address;
                    } else {
                        order['DeliveryAddress'] = '-'
                    }
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'Pending_Orders')
        } else if (this.delivered_tab) {
            // this.exportexcel('delivered_orders')
            if (this.displayDeliveredOrders.length == 0) {
                this.toaster.error('No Delivered Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.displayDeliveredOrders.length; i++) {
                let quantity = 0;
                for (let j = 0; j < this.displayDeliveredOrders[i].orderItems.length; j++) {
                    quantity += this.displayDeliveredOrders[i].orderItems[j].quantity;
                }
                let order = {
                    OrderNumber: this.displayDeliveredOrders[i].uid,
                    TotalItems: this.displayDeliveredOrders[i].total_items,
                    Quantity: quantity,
                    PickupTime: this.utilService.getDateTimeByTime(this.displayDeliveredOrders[i].delivery_at),

                    // Price: this.deliveredOrders[i].total_price,
                }
                if (this.utilService.getUserType() == '2') {
                    if (this.displayDeliveredOrders[i].hasOwnProperty('chef_location') && this.displayDeliveredOrders[i].chef_location != null) {
                        order['PickupAddress'] = this.displayDeliveredOrders[i].chef_location.address;
                    } else {
                        order['PickupAddress'] = '-'
                    }
                }

                order['Chef'] = this.displayDeliveredOrders[i].chef_name;
                order['ChefPhone'] = this.displayDeliveredOrders[i].chef_phone;
                order['Customer'] = this.displayDeliveredOrders[i].cus_name;


                if (this.utilService.getUserType() == '2') {
                    order['CustomerPhone'] = this.displayDeliveredOrders[i].cus_phone;
                    if (this.displayDeliveredOrders[i].hasOwnProperty('order_address') && this.displayDeliveredOrders[i].order_address != null) {
                        order['DeliveryAddress'] = this.displayDeliveredOrders[i].order_address.address;
                    } else {
                        order['DeliveryAddress'] = '-'
                    }
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'Delivered_Orders')
        } else if (this.declined_tab) {
            // this.exportexcel('declined_orders')
            if (this.displayDeclinedOrders.length == 0) {
                this.toaster.error('No Declined Orders Found');
                return
            }
            let orders = [];
            for (let i = 0; i < this.displayDeclinedOrders.length; i++) {
                let quantity = 0;
                for (let j = 0; j < this.displayDeclinedOrders[i].orderItems.length; j++) {
                    quantity += this.displayDeclinedOrders[i].orderItems[j].quantity;
                }
                let order = {
                    OrderNumber: this.displayDeclinedOrders[i].uid,
                    TotalItems: this.displayDeclinedOrders[i].total_items,
                    Quantity: quantity,
                    PickupTime: this.utilService.getDateTimeByTime(this.displayDeclinedOrders[i].delivery_at),

                    // Price: this.deliveredOrders[i].total_price,
                }
                if (this.utilService.getUserType() == '2') {
                    if (this.displayDeclinedOrders[i].hasOwnProperty('chef_location') && this.displayDeclinedOrders[i].chef_location != null) {
                        order['PickupAddress'] = this.displayDeclinedOrders[i].chef_location.address;
                    } else {
                        order['PickupAddress'] = '-'
                    }
                }

                order['Chef'] = this.displayDeclinedOrders[i].chef_name;
                order['ChefPhone'] = this.displayDeclinedOrders[i].chef_phone;
                order['Customer'] = this.displayDeclinedOrders[i].cus_name;


                if (this.utilService.getUserType() == '2') {
                    order['CustomerPhone'] = this.displayDeclinedOrders[i].cus_phone;
                    if (this.displayDeclinedOrders[i].hasOwnProperty('order_address') && this.displayDeclinedOrders[i].order_address != null) {
                        order['DeliveryAddress'] = this.displayDeclinedOrders[i].order_address.address;
                    } else {
                        order['DeliveryAddress'] = '-'
                    }
                }

                orders.push(order)
            }
            this.exportAsExcelFile(orders, 'Declined_Orders')
        }
    }

    // exportexcel(id): void {
    //     /* table id is passed over here */
    //     let element = document.getElementById(id);
    //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    //
    //     /* generate workbook and add the worksheet */
    //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    //     /* save to file */
    //     XLSX.writeFile(wb, this.fileName);
    //
    // }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
    }

    downloadPendingReport() {
        if (this.pending_tab) {
            this.pendingReport()
        } else if (this.delivered_tab) {
            this.deliveryReport()
        } else if (this.declined_tab) {
            this.declinedReport()
        }
    }

    declinedReport() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefDeclinedOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                let orders = [];

                for (let i = 0; i < result.result.length; i++) {

                    let quantity = 0;
                    for (let j = 0; j < result.result[i].orderItems.length; j++) {
                        quantity += result.result[i].orderItems[j].quantity;
                    }

                    let order = {
                        'Order Number': result.result[i].uid,
                        'Customer Name': result.result[i].cus_name,
                        'Customer Phone': result.result[i].cus_phone,
                        'Chef Name': result.result[i].chef_name,
                        'Pickup Date Time': this.utilService.get1HourBeforeDT(result.result[i].delivery_at),
                        'Total Items': quantity,

                    }
                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('chef_location') && result.result[i].chef_location != null) {
                            order['Pickup Address'] = result.result[i].chef_location.address;
                        } else {
                            order['Pickup Address'] = '-'
                        }
                        order['Amount'] = result.result[i].total_price;
                    }


                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('order_address') && result.result[i].order_address != null) {
                            order['Delivery Address'] = result.result[i].order_address.address;
                        } else {
                            order['Delivery Address'] = '-'
                        }
                    }
                    orders.push(order)
                }
                this.exportAsExcelFile(orders, 'declined_report')
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    deliveryReport() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefdeliveredOrder/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                let orders = [];

                for (let i = 0; i < result.result.length; i++) {

                    let quantity = 0;
                    for (let j = 0; j < result.result[i].orderItems.length; j++) {
                        quantity += result.result[i].orderItems[j].quantity;
                    }

                    let order = {
                        'Order Number': result.result[i].uid,
                        'Customer Name': result.result[i].cus_name,
                        'Customer Phone Number': result.result[i].cus_phone,
                        'Chef Name': result.result[i].chef_name,
                        'Chef Phone Number': result.result[i].chef_phone,
                        'Pickup Date Time': this.utilService.get1HourBeforeDT(result.result[i].delivery_at),
                        'Total Items': quantity,

                    }
                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('chef_location') && result.result[i].chef_location != null) {
                            order['Pickup Address'] = result.result[i].chef_location.address;
                        } else {
                            order['Pickup Address'] = '-'
                        }
                        // order['Amount'] = result.result[i].total_price;
                    }


                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('order_address') && result.result[i].order_address != null) {
                            order['Delivery Address'] = result.result[i].order_address.address;
                        } else {
                            order['Delivery Address'] = '-'
                        }
                    }
                    orders.push(order)
                }
                this.exportAsExcelFile(orders, 'delivery_report')
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }


    pendingReport() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'order/chefPendingOrders/' + this.utilService.getUserID() + '/' + this.utilService.getUserType() + '/' + this.getDatetime()).then((result) => {
            if (result.status) {
                let orders = [];

                for (let i = 0; i < result.result.length; i++) {

                    let quantity = 0;
                    for (let j = 0; j < result.result[i].orderItems.length; j++) {
                        quantity += result.result[i].orderItems[j].quantity;
                    }


                    let itemDescription = '';
                    for (let j = 0; j < result.result[i].orderItems.length; j++) {
                        if (j == 0) {
                            itemDescription = result.result[i].orderItems[j].quantity + ' x ' + result.result[i].orderItems[j].name;
                        } else {
                            itemDescription += ' , ' + result.result[i].orderItems[j].quantity + ' x ' + result.result[i].orderItems[j].name;
                        }
                    }


                    let order = {
                        'Order Number': result.result[i].uid,
                        'Customer Name': result.result[i].cus_name,
                        'Customer Phone': result.result[i].cus_phone,
                        'Chef Name': result.result[i].chef_name,
                        'Chef Phone': result.result[i].chef_phone,
                        'Pickup Date Time': this.utilService.get1HourBeforeDT(result.result[i].delivery_at),
                        'Item Description': itemDescription,
                        'Total Items': quantity,
                    }

                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('chef_location') && result.result[i].chef_location != null) {
                            order['Pickup Address'] = result.result[i].chef_location.address;
                        } else {
                            order['Pickup Address'] = '-'
                        }
                        // order['Amount'] = result.result[i].total_price;
                    }


                    if (this.utilService.getUserType() == '2') {
                        if (result.result[i].hasOwnProperty('order_address') && result.result[i].order_address != null) {
                            order['Delivery Address'] = result.result[i].order_address.address;
                        } else {
                            order['Delivery Address'] = '-'
                        }
                    }
                    orders.push(order)
                }
                this.exportAsExcelFile(orders, 'order_report')
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    downloadSalesReport() {
        let fromDate = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
        let toDate = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day

        let post = {
            fromDate: fromDate + ' 00:00:00',
            toDate: toDate + ' 23:59:00'
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'order/getAllOrdersByDate', post).then((result) => {
            if (result.status) {
                this.downloadDailyReport(result.result, post);
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
            this.toaster.error('Something went wrong')
        })
    }

    downloadDailyReport(data, post) {
        let orders = [];
        for (let i = 0; i < data.length; i++) {
            let quantity = 0;
            let itemDescription = '';
            for (let j = 0; j < data[i].orderItems.length; j++) {
                quantity += data[i].orderItems[j].quantity;
                if (j == 0) {
                    itemDescription = data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                } else {
                    itemDescription += ' , ' + data[i].orderItems[j].quantity + ' x ' + data[i].orderItems[j].name;
                }
            }

            let invoicenumber = '';
            if (data[i].invoice_number != null && data[i].invoice_number != undefined && data[i].invoice_number != '') {
                invoicenumber = data[i].invoice_number;
            }

            let customer_name = '-';
            let customer_phone = '-';
            if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                customer_name = data[i].customer_detail.name;
                customer_phone = data[i].customer_detail.phone;
            }
            let chef_name = '-'
            let chef_phone = '-'
            if (data[i].hasOwnProperty('chef_detail') && data[i].chef_detail != null) {
                chef_name = data[i].chef_detail.name;
                chef_phone = data[i].chef_detail.phone;
            }

            let order = {
                'Chef Name': chef_name,
                'Customer Name': customer_name,
                'Item Description': itemDescription,
                'Order Date': this.utilService.getDateTimeByTime(data[i].created_at),
                'Delivery Date': this.utilService.getDateTimeByTime(data[i].delivery_at),
                'Order Quantity': quantity,
                'Order Id': data[i].uid,
                'Transaction Id': data[i].razorpay_id,
                'Invoice Number': invoicenumber,
                'Item Price': data[i].base_price,
                'Chef Price': data[i].chef_price,
                'Delivery Charges': data[i].delivery_charges,
                // 'GST on Delivery': data[i].delivery_service_tax,
                // 'GST': data[i].tax,
                // 'Total Price': data[i].total_price
            }


            if (data[i].hasOwnProperty('order_address') && data[i].order_address != null && data[i] != undefined
                && data[i].order_address.formatted_address.toLowerCase().includes('haryana')
            ) {
                order['Delivery IGST'] = '-';
                order['Delivery SGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)
                order['Delivery CGST'] = ((Number(data[i].delivery_charges * (this.delivery_charges_gst / 100))) / 2).toFixed(2)

                order['IGST'] = '-';
                order['SGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
                order['CGST'] = ((Number(data[i].tax)) / 2).toFixed(2)
            } else {
                order['Delivery IGST'] = Number((data[i].delivery_charges * (this.delivery_charges_gst / 100)).toFixed(2));
                order['Delivery SGST'] = '-'
                order['Delivery CGST'] = '-'

                order['IGST'] = data[i].tax
                order['SGST'] = '-'
                order['CGST'] = '-'
            }

            order['Total Price'] = data[i].total_price;


            orders.push(order);
        }
        this.saveReport(orders, 'Order Sales Report, From : ' + this.utilService.getDateTimeByTime(post.fromDate) + ' , To : ' + this.utilService.getDateTimeByTime(post.toDate), 'daily_sales_report');
    }

    saveReport(data, reportTitle, reportName) {
        let cellValue = [];
        let merge = [];


        // merge.push("A2:D2");

        cellValue.push({
            cell: 'A1',
            value: reportTitle
        })

        let keys = Object.keys(data[0]);

        merge.push('A1:' + this.utilService.getKeys(keys.length) + '1');

        for (let i = 0; i < keys.length; i++) {
            cellValue.push({
                cell: this.utilService.getKeys(i + 1) + 2,
                value: keys[i]
            })
        }

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                let index = 3 + i;
                cellValue.push({
                    cell: this.utilService.getKeys(j + 1) + index,
                    value: data[i][keys[j]]
                })
            }
        }
        this.fetchReport(merge, cellValue, reportName + '_' + this.utilService.formatReportName());
    }

    private fetchReport(merge, cellValue, name) {
        let report_name = name + this.utilService.formatReportName() + '.xlsx';

        let postData = {
            merge: merge,
            cellValues: cellValue,
            file_name: report_name
        }

        this.http.post('https://theshakehand.com/chef_php/index.php/Report/generateReportExcel', postData, {responseType: 'blob'}).subscribe(blob => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = report_name;
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    }

    searchPending = '';

    onSearchPending() {
        setTimeout(() => {
            if (this.searchPending == '') {
                this.displayPendingOrders = this.pendingOrders;
            } else {
                this.displayPendingOrders = this.pendingOrders.filter(res => {
                    return (
                        res.cus_name.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.chef_name.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.chef_phone.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.cus_phone.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.invoice_number != null && res.invoice_number != '' && res.invoice_number.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.razorpay_id.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.uid.toLowerCase().match(this.searchPending.toLowerCase())
                    )
                })
            }
        }, 300)
    }

    searchDelivered = '';

    onSearchDelivered() {
        setTimeout(() => {
            if (this.searchDelivered == '') {
                this.displayDeliveredOrders = this.deliveredOrders;
            } else {
                this.displayDeliveredOrders = this.deliveredOrders.filter(res => {
                    return (
                        res.cus_name.toLowerCase().match(this.searchDelivered.toLowerCase())
                        || res.chef_name.toLowerCase().match(this.searchDelivered.toLowerCase())
                        || res.chef_phone.toLowerCase().match(this.searchDelivered.toLowerCase())
                        || res.cus_phone.toLowerCase().match(this.searchDelivered.toLowerCase())
                        || res.invoice_number != null && res.invoice_number != '' && res.invoice_number.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.razorpay_id.toLowerCase().match(this.searchDelivered.toLowerCase())
                        || res.uid.toLowerCase().match(this.searchDelivered.toLowerCase())
                    )
                })
            }
        }, 300)
    }

    searchDeclined = '';

    onSearchDeclined() {
        setTimeout(() => {
            if (this.searchDeclined == '') {
                this.displayDeclinedOrders = this.declinedOrders;
            } else {
                this.displayDeclinedOrders = this.declinedOrders.filter(res => {
                    return (
                        res.cus_name.toLowerCase().match(this.searchDeclined.toLowerCase())
                        || res.chef_name.toLowerCase().match(this.searchDeclined.toLowerCase())
                        || res.chef_phone.toLowerCase().match(this.searchDeclined.toLowerCase())
                        || res.cus_phone.toLowerCase().match(this.searchDeclined.toLowerCase())
                        || res.invoice_number != null && res.invoice_number != '' && res.invoice_number.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.razorpay_id.toLowerCase().match(this.searchDeclined.toLowerCase())
                        || res.uid.toLowerCase().match(this.searchDeclined.toLowerCase())
                    )
                })
            }
        }, 300)
    }

    searchFailed = '';

    onSearchFailed() {
        setTimeout(() => {
            if (this.searchFailed == '') {
                this.displayFailedOrders = this.failedOrders;
            } else {
                this.displayFailedOrders = this.failedOrders.filter(res => {
                    return (
                        res.cus_name.toLowerCase().match(this.searchFailed.toLowerCase())
                        || res.chef_name.toLowerCase().match(this.searchFailed.toLowerCase())
                        || res.chef_phone.toLowerCase().match(this.searchFailed.toLowerCase())
                        || res.cus_phone.toLowerCase().match(this.searchFailed.toLowerCase())
                        || res.invoice_number != null && res.invoice_number != '' && res.invoice_number.toLowerCase().match(this.searchPending.toLowerCase())
                        || res.razorpay_id.toLowerCase().match(this.searchFailed.toLowerCase())
                        || res.uid.toLowerCase().match(this.searchFailed.toLowerCase())
                    )
                })
            }
        }, 300)

    }

    @ViewChild('pdfTable') pdfTable: ElementRef;

    pdfOrder = null;

    downloadInvoice(order: any) {

        console.log('order :-' + JSON.stringify(order));
        // return;
        this.pdfOrder = order;

        let totalQuantity = 0
        let taxAmount = 0
        let icgsttotal = 0
        let sgsttotal = 0
        let cgsttotal = 0
        let deliveryIgstPer = ''
        let deliverySGSTPer = ''

        let deliveryIgst = ''
        let deliverySGST = ''

        if (this.pdfOrder.hasOwnProperty('order_address') && this.pdfOrder.order_address != null && this.pdfOrder != undefined
            && this.pdfOrder.order_address.formatted_address.toLowerCase().includes('haryana')) {
            this.pdfOrder['isICGST'] = false;

            deliverySGSTPer = (this.delivery_charges_gst / 2).toFixed(2);
            deliveryIgstPer = '-';

            deliveryIgst = '-';
            deliverySGST = (this.pdfOrder.delivery_service_tax / 2).toFixed(2);

            for (let i = 0; i < this.pdfOrder.orderItems.length; i++) {
                totalQuantity += this.pdfOrder.orderItems[i].quantity;
                taxAmount += Number(this.pdfOrder.orderItems[i].price);
                if (this.pdfOrder.orderItems[i].category == 1) {
                    this.pdfOrder.orderItems[i]['icgstper'] = '-';
                    this.pdfOrder.orderItems[i]['sgstper'] = Number((this.packaged_gst / 2).toFixed(2));
                    this.pdfOrder.orderItems[i]['cgstper'] = Number((this.packaged_gst / 2).toFixed(2));

                    let gst = Number(((this.pdfOrder.orderItems[i].price * (this.packaged_gst / 100)) / 2).toFixed(2));

                    this.pdfOrder.orderItems[i]['icgstamount'] = '-';
                    this.pdfOrder.orderItems[i]['sgstamount'] = gst;
                    this.pdfOrder.orderItems[i]['cgstamount'] = gst;

                    sgsttotal += gst
                    cgsttotal += gst

                    let tax = Number((this.pdfOrder.orderItems[i].price * (this.packaged_gst / 100)).toFixed(2));
                    this.pdfOrder.orderItems[i]['taxamount'] = tax;
                    // taxAmount += tax;
                    this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
                } else {
                    this.pdfOrder.orderItems[i]['icgstper'] = '-';
                    this.pdfOrder.orderItems[i]['sgstper'] = Number((this.cooked_gst / 2).toFixed(2));
                    this.pdfOrder.orderItems[i]['cgstper'] = Number((this.cooked_gst / 2).toFixed(2));

                    let gst = Number(((this.pdfOrder.orderItems[i].price * (this.cooked_gst / 100)) / 2).toFixed(2));

                    this.pdfOrder.orderItems[i]['icgstamount'] = '-';
                    this.pdfOrder.orderItems[i]['sgstamount'] = gst;
                    this.pdfOrder.orderItems[i]['cgstamount'] = gst;

                    sgsttotal += gst
                    cgsttotal += gst

                    let tax = Number((this.pdfOrder.orderItems[i].price * (this.cooked_gst / 100)).toFixed(2))
                    this.pdfOrder.orderItems[i]['taxamount'] = tax;
                    // taxAmount += tax;
                    this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
                }
            }
        } else {
            this.pdfOrder['isICGST'] = true;

            deliverySGSTPer = '-';
            deliveryIgstPer = this.delivery_charges_gst.toFixed(2);


            deliveryIgst = (this.pdfOrder.delivery_service_tax).toFixed(2);
            deliverySGST = '-';

            for (let i = 0; i < this.pdfOrder.orderItems.length; i++) {

                totalQuantity += this.pdfOrder.orderItems[i].quantity;
                taxAmount += Number(this.pdfOrder.orderItems[i].price);

                if (this.pdfOrder.orderItems[i].category == 1) {
                    this.pdfOrder.orderItems[i]['icgstper'] = this.packaged_gst;
                    this.pdfOrder.orderItems[i]['sgstper'] = '-';
                    this.pdfOrder.orderItems[i]['cgstper'] = '-';

                    let gst = Number((this.pdfOrder.orderItems[i].price * (this.packaged_gst / 100)).toFixed(2));

                    this.pdfOrder.orderItems[i]['icgstamount'] = gst
                    this.pdfOrder.orderItems[i]['sgstamount'] == '-';
                    this.pdfOrder.orderItems[i]['cgstamount'] = '-';

                    icgsttotal += gst;

                    let tax = Number((this.pdfOrder.orderItems[i].price * (this.packaged_gst / 100)).toFixed(2));
                    this.pdfOrder.orderItems[i]['taxamount'] = tax;
                    this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
                } else {
                    this.pdfOrder.orderItems[i]['icgstper'] = this.cooked_gst;
                    this.pdfOrder.orderItems[i]['sgstper'] = '-';
                    this.pdfOrder.orderItems[i]['cgstper'] = '-';

                    let gst = Number((this.pdfOrder.orderItems[i].price * (this.cooked_gst / 100)).toFixed(2));

                    this.pdfOrder.orderItems[i]['icgstamount'] = gst;
                    this.pdfOrder.orderItems[i]['sgstamount'] == '-';
                    this.pdfOrder.orderItems[i]['cgstamount'] = '-';

                    icgsttotal += gst;

                    let tax = Number((this.pdfOrder.orderItems[i].price * (this.cooked_gst / 100)).toFixed(2));
                    this.pdfOrder.orderItems[i]['taxamount'] = tax;
                    this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))

                }
            }
        }


        this.pdfOrder['totalQuantity'] = totalQuantity
        this.pdfOrder['taxAmount'] = taxAmount
        this.pdfOrder['icgsttotal'] = icgsttotal
        this.pdfOrder['sgsttotal'] = sgsttotal
        this.pdfOrder['cgsttotal'] = cgsttotal
        this.pdfOrder['deliveryIgstPer'] = deliveryIgstPer
        this.pdfOrder['deliverySGSTPer'] = deliverySGSTPer
        this.pdfOrder['deliveryIgst'] = deliveryIgst
        if (deliveryIgst != '-') {
            this.pdfOrder['deliveryIgstAmount'] = Number(deliveryIgst)
        } else {
            this.pdfOrder['deliveryIgstAmount'] = 0
        }
        this.pdfOrder['deliverySGST'] = deliverySGST

        let fileName = '';
        if (this.pdfOrder.invoice_number != null && this.pdfOrder.invoice_number != '') {
            fileName = 'order_' + this.pdfOrder.invoice_number + '_';
        } else {
            fileName = 'order_' + this.utilService.formatReportName() + '_';
        }

        // return;

        setTimeout(() => {
            var data = document.getElementById('pdfTable');
            html2canvas(data).then(canvas => {
                var imgWidth = 208;
                var pageHeight = 295;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;

                const contentDataURL = canvas.toDataURL('image/png')
                // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
                const pdf = new jsPDF();
                var position = 0;
                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
                pdf.save(fileName + '.pdf'); // Generated PDF
                this.pdfOrder = null;
            });
        }, 1000)


    }

    // htmlString="<table class=\"table table-bordered\">\n" +
    //     "                <thead>\n" +
    //     "                    <tr>\n" +
    //     "                        <td rowspan=\"2\">S.no</td>\n" +
    //     "                        <td rowspan=\"2\">Item Name</td>\n" +
    //     "                        <td rowspan=\"2\">Qty</td>\n" +
    //     "                        <td rowspan=\"2\">Unit Rate</td>\n" +
    //     "                        <td rowspan=\"2\">Taxable Amount</td>\n" +
    //     "                        <td colspan=\"2\">CGST</td>\n" +
    //     "                        <td colspan=\"2\">SGST</td>\n" +
    //     "                        <td rowspan=\"2\">Grand Total</td>\n" +
    //     "                    </tr>\n" +
    //     "                </thead>\n" +
    //     "                <tbody>\n" +
    //     "                    <tr>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td class=\"font-weight\">%</td>\n" +
    //     "                        <td class=\"font-weight\">Amount</td>\n" +
    //     "                        <td class=\"font-weight\">%</td>\n" +
    //     "                        <td class=\"font-weight\">Amount</td>\n" +
    //     "                        <td></td>\n" +
    //     "                    </tr>\n" +
    //     "                    <tr>\n" +
    //     "                        <td>1</td>\n" +
    //     "                        <td>Chickin Biryani</td>\n" +
    //     "                        <td>4</td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td>200000</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>50.00</td>\n" +
    //     "                        <td>21.000</td>\n" +
    //     "                    </tr>\n" +
    //     "                    <tr>\n" +
    //     "                        <td>1</td>\n" +
    //     "                        <td>Chickin Biryani</td>\n" +
    //     "                        <td>4</td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td>200000</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>50.00</td>\n" +
    //     "                        <td>21.000</td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr>\n" +
    //     "                        <td><span style=\"visibility: hidden;\">span</span></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr class=\"font-weight\">\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>Total Food Cost</td>\n" +
    //     "                        <td>6</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>200000</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>50.00</td>\n" +
    //     "                        <td>21.000</td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr>\n" +
    //     "                        <td><span style=\"visibility: hidden;\">span</span></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr class=\"font-weight\">\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>Delivery Charges</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>3000</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td>2.50%</td>\n" +
    //     "                        <td>50.00</td>\n" +
    //     "                        <td>21.000</td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr>\n" +
    //     "                        <td><span style=\"visibility: hidden;\">span</span></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td></td>\n" +
    //     "                    </tr>\n" +
    //     "\n" +
    //     "                    <tr class=\"font-weight\">\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>Total Food Cost</td>\n" +
    //     "                        <td>6</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>200000</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>500</td>\n" +
    //     "                        <td></td>\n" +
    //     "                        <td>50.00</td>\n" +
    //     "                        <td>21.000</td>\n" +
    //     "                    </tr>\n" +
    //     "                </tbody>\n" +
    //     "            </table>"

}
