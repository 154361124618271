import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AddupdateoccassionAndCollectionComponent} from '../../modals/addupdateoccassion-and-collection/addupdateoccassion-and-collection.component';
// import {AddUpdateCuisineComponent} from '../../modals/add-update-cuisine/add-update-cuisine.component';
import {UtilService} from '../../service/util.service';
import { ConfirmPopupComponent } from 'app/modals/confirm-popup/confirm-popup.component';
@Component({
  selector: 'app-occassion-and-collection',
  templateUrl: './occassion-and-collection.component.html',
  styleUrls: ['./occassion-and-collection.component.css']
})
export class OccassionAndCollectionComponent implements OnInit {
  occassionList = [];
  constructor(  private apiService: ApiService, private modalService: NgbModal, private router: Router,
    public utilService: UtilService) { }

  ngOnInit(): void {
    this.getAllCuisine();
  }
  getAllCuisine() {
    this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefTag').then((result) => {
        if (result.status) {
          console.log(result.result,"occasion");
          
            this.occassionList = result.result;
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })
}

addCuisine() {
    const modalRef = this.modalService.open(AddupdateoccassionAndCollectionComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCuisine()
    })
}

editCuisine(item) {
    const modalRef = this.modalService.open(AddupdateoccassionAndCollectionComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCuisine()
    })
    modalRef.componentInstance.cuisineDetail = item;
}
openDeleteCuisinePopup(item:any) {
    
    const modalRef = this.modalService.open(ConfirmPopupComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCuisine()
    })
    modalRef.componentInstance.tagId = item.id;
    modalRef.componentInstance.DeleteText = `Do you really want to Delete ${item.heading} tag ?`;
    modalRef.componentInstance.BtnText = "OK";
    modalRef.componentInstance.title = "Chef Tag Master";
    // modalRef.componentInstance.BtnFunction = this.deleteCuisine(item);
}

}
