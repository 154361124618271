import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {UtilService} from '../../service/util.service';
import {ToastrService} from 'ngx-toastr';
import {PhoneVerificationComponent} from '../../modals/phone-verification/phone-verification.component';
import {PasswordResetComponent} from '../../modals/password-reset/password-reset.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    email = '';
    password = '';

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService,
        private toaster: ToastrService
    ) {
    }

    ngOnInit(): void {
        if (this.utilService.isUserLoggedIn()) {
            this.router.navigateByUrl('/admin')
        }
    }

    signin() {
        if (this.email == '') {
            this.toaster.error('Please enter email');
            return;
        }
        if (!this.utilService.validateEmail(this.email)) {
            this.toaster.error('Please enter email');
            return;
        }
        if (this.password == '') {
            this.toaster.error('Please enter password');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'user/loginAdmin', {
            email: this.email,
            password: this.password
        }).then((result) => {
            if (result.status) {
                this.checkLoginStatus(result.result);
                // if (result.result.type == '2' || result.result.hasOwnProperty('prospect_status')) {

                // } else {
                //     alert('invalid password');
                // }
            } else {
                this.toaster.error('Invalid password');
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    checkLoginStatus(userDetail) {
        if(this.utilService.getPanelType()==this.utilService.ADMIN_PANEL){
            if (userDetail.hasOwnProperty('type')) {
                this.utilService.setItem(this.utilService.USER_LOGIN, '1');
                this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(userDetail));
                if(userDetail.type==4){
                    this.router.navigateByUrl('/home-cms')
                }else{
                    this.router.navigateByUrl('/dashboard')
                }

            }else{
                this.toaster.error('Invalid Email/Password');
            }
        }else if(this.utilService.getPanelType()==this.utilService.CHEF_PANEL){
            if (!userDetail.hasOwnProperty('type')) {
                if (userDetail.status == 1) {
                    this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(userDetail));
                    if (userDetail.phone_verified == 1) {
                        this.utilService.setItem(this.utilService.USER_LOGIN, '1');
                        this.router.navigateByUrl('/dashboard')
                    } else {
                        const modalRef = this.modalService.open(PhoneVerificationComponent, {
                            backdrop: 'static',
                            size: '',
                            keyboard: false,
                            centered: true
                        });
                        modalRef.closed.subscribe((result) => {
                            if (result.status) {
                                this.utilService.setItem(this.utilService.USER_LOGIN, '1');
                                this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(userDetail));
                                this.router.navigateByUrl('/dashboard')
                            }
                        })
                        modalRef.componentInstance.userDetail = userDetail;
                    }
                } else {
                    this.toaster.error('Your account is disabled! Please contact admin.')
                }
            }else{
                this.toaster.error('Invalid Email/Password');
            }
        }
         // else {
            //    verify email
            // const modalRef = this.modalService.open(EmailVerificationComponent, {
            //     backdrop: 'static',
            //     size: 'xl',
            //     keyboard: false,
            //     centered: true
            // });
            // modalRef.closed.subscribe((result) => {
            //     if (result.status) {
            //         this.utilService.setItem(this.utilService.USER_LOGIN, '1');
            //         this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(userDetail));
            //         this.router.navigateByUrl('/dashboard')
            //     }
            // })
            // modalRef.componentInstance.userDetail = userDetail;
        // }
    }

    forgotPassword() {
        const modalRef = this.modalService.open(PasswordResetComponent, {
            backdrop: 'static',
            size: '',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
          if(this.utilService.getItem(this.utilService.USER_LOGIN)=='1'){
              this.router.navigateByUrl('/dashboard')
          }
        })
    }
}
