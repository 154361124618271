import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-add-chef',
    templateUrl: './add-chef.component.html',
    styleUrls: ['./add-chef.component.css']
})
export class AddChefComponent implements OnInit {

    name = '';
    email = '';
    password = '';
    phone = '';
region;
    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private activeModal: NgbActiveModal,
        private toaster:ToastrService
    ) {
    }

    ngOnInit(): void {
    }
selectedCity(){

}
    saveChef() {
        if (this.name == '') {
            this.toaster.error('please enter name');
            return;
        }
        if (this.email == '') {
            this.toaster.error('please enter email');
            return;
        }
        if (!this.utilService.validateEmail(this.email)) {
            this.toaster.error('please enter valid email');
            return;
        }
        if (this.password == '') {
            this.toaster.error('please enter password');
            return;
        }
        if (this.region == '') {
            this.toaster.error('please select region');
            return;
        }
        if (this.password.toString().length <= 8) {
            this.toaster.error('password must be 8 or more characters long');
            return;
        }
        if (this.phone == '') {
            this.toaster.error('please enter phone');
            return;
        }
        if (this.phone.toString().length != 10) {
            this.toaster.error('please enter valid phone');
            return;
        }

        let userType = 'CHEF';

        this.email = this.email.toLowerCase()

        let postData = {
            type: userType,
            email: this.email,
            phone: this.phone,
          
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'user/getUserByEmailPhone', postData).then((result) => {
            if (result.status) {
                if (result.result.emailUser != null) {
                    this.toaster.error('Email already exist');
                    return
                }
                if (result.result.phoneUser != null) {
                    this.toaster.error('Phone number already exist');
                    return
                }
                this.signupAPI();
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    signupCognito() {
        this.apiService.chefSignUp(this.name, this.email.toLowerCase(), this.password, this.phone).then((result) => {
            console.log('result:-' + JSON.stringify(result));
            // this.utilService.setItem(this.utilService.COGNITO_PROFILE, JSON.stringify(result));
            this.signupAPI();
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
            this.toaster.error(error.message);
        });
    }

    signupAPI() {
        let postData = {
            'user_name': this.name,
            'user_email': this.email,
            'user_password': this.password,
            'user_phone': this.phone,
            'region':this.region,
            'user_minimum_order_value': '0'
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'user/updateChefProfile', postData).then((result) => {
            if (result.status) {
                this.toaster.success('chef registered');
                this.activeModal.close()
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    close() {
        this.activeModal.close();
    }
}
