import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

    verification_code = '';
    resend_disabled = true;
    @Input() userDetail;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        public utilService: UtilService,
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.resendConfirmation();
        this.setResendEnableTimer();
    }

    resendConfirmation() {
        this.apiService.chefResendConfirmation(this.userDetail.email.toLowerCase()).then(result => {
            console.log('result:-' + JSON.stringify(result));
            this.setResendEnableTimer();
        }, error => {
            // console.log("error:-" + JSON.stringify(error));
            alert(JSON.parse(JSON.stringify(error)).message);
        });
    }

    timeLeft: number = 120000;
    interval;

    setResendEnableTimer() {
        this.timeLeft = 120000;
        this.interval = setInterval(() => {
            console.log('timeleft:-' + this.timeLeft);
            if (this.timeLeft > 0) {
                this.timeLeft = this.timeLeft - 1000;
                this.resend_disabled = true;
            } else {
                this.clearTimer();
                this.resend_disabled = false;
            }
        }, 1000);
    }

    clearTimer() {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
    }

    ngOnDestroy() {
        this.clearTimer();
    }

    verifyUser() {
        console.log('user profile:-' + this.utilService.getItem(this.utilService.USER_PROFILE));
        if (this.verification_code == '') {
            alert('Please enter verification code');
            return;
        }

        this.apiService.chefConfirmUser(this.userDetail.email.toLowerCase(), this.verification_code.toString()).then(result => {
            console.log('result:-' + result);
            if (result == 'SUCCESS') {
                this.setEmailVerified()
            }
        }, error => {
            try {
                if (error.code == 'CodeMismatchException') {
                    alert('Please enter valid code')
                } else if (error.code == 'UserNotFoundException') {
                    alert('Email/password is incorrect');
                }
            } catch (e) {

            }
            console.log('error:-' + JSON.stringify(error));
        });
    }

    setEmailVerified() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'user/updateEmailVerified', {
            id: this.userDetail.id,
            type: 'CHEF'
        }).then(result => {
            console.log('result:-' + result);
            if (result.status) {
                this.activeModal.close({status: true})
            } else {
                alert(result.message);
            }
        }, error => {
            console.log('error:-' + JSON.stringify(error));
        });
    }

    closeModal() {
        this.activeModal.close({status:false});
    }
}
