import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-constant',
    templateUrl: './constant.component.html',
    styleUrls: ['./constant.component.css']
})
export class ConstantComponent implements OnInit {

    base_delivery_charge = 0;
    delivery_perkm = 0;
    package_gst = 0;
    cooked_gst = 0;
    delivery_charges_gst=0;
    commission=0;

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster:ToastrService
    ) {
    }

    ngOnInit(): void {
        this.getConstants()
    }

    getConstants() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'common/getConstants').then((result) => {
            if (result.status) {
                this.base_delivery_charge = result.result.base_delivery_charge;
                this.delivery_perkm = result.result.cpkm;
                this.package_gst = result.result.packaged_gst;
                this.cooked_gst = result.result.cooked_gst;
                this.delivery_charges_gst = result.result.delivery_charges_gst;
                this.commission = result.result.commission;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    updateConstants() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/updateConstants',{
            base_delivery_charge:this.base_delivery_charge,
            cpkm:this.delivery_perkm,
            packaged_gst:this.package_gst,
            cooked_gst:this.cooked_gst,
            delivery_charges_gst:this.delivery_charges_gst,
            commission:this.commission
        }).then((result) => {
            if (result.status) {
                this.base_delivery_charge = result.result.base_delivery_charge;
                this.delivery_perkm = result.result.cpkm;
                this.package_gst = result.result.packaged_gst;
                this.cooked_gst = result.result.cooked_gst;
                this.delivery_charges_gst = result.result.delivery_charges_gst;

                this.toaster.success("Values saved");
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
}
