import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {LocationEditorComponent} from '../location-editor/location-editor.component';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-edit-teacher',
    templateUrl: './edit-teacher.component.html',
    styleUrls: ['./edit-teacher.component.css']
})
export class EditTeacherComponent implements OnInit {

    @Input() chefId;
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    basic_tab: boolean = true;
    bank_tab: boolean = false;
    location_tab: boolean = false;

    user_name = '';
    user_email = '';
    user_password = '';
    user_phone = '';
    user_address = '';
    user_landmark = '';
    google_location = '';
    account_holder_name = '';
    account_no = '';
    masked_account_no = '';
    pan_card = '';
    ifsc = '';
    account_type = '';
    user_fssai = '';
    commission = '';
    user_minimum_order_value = '';
    user_min_time = '';
    user_about = '';
region;
    selectedLocation = null;

    chefDetail = null;
    prospect_status = false;
    business_status = false;
    status = false;
    displayTag:any
    allChefTag = [];
    readAccountDetails=true;
    tagDropDownList = [];
    selectedtag = [];
    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        private toaster: ToastrService,
        public utilService: UtilService
    ) {

    }

    ngOnInit(): void {
        if (this.chefId != undefined) {
            console.log('chefDetail:-' + this.chefId);
            this.getChefDetail();
            this.getAllChefTag()
        }

        if(this.utilService.getUserTypeByLogin()==3){
            this.readAccountDetails=false;
        }
    }

    getChefDetail() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'user/getChefFullDetailByChefId', {
            chef_id: this.chefId,
            cus_id: ''
        }).then((result) => {
            console.log('chef detail:-' + JSON.stringify(result, null, 4));
            if (result.status) {
                this.chefDetail = result.result;
                this.setChefProfile();
            }
        }, (error) => {
            alert('something went wrong');
        })
    }
    getAllChefTag() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefTag').then((result) => {
            if (result.status) {
                this.allChefTag = result.result;
                for (let i = 0; i < this.allChefTag.length; i++) {
                    this.tagDropDownList = this.tagDropDownList.concat({
                        'id': this.allChefTag[i].id, 'heading': this.allChefTag[i].heading
                    })
                }
            }
        }, (error) => {
            this.toaster.error('something went wrong');
        })
    }
    setChefProfile() {
        this.user_name = this.chefDetail.profile.name;
        this.user_email = this.chefDetail.profile.email;
        this.user_phone = this.chefDetail.profile.phone;
        this.user_minimum_order_value = this.chefDetail.profile.min_order_val;
        this.region = this.chefDetail.profile.region;
        this.displayTag = this.chefDetail.profile.display_tag;
        if (this.chefDetail.profile.fssai != null) {
            this.user_fssai = this.chefDetail.profile.fssai;
        }
        this.commission= this.chefDetail.profile.commission;
        this.user_about = this.chefDetail.profile.about;
        this.user_min_time = this.chefDetail.profile.min_time;
        if (this.chefDetail.location != null) {
            this.user_address = this.chefDetail.location.address;
            this.user_landmark = this.chefDetail.location.landmark;
            this.google_location = this.chefDetail.location.formatted_address;
            this.selectedLocation = {
                place_id: this.chefDetail.location.place_id,
                formatted_address: this.chefDetail.location.formatted_address,
                location: {
                    lat: this.chefDetail.location.lat,
                    lng: this.chefDetail.location.lng
                }
            }
        }
        console.log('this.chefDetail.prospect_status:-' + this.chefDetail.profile.prospect_status);
        if (this.chefDetail.profile.prospect_status == 0) {
            this.prospect_status = false;
        } else {
            this.prospect_status = true;
        }
        if (this.chefDetail.profile.business_status == 0) {
            this.business_status = false;
        } else {
            this.business_status = true;
        }
        if (this.chefDetail.profile.status == 0) {
            this.status = false;
        } else {
            this.status = true;
        }
        if (this.chefDetail.bank_detail != null) {
            this.account_holder_name = this.chefDetail.bank_detail.account_holder_name;
            this.account_no = this.chefDetail.bank_detail.acc_no;
            this.masked_account_no = this.utilService.getMaskedAccountNumber(this.chefDetail.bank_detail.acc_no);
            this.pan_card = this.chefDetail.bank_detail.pan_card;
            this.ifsc = this.chefDetail.bank_detail.ifsc_code;
            this.account_type = this.chefDetail.bank_detail.account_type;
            this.pan_path = this.chefDetail.bank_detail.pan_path;
            this.cheque_path = this.chefDetail.bank_detail.cheque_path;
            // this.user_fssai = this.chefDetail.bank_detail.account_holder_name;
        }
    }

    close() {
        this.activeModal.close();
    }

    open_basic() {
        this.basic_tab = true;
        this.bank_tab = false;
        this.location_tab = false;
    }

    open_location() {
        this.basic_tab = false;
        this.bank_tab = false;
        this.location_tab = true;
    }

    open_bank_account() {
        this.basic_tab = false;
        this.bank_tab = true;
        this.location_tab = false;
    }

    public handleAddressChange(address: Address) {
        console.log('address:-' + JSON.stringify(address));

        this.selectedLocation = {
            place_id: address.place_id,
            formatted_address: address.formatted_address,
            location: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            }
        }
    }

    validatePost() {
        let isValid = true;
        if (this.user_name == '') {
            alert('please enter user name');
            isValid = false;
        }
        if (this.user_email == '') {
            alert('please enter user email');
            isValid = false;
        }
        if (this.user_phone == '') {
            alert('please enter user phone');
            isValid = false;
        }
        if (this.user_landmark == '') {
            alert('please enter user landmark');
            isValid = false;
        }
        if (this.selectedLocation == null) {
            alert('please select google location');
            isValid = false;
        }
        if (this.account_holder_name == '') {
            alert('please enter account holder name');
            isValid = false;
        }
        if (this.account_no == '') {
            alert('please enter account number');
            isValid = false;
        }
        if (this.ifsc == '') {
            alert('please select account type');
            isValid = false;
        }


        return isValid;
    }

    saveChef() {
        if (this.validatePost()) {
            let postData = {
                user_name: this.user_name,
                user_email: this.user_email,
                user_phone: this.user_phone,
                user_minimum_order_value: this.user_minimum_order_value,
                region: this.region,
                display_tag:this.displayTag,
                account_holder_name: this.account_holder_name,
                acc_no: this.account_no,
                ifsc_code: this.ifsc,
                account_type: this.account_type,
                user_fssai: this.user_fssai,
                commission:this.commission,
                address: this.user_address,
                user_landmark: this.user_landmark,
                formatted_address: this.selectedLocation.formatted_address,
                place_id: this.selectedLocation.place_id,
                lat: this.selectedLocation.location.lat,
                lng: this.selectedLocation.location.lng,
                google_location: this.google_location,
            }

            if (this.prospect_status) {
                postData['prospect_status'] = 1;
            } else {
                postData['prospect_status'] = 0;
            }

            if (this.business_status) {
                postData['business_status'] = 1;
            } else {
                postData['business_status'] = 0;
            }

            if (this.chefId != undefined) {
                postData['user_id'] = this.chefDetail.profile.id;
            }

            this.apiService.postAPI(this.apiService.BASE_URL + 'user/updateChefProfile', postData).then((result) => {
                console.log('chefupdated:-' + JSON.stringify(result));
                this.activeModal.close();
            }, (error) => {
                console.log('error:-' + JSON.stringify(error));
            })
        }
    }


    saveChefBasicProfile() {

        if (this.user_name == '') {
            this.toaster.error('please enter user name');
            return;
        }
        if (this.user_phone == '') {
            this.toaster.error('please enter user phone');
            return;
        }

        let postData = {
            user_name: this.user_name,
            user_email: this.user_email,
            user_phone: this.user_phone,
            user_fssai: this.user_fssai,
            commission:this.commission,
            region: this.region,
            display_tag:this.displayTag,
            user_about: this.user_about,
            user_min_time: this.user_min_time,
            user_minimum_order_value: this.user_minimum_order_value,
        }

        if (this.prospect_status) {
            postData['prospect_status'] = 1;
        } else {
            postData['prospect_status'] = 0;
        }
        if (this.status) {
            postData['status'] = 1;
        } else {
            postData['status'] = 0;
        }
        if (this.user_password != '') {
            postData['user_password'] = this.user_password;
        }

        if (this.business_status) {
            postData['business_status'] = 1;
        } else {
            postData['business_status'] = 0;
        }

        if (this.chefId != undefined) {
            postData['user_id'] = this.chefDetail.profile.id;
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/updateChef', postData).then((result) => {
            this.getChefDetail()
            this.toaster.success('Profile Updated')
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    locationEditor() {
        this.modalService.open(LocationEditorComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            centered: true
        }).closed.subscribe((result) => {
            if (result != undefined) {
                console.log('result:-' + JSON.stringify(result));
                let location = result.location;
                this.google_location = location.formatted_address;
                this.selectedLocation = location;
            }
        });
    }


    saveAddress() {

        if (this.user_address == '') {
            this.toaster.error('please enter address');
            return;
        }
        if (this.user_landmark == '') {
            this.toaster.error('please enter user landmark');
            return;
        }
        if (this.selectedLocation == null) {
            this.toaster.error('please enter location');
            return;
        }

        let postData = {
            address: this.user_address,
            landmark: this.user_landmark,
            formatted_address: this.selectedLocation.formatted_address,
            place_id: this.selectedLocation.place_id,
            lat: this.selectedLocation.location.lat,
            lng: this.selectedLocation.location.lng
        }

        if (this.chefId != undefined) {
            postData['user_id'] = this.chefDetail.profile.id;
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/addUpdateChefLocation', postData).then((result) => {
            this.getChefDetail()
            this.toaster.success('Location Updated')
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    saveChefBank() {

        if (this.account_holder_name == '') {
            this.toaster.error('please enter account holder name');
            return;
        }
        if (this.account_no == '') {
            this.toaster.error('please enter account number');
            return;
        }
        if (this.account_type == null) {
            this.toaster.error('please select account type');
            return;
        }
        if (this.pan_card == null) {
            this.toaster.error('please enter pan card');
            return;
        }

        let pan_path = '';
        if (this.pan_path != 'assets/img/default_banner.png') {
            pan_path = this.pan_path
        }

        let cheque_path = '';
        if (this.cheque_path != 'assets/img/default_banner.png') {
            cheque_path = this.cheque_path
        }

        let postData = {
            account_holder_name: this.account_holder_name,
            acc_no: this.account_no,
            ifsc_code: this.ifsc,
            account_type: this.account_type,
            pan_card: this.pan_card,
            pan_path: pan_path,
            cheque_path: cheque_path
        }

        if (this.chefId != undefined) {
            postData['user_id'] = this.chefDetail.profile.id;
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/addUpdateChefBankDetail', postData).then((result) => {
            this.getChefDetail()
            this.toaster.success('Bank Detail Updated')
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    pan_path = 'assets/img/default_banner.png';
    cheque_path = 'assets/img/default_banner.png';

    onselectimage(e, type) {
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            reader.readAsDataURL(target.files[0]);
            reader.onload = (e: any) => {
                let name = target.files[0].name;
                if (
                    name.toLowerCase().includes('.jpg')
                    || name.toLowerCase().includes('.jpeg')
                    || name.toLowerCase().includes('.png')
                ) {
                    let formData = new FormData();
                    formData.append('image', file);

                    this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/addFile', formData).then((result) => {
                        if (result.status) {
                            if (type == 'cheque') {
                                this.cheque_path = result.result;
                            } else {
                                this.pan_path = result.result;
                            }
                        } else {
                            this.toaster.error(result.message);
                        }
                    }, (error) => {
                        console.log('error:-' + JSON.stringify(error));
                    })
                } else {
                    this.toaster.error('Please upload image file');
                }
            }
        }
    }

    downloadImage(path: string) {
        window.open(path);
    }
}
