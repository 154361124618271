import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UtilService {

    USER_PROFILE = 'USER_PROFILE';
    USER_LOGIN = 'USER_LOGIN'

    ADMIN_PANEL = 'ADMIN_PANEL';
    CHEF_PANEL = 'CHEF_PANEL';

    // DEFAULTSTORAGE = this.ADMIN_PANEL;
    DEFAULTSTORAGE = environment.DEFAULTSTORAGE;

    PANEL_TYPE = 'ADMIN_PANEL';

    constructor() {

    }

    SharingData = new Subject();
    SharingAlertData = new Subject();
    private subject = new Subject<any>();

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sharedData: string;

    getImageCDNURL(imageId) {
        return 'https://imagedelivery.net/3Bz-h2mG3ai2h90cKrwv5g/' + imageId + '/public';
    }

    numberWithCommas(x) {
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return x.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
    }

    printObj(obj) {
        console.log('obj:-' + JSON.stringify(obj));
    }

    setItem(key, value) {
        window.localStorage.setItem(this.DEFAULTSTORAGE + key, value);
    }

    getItem(key) {
        return window.localStorage.getItem(this.DEFAULTSTORAGE + key);
    }

    properFormatNumber(number: number): string {
        let numString = '';
        if (number < 10) {
            numString = '0' + number;
        } else {
            numString = number + '';
        }
        return numString;
    }

    tConvert(time) {
        var timeString = time;
        var H = +timeString.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? 'AM' : 'PM';
        timeString = h + timeString.substr(2, 3) + ampm;

        return timeString;
    }

    getChefOrderPrice(price, commission) {
        return parseFloat((price - (price * (commission / 100))).toFixed(2));
    }

    getDateTimeByTime(time): any {
        if (time == null || time == undefined || time == '') {
            return '-';
        }
        var d = new Date(time);

        d.setMinutes(d.getMinutes())

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let min = this.properFormatNumber(d.getMinutes());
        let sec = this.properFormatNumber(d.getSeconds());

        // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;
        let finalDate = date + '/' + month + '/' + year + ' ' + hour + ':' + min;

        // let finalDate = year + '-' + month + '-' + date;
        return finalDate;
    }

    getAdminDateTimeByTime(time): any {
        if (time == null || time == undefined || time == '') {
            return '-';
        }
        var d = new Date(time);

        d.setMinutes(d.getMinutes() -330)

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let min = this.properFormatNumber(d.getMinutes());
        let sec = this.properFormatNumber(d.getSeconds());

        // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;
        let finalDate = date + '/' + month + '/' + year + ' ' + hour + ':' + min;

        // let finalDate = year + '-' + month + '-' + date;
        return finalDate;
    }
    getTodayDate(): any {
        var d = new Date();

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let finalDate = year + '-' + month + '-' + date;
        // let finalDate = '2021-11-08';

        return finalDate;
    }

    getDeliveryDT(time): any {
        if (time == null || time == undefined || time == '') {
            return '-';
        }
        var d = new Date(time);
        // try{
        //
        // }catch (e) {
        //
        // }

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let min = this.properFormatNumber(d.getMinutes());
        let sec = this.properFormatNumber(d.getSeconds());

        // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;
        let finalDate = date + '/' + month + '/' + year + ' ' + hour + ':' + min;

        // let finalDate = year + '-' + month + '-' + date;
        return finalDate;
    }

    get1HourBeforeDT(time): any {
        if (time == null || time == undefined || time == '') {
            return '-';
        }
        var d = new Date(time);

        d.setHours(d.getHours() - 1);

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let min = this.properFormatNumber(d.getMinutes());
        let sec = this.properFormatNumber(d.getSeconds());

        // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;

        // let finalDate = year + '-' + month + '-' + date;
        return finalDate;
    }

    getOrder1HourBeforeDT(time): any {
        if (time == null || time == undefined || time == '') {
            return '-';
        }
        var d = new Date(time);

        d.setMinutes(d.getMinutes() - 390);

        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let min = this.properFormatNumber(d.getMinutes());
        let sec = this.properFormatNumber(d.getSeconds());

        // let finalDate = this.getDayName(day) + ' ' + date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        // let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min + ':' + sec;
        let finalDate = date + ' ' + this.getMonthName(month) + ',' + year + ' at ' + hour + ':' + min;

        // let finalDate = year + '-' + month + '-' + date;
        return finalDate;
    }

    getTodayByTimeObj(): any {
        var d = new Date();
        return {'year': d.getFullYear(), 'month': d.getMonth() + 1, 'day': d.getDate()}
    }

    getMonthName(month) {
        switch (month) {
            case '01':
                return 'Jaunurary';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
        }
        return 'Janurary';
    }

    parseGraphMonthYear(yearMonth) {
        let month = yearMonth.split('-')[1];
        return this.getMonthShortName(Number(month)) + ',' + yearMonth.split('-')[0];
    }

    getMonthShortName(month) {
        switch (month) {
            case 1:
                return 'Jan';
            case 2:
                return 'Feb';
            case 3:
                return 'Mar';
            case 4:
                return 'Apr';
            case 5:
                return 'May';
            case 6:
                return 'Jun';
            case 7:
                return 'Jul';
            case 8:
                return 'Aug';
            case 9:
                return 'Sep';
            case 10:
                return 'Oct';
            case 11:
                return 'Nov';
            case 12:
                return 'Dec';
        }
        return 'Janurary';
    }

    getUserID() {
        if (this.getItem(this.USER_PROFILE) != null && this.getItem(this.USER_PROFILE) != undefined && this.getItem(this.USER_PROFILE) != '' && this.getItem(this.USER_PROFILE) != 'null') {
            let profile = JSON.parse(this.getItem(this.USER_PROFILE));
            return profile.id;
        } else {
            return '';
        }
    }

    getUserType() {
        if (this.getItem(this.USER_PROFILE) != null && this.getItem(this.USER_PROFILE) != undefined && this.getItem(this.USER_PROFILE) != '' && this.getItem(this.USER_PROFILE) != 'null') {
            let profile = JSON.parse(this.getItem(this.USER_PROFILE));
            if (profile.hasOwnProperty('type')) {
                return '2';
            } else {
                return '1';
            }
        }
        return ''
    }

    getUserTypeByLogin() {
        if (this.getItem(this.USER_PROFILE) != null && this.getItem(this.USER_PROFILE) != undefined && this.getItem(this.USER_PROFILE) != '' && this.getItem(this.USER_PROFILE) != 'null') {
            let profile = JSON.parse(this.getItem(this.USER_PROFILE));
            if (profile.hasOwnProperty('type')) {
                return profile.type;
            }
        }
        return ''
    }

    getMaskedAccountNumber(accNo) {
        console.log('accNO:-' + accNo)
        if (accNo != null && accNo != undefined) {
            let accountNumber: string = accNo + '';
            if (accountNumber.length > 4) {
                let last4Chars = accountNumber.substr(accountNumber.length - 4);
                let totalLength = accountNumber.length;
                console.log('length:-' + totalLength);
                let accountno = '';
                for (let i = 0; i < (totalLength - 4); i++) {
                    accountno += 'X';
                }

                accountno += last4Chars;
                console.log('accNO:-' + accountno)
                return accountno;
            } else {
                return accountNumber;
            }
        }

        return accNo;
    }

    getPanelType() {
        return this.getItem(this.PANEL_TYPE);
    }

    getUserProfile() {
        if (this.getItem(this.USER_PROFILE) != null && this.getItem(this.USER_PROFILE) != undefined && this.getItem(this.USER_PROFILE) != '' && this.getItem(this.USER_PROFILE) != 'null') {
            let profile = JSON.parse(this.getItem(this.USER_PROFILE));
            return profile;
        } else {
            return null;
        }
    }

    getTimerTimeLeft(millis) {
        // let secs = time / 1000;
        // let timeleft = (secs / 60) + ':' + (secs % 60);
        // return timeleft;
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return ((Number(minutes) < 10) ? '0' : '') + minutes + ':' + ((Number(seconds) < 10) ? '0' : '') + seconds;
    }

    clearALLData() {
        window.localStorage.clear();
    }

    printKeyValuePairs(url: string, postdata) {
        console.log('url:-' + url);
        console.log('--------------FORM DATA---------------');
        let data = '';
        postdata.forEach((value, key) => {
            // console.log(key + ':' + value)
            data += key + ':' + value + '\n';
        });
        console.log(data);
        console.log('--------------FORM DATA---------------');
    }

    printUrlPostData(url: string, postdata) {
        console.log('url:-' + url);
        console.log('postData:-' + JSON.stringify(postdata, null, 4));

    }

    validateEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true
        }
        return false
    }

    calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = this.toRad(lat2 - lat1);
        var dLon = this.toRad(lon2 - lon1);
        lat1 = this.toRad(lat1);
        lat2 = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    calculateGSTAmount(price, taxPer) {
        return Number((price * (taxPer / 100)).toFixed(2));
    }

    getFixedAmount(amount, fixed) {
        if (isNaN(amount)) {
            return amount;
        } else {
            return Number(Number(amount).toFixed(fixed));
        }
    }

    calculateTotal(amount1, amount2) {
        return Number(amount1) + Number(amount2);
    }

    // Converts numeric degrees to radians
    toRad(Value) {
        return Value * Math.PI / 180;
    }

    isUserLoggedIn() {
        if (this.getItem(this.USER_LOGIN) == '1') {
            return true;
        } else {
            return false;
        }
    }

    getKeys(id) {
        switch (id) {
            case 1:
                return 'A';
            case 2:
                return 'B';
            case 3:
                return 'C';
            case 4:
                return 'D';
            case 5:
                return 'E';
            case 6:
                return 'F';
            case 7:
                return 'G';
            case 8:
                return 'H';
            case 9:
                return 'I';
            case 10:
                return 'J';
            case 11:
                return 'K';
            case 12:
                return 'L';
            case 13:
                return 'M';
            case 14:
                return 'N';
            case 15:
                return 'O';
            case 16:
                return 'P';
            case 17:
                return 'Q';
            case 18:
                return 'R';
            case 19:
                return 'S';
            case 20:
                return 'T';
            case 21:
                return 'U';
            case 22:
                return 'V';
            case 23:
                return 'W';
            case 24:
                return 'X';
            case 25:
                return 'Y';
            case 26:
                return 'Z';
        }

        return '';
    }

    formatReportName() {
        var d = new Date();
        let date = this.properFormatNumber(d.getDate());
        let month = this.properFormatNumber(d.getMonth() + 1);
        let year = d.getFullYear();

        let hour = this.properFormatNumber(d.getHours());
        let minutes = this.properFormatNumber(d.getMinutes() + 1);
        let sec = this.properFormatNumber(d.getSeconds() + 1);

        let finalDate = year + '-' + month + '-' + date + '-' + hour + ':' + minutes + ':' + sec;
        return finalDate;
    }

    b64toBlob(b64Data, contentType = 'image/png', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/png'});
        return blob;
    }

}
