import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {AddUpdateEventComponent} from '../../modals/add-update-event/add-update-event.component';
import { ConfirmPopupComponent } from 'app/modals/confirm-popup/confirm-popup.component';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

    events = [];

    constructor(
        private modalService: NgbModal,
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster: ToastrService
    ) {

    }

    ngOnInit(): void {
        this.getAllEvents();
    }

    addEvent() {
        const modalRef = this.modalService.open(AddUpdateEventComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('status') && result.status) {
                this.getAllEvents()
            }
        })
    }

    getAllEvents() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'events/getAllAdminEvents').then((result) => {
            console.log('result:-' + JSON.stringify(result));
            if (result.status) {
                this.events = result.result;
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })
    }

    editEvent(item: any) {
        const modalRef = this.modalService.open(AddUpdateEventComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.eventId = item.id;
        modalRef.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('status') && result.status) {
                this.getAllEvents()
            }
        })
    }

    deleteEvent(id: any) {
        this.apiService.getAPI(this.apiService.BASE_URL + 'events/changeEventStatus/' + id + '/0').then((result) => {
            console.log('result:-' + JSON.stringify(result));
            if (result.status) {
                this.getAllEvents()
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })
    }
    openDeleteEventPopup(item:any) {
        const modalRef = this.modalService.open(ConfirmPopupComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllEvents();
        })
        modalRef.componentInstance.user_id = item.id;
        modalRef.componentInstance.DeleteText = `Do you really want to Delete ${item.name} Event ?`;
        modalRef.componentInstance.BtnText = "OK";
        modalRef.componentInstance.title = "Event";
        // modalRef.componentInstance.BtnFunction = this.deleteCuisine(item);
    }
    // deleteEvent(id: any) {
    //     let postData = {
    //         user_id: id
    //     }
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'events/deleteEventStatus' ,postData).then((result) => {
    //         console.log('result:-' + JSON.stringify(result));
    //         if (result.status) {
    //             this.getAllEvents()
    //         } else {
    //             this.toaster.error(result.message);
    //         }
    //     }, (error) => {
    //         console.log('error:-' + error.message);
    //         this.toaster.error('Something went wrong');
    //     })
    // }
}
