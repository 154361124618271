import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';

@Component({
  selector: 'app-chef-prospect-update',
  templateUrl: './chef-prospect-update.component.html',
  styleUrls: ['./chef-prospect-update.component.css']
})
export class ChefProspectUpdateComponent implements OnInit {

  @Input() chefStatusId;
  admin_msg_prospect = ''

  constructor(
      private modalService: NgbModal,
      private activeModal: NgbActiveModal,
      private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.getChefSubmittedBusiness()
  }

  chefProspectDetail = null;
  chefProspectData = null;
  chefPropectCuisines = '';
  chefPropectTag = '';

  getChefSubmittedBusiness() {
    this.apiService.getAPI(this.apiService.BASE_URL + 'chefstatus/getChefStatusById/' + this.chefStatusId).then((result) => {
      console.log('chefupdated:-' + JSON.stringify(result));
      if (result.status) {
        this.chefProspectDetail = result.result;
        this.chefProspectData = JSON.parse(result.result.data).prospect;
        let cuisineNames = '';
        for (let i = 0; i < this.chefProspectData.cuisines.length; i++) {
          if (i == 0) {
            cuisineNames = this.chefProspectData.cuisines[i].name;
          } else {
            cuisineNames += ' , ' + this.chefProspectData.cuisines[i].name;
          }
        }
        this.chefPropectCuisines = cuisineNames;
        let tagNames = '';
        for (let i = 0; i < this.chefProspectData.chef_tagging.length; i++) {
          if (i == 0) {
            tagNames = this.chefProspectData.chef_tagging[i].heading;
          } else {
            tagNames += ' , ' + this.chefProspectData.chef_tagging[i].heading;
          }
        }
        this.chefPropectTag = tagNames;
      }
    }, (error) => {
      console.log('error:-' + JSON.stringify(error));
    })
  }

  changeChefUpdateStatus(status: number) {
    if(this.chefProspectDetail!=null){
      if(status==2&&this.admin_msg_prospect==''){
        alert("Please enter admin message");
        return;
      }
      this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/adminSubmitStatus',{
        status:status,
        admin_msg:this.admin_msg_prospect,
        id:this.chefProspectDetail.id
      }).then((result) => {
        console.log('chefupdated:-' + JSON.stringify(result));
        if (result.status) {
          this.activeModal.close();
        }
      }, (error) => {
        console.log('error:-' + JSON.stringify(error));
      })
    }
  }

  closeModal() {
    this.activeModal.close()
  }
}
