import {Component, OnInit,ElementRef, Renderer2, ViewChild,AfterViewChecked} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {CropImageComponent} from '../../modals/crop-image/crop-image.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-home-cms',
    templateUrl: './home-cms.component.html',
    styleUrls: ['./home-cms.component.css']
})

export class HomeCMSComponent implements OnInit {
    newlyAddedChefIndex: number;
    newlyAddedFaqIndex: number;
    newlyAddedTestimonialsIndex: number;
showAccordion: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]; 

    // showAccordian= false;
    showAccordian1=false;
    showAccordian2=false;
    showAccordian3=false;
    showAccordian4=false;
    showAccordian5=false;
    isLoading: boolean = false; // Variable to track loader state
rating;
selectedDishes: any[] = []; 
avgRating = 0;
    allChefs = [];
    allCuisines = [];
    allOccassion = [];
    CMS14 = {
        SO4: {
            heading: '',
            chefTag: []
        },
    }
    CMS = {
        S1_BANNERS: [],
        S2: {
            heading: '',
            cuisines: []
        },
        SO3: {
            heading: '',
            occassion: []
        },
        SO4: {
            heading: '',
            chefTag: []
        },
        S14: {
            heading: '',
            cuisines: []
        },
        S3: {
            heading: '',
            heading2: '',
            C1Title: '',
            C1Description: '',
            C2Title: '',
            C2Description: '',
            C3Title: '',
            C3Description: '',
        },
        S4: {
            title: '',
            descriptions: [],
            image: ''
        },
        S5: {
            heading: '',
            chefs: [],
        },
        S6: {
            data1: '',
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            label1: '',
            label2: '',
            label3: '',
            label4: '',
            label5: '',
        },
        S7: {
            heading: '',
            banner1: [],
            banner2: [],
            banner3: []
        },
        S8: {
            heading: '',
            title1: '',
            title2: '',
            email: '',
            image: '',
        },
        S9: {
            heading: '',
            title: '',
            description: '',
            image: '',
            label1: '',
            content1: '',
            label2: '',
            content2: '',
            label3: '',
            content3: '',
            label4: '',
            content4: '',
        },
        S10: {
            heading: '',
            link1: '',
            link2: '',
            link3: '',
            link4: '',
            image1: '',
            image2: '',
            image3: '',
            image4: '',
        },
        S12faq: [],
        S13: {
            title1: '',
            description1: '',
            image1: '',
            image2: '',
            title2: '',
            description2: ''
        },
        testimonials: [],
        app_home_dishes: [],
        S15:{
          label1:'',
          link:'',
          text:'' 
        },
        S16: {
            title1: '',
            link1: '',
            image1: '',
            image2: '',
            title2: '',
            link2: ''
        },
        VideoS1:{
            heading:'',
            desc:'',
            bacImage:''
        },
        ContactUs:{
            heading:'',
            desc:'',
        }
    }
    selectedFeatures: any;
alldishes = []
    constructor(
        public apiService: ApiService,
        private toaster: ToastrService,
        private modalService: NgbModal,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.getCMS()
        this.getAllChefs();
        this.getAllCuisines();
        this.getAllOccassion();
        // if (this.newlyAddedChefSection) {
        //     this.scrollToNewlyAddedChef();
        //   }
        // this.CMS = JSON.parse(result.result[0].content);
    }
    features = [{ name: 'Feature 1', value: 1 }, { name: 'Feature 2', value: 2 }, { name: 'Feature 3', value: 3 },
    {name: 'Feature 4', value: 4 },
   { name: 'Feature 5', value: 5 },
    {name: 'Feature 6', value: 6 },
  ]

 
  @ViewChild('addChefSection', { static: false }) addChefSection: ElementRef;
  @ViewChild('lastChefSection', { static: false }) lastChefSection: ElementRef;
  @ViewChild('newlyAddedChefSection', { static: false }) newlyAddedChefSection: ElementRef;
    removeCuisines(index) {
        this.CMS.S2.cuisines.splice(index, 1);
    }
    removeOccassion(index) {
        this.CMS.SO3.occassion.splice(index, 1);
    }
    removechefTag(index) {
        this.CMS.SO4.chefTag.splice(index, 1);
    }
    removeCuisinesPop(index) {
        this.CMS.S14.cuisines.splice(index, 1);
    }
    showLoader() {
        this.isLoading = true;
        console.log("loader")
      }
    
      // Function to hide loader
      hideLoader() {
        this.isLoading = false;
      }


showToggle(index: number) {
    this.showAccordion[index] = !this.showAccordion[index];
    for (let i = 0; i < this.showAccordion.length; i++) {
        if (i !== index) {
            this.showAccordion[i] = false;
        }
    }
}

    getCMS() {
        console.log(this.CMS,"before CMS")
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/getAllCMS', {
            names: ['HOME']
        }).then((result) => {
            if (result.status) {
                if (result.result[0].content != null && result.result[0].content != undefined && result.result[0].content != '') {
                    this.CMS = JSON.parse(result.result[0].content);
                    // this.CMS.SO4 = this.CMS14.SO4;
                    console.log(this.CMS,"CMS")
                    console.log(this.CMS,"after")
                    if (!this.CMS.hasOwnProperty('S10')) {
                        this.CMS['S10'] = {
                            heading: '',
                            link1: '',
                            link2: '',
                            link3: '',
                            link4: '',
                            image1: '',
                            image2: '',
                            image3: '',
                            image4: '',
                        }
                    }
                }
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    getAllChefs() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'user/getAllChefs').then((result) => {
            if (result.status) {
                this.allChefs = result.result;
                console.log('allchefs:-' + JSON.stringify(this.allChefs));
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    getAllCuisines() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefCuisine').then((result) => {
            if (result.status) {
                this.allCuisines = result.result;
                console.log(result.result,"occassion");
            }
        })
    }
    getAllOccassion() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefTag').then((result) => {
            if (result.status) {
                this.allOccassion = result.result;
                console.log(result.result,"occassion");
                
            }
        })
    }
    removeS1Banner(i: number) {
        this.CMS.S1_BANNERS.splice(i, 1);
    }
saveChefCMS(){
    
    //     for (let i = 0; i <= this.CMS.S5.chefs[i]?.selectedFeatures.length; i++) {
    //         this.CMS.S5.chefs[i].selectedFeatures = []
    //         console.log(this.CMS.S5.chefs[i],"check");
    //         console.log(i,"index");
    //         console.log(this.CMS.S5.chefs[i]?.selectedFeatures.length,"dishes check");
            
    // }

   

//     for (let i = 0; i < this.CMS.S5.chefs.length; i++) {

//         for (let index = 0; index < this.CMS.S5.chefs[i].selectedFeatures.length; index++) 
//         {
//             this.CMS.S5.chefs[i].selectedFeatures = []
//             break;
//         //console.log(this.CMS.S5.chefs[i],"check");
//         //console.log(i,"index");
//         //console.log(this.CMS.S5.chefs[i]?.selectedFeatures.length,"dishes check");
//         }
// }
    this.saveCMS();
}

    saveCMS() {
        // this.CMS.S5.chefs.forEach(function(item){
        //     item.selectedFeatures = [];
        // });
        console.log(this.CMS,"Ssave cms");
        console.log(JSON.stringify(this.CMS)," stringfy Ssave cms");
        
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/addCMS', {
            name: 'HOME',
            content: JSON.stringify(this.CMS)
        }).then((result) => {
            if (result.status) {
                console.log(JSON.parse(result.result.content),"after save cms");
                
                this.toaster.success('Data saved');
                // this.CMS = JSON.parse(result.result[0].content);
                    // console.log(JSON.parse(result.result),"CMS")
                this.getCMS()
                // this.CMS = JSON.parse(result.result);
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
    saveCMSPop() {
       console.log(JSON.stringify(this.CMS))
            // content: JSON.stringify(this.CMS)
       
    }
    onselectimage(e, type, index) {
        this.showLoader();
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            if (
                type == 'S1_IMAGE' || type == 'S4_IMAGE' || type == 'S10' || type == 'S7_BANNER_1' || type == 'S7_BANNER_2' || type == 'S7_BANNER_3' || type == 'HOME_S8' || type == 'HOME_S9' || type == 'HOME_S13_1' || type == 'HOME_S13_2' || type == 'HOME_S16_1' || type == 'HOME_S16_2' || type == 'VideoS1'
            ) {
                reader.readAsDataURL(target.files[0]);
                reader.onload = (e: any) => {
                    let name = file.name;
                    if (type == 'S10') {
                       
                        this.uploadS10Image(type, file, index);
                    } else {
                        this.uploadImage(type, file, index)
                        
                    }
                    this.hideLoader();
                }
                e.target.value = null;
            }
            else if (type == 'VideoS1_VIDEO') {
                reader.readAsDataURL(target.files[0]);
                reader.onload = (e: any) => {
                    let name = file.name;
                    if (
                        name.toLowerCase().includes('.mp4')
                    ) {
                        this.uploadImageView(type, file)
                    } else {
                        alert('Please upload png image file');
                    }
                }
                e.target.value = null;
            }
        }
    }

    uploadImageView(type, file) {
        let formData = new FormData();
        formData.append('image', file);
        if (type == 'VideoS1_VIDEO') {
            formData.append('file_name', 'VideoS1_VIDEO.mp4')
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/bacImages', formData).then((result) => {
            if (result.status) {
                alert('file uploaded')
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    openImageCropper(type, index) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage(type, result.image, index)
            }
        });

        if (type == 'S1_IMAGE') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'S4_IMAGE') {
            modal.componentInstance.ratio = 7 / 4;
            modal.componentInstance.width = 1100;
        } else if (type == 'S7_BANNER_1') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 200;
        } else if (type == 'S7_BANNER_2') {
            modal.componentInstance.ratio = 2 / 1;
            modal.componentInstance.width = 1100;
        } else if (type == 'S7_BANNER_3') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 1100;
        } else if (type == 'HOME_S8') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'VideoS1') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        }
         else if (type == 'HOME_S9') {
            modal.componentInstance.ratio = 1.2 / 1;
            modal.componentInstance.width = 900;
        } else if (type == 'HOME_S9') {
            modal.componentInstance.ratio = 1.2 / 1;
            modal.componentInstance.width = 900;
        } else if (type == 'HOME_S13_1') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 601;
        } else if (type == 'HOME_S13_2') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 345;
        } else if (type == 'HOME_S16_1') {
            modal.componentInstance.ratio = 3.9 / 1;
            modal.componentInstance.width = 900;
        } else if (type == 'HOME_S16_2') {
            modal.componentInstance.ratio = 3.9 / 1;
            modal.componentInstance.width = 900;
        } else if (type == 'HOME_TESTIMONIAL') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 200;
        }
    }

    openChefImageCropper(index, position) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadChefImage(result.image, index, position);
            }
        });

        modal.componentInstance.ratio = 1 / 1;
        modal.componentInstance.width = 170;

    }

    uploadChefImage(image, index, position) {
        this.showLoader();
        let postData = {
            image: image
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/homeCMSBase64Images', postData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                // this.uploadToCDN(result.result);
                if (position == 1) {
                    this.CMS.S5.chefs[index]['image1'] = result.result;
                } else if (position == 2) {
                    this.CMS.S5.chefs[index]['image2'] = result.result;
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
		 this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    uploadToCDN(path) {
        let url = 'https://api.cloudflare.com/client/v4/accounts/31b3781e6d3481a66f09b1e3feb1338f/images/v1';
        let formData = new FormData();
        formData.append('url', path);
        formData.append('requireSignedURLs', 'false');

        this.apiService.postAPIWHeader(url, formData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                // this.uploadToCDN(result.result);
                // if (position == 1) {
                //     this.CMS.S5.chefs[index]['image1'] = result.result;
                // } else if (position == 2) {
                //     this.CMS.S5.chefs[index]['image2'] = result.result;
                // }
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    uploadImage(type, file, index) {
        // let formData = new FormData();
        // formData.append('image', file);
        this.showLoader();
        let postData = {
            image: file
        }
        if (type == 'VideoS1_VIDEO') {
            postData['file_name']= 'VideoS1_video.mp4'
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/homeCMSBase64Images', postData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                if (type == 'S1_IMAGE') {
                    this.CMS.S1_BANNERS[index].image = result.result;
                    console.log('banners:-' + JSON.stringify(this.CMS.S1_BANNERS));
                    // this.loading = true;
                } else if (type == 'S4_IMAGE') {
                    this.CMS.S4.image = result.result;
                } else if (type == 'S7_BANNER_1') {
                    this.CMS.S7.banner1.push({
                        image: result.result
                    });
                } else if (type == 'S7_BANNER_2') {
                    this.CMS.S7.banner2.push({
                        image: result.result
                    });
                } else if (type == 'S7_BANNER_3') {
                    this.CMS.S7.banner3.push({
                        image: result.result
                    });
                } else if (type == 'HOME_S8') {
                    this.CMS.S8.image = result.result;
                } else if (type == 'VideoS1') {
                    this.CMS.VideoS1. bacImage = result.result;
                } else if (type == 'HOME_S9') {
                    this.CMS.S9.image = result.result;
                } else if (type == 'HOME_S13_1') {
                    this.CMS.S13.image1 = result.result;
                } else if (type == 'HOME_S13_2') {
                    this.CMS.S13.image2 = result.result;
                } else if (type == 'HOME_S16_1') {
                    this.CMS.S16.image1 = result.result;
                } else if (type == 'HOME_S16_2') {
                    this.CMS.S16.image2 = result.result;
                } else if (type == 'HOME_TESTIMONIAL') {
                    this.CMS.testimonials[index].userimage = result.result;
                } else if (type == 'S10') {
                    if (index == 1) {
                        this.CMS.S10.image1 = result.result;
                    } else if (index == 2) {
                        this.CMS.S10.image2 = result.result;
                    } else if (index == 3) {
                        this.CMS.S10.image3 = result.result;
                    } else if (index == 4) {
                        this.CMS.S10.image4 = result.result;
                    }
                    this.saveCMS()
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
                this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    uploadS10Image(type, file, index) {
        this.showLoader();
        let formData = new FormData();
        formData.append('image', file);

        this.apiService.postAPI(this.apiService.BASE_URL + 'common/addFile', formData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                if (index == 1) {
                    this.CMS.S10.image1 = result.result;
                } else if (index == 2) {
                    this.CMS.S10.image2 = result.result;
                } else if (index == 3) {
                    this.CMS.S10.image3 = result.result;
                } else if (index == 4) {
                    this.CMS.S10.image4 = result.result;
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
		 this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    addS1Banner() {
        this.CMS.S1_BANNERS.push({
            title: '',
            image: ''
        })
    }

    addS4Description() {
        if (this.CMS.S4.descriptions == null || this.CMS.S4.descriptions == undefined) {
            this.CMS.S4.descriptions = [];
        }
        this.CMS.S4.descriptions.push({
            data: ''
        });
    }


    deleteS4Description(i: any) {
        this.CMS.S4.descriptions.splice(i, 1);
    }

    addCuisine() {
        this.CMS.S2.cuisines.push({
            id: 0
        })
    }
    addOccassion() {
        this.CMS.SO3.occassion.push({
            id: 0
        })
    }
    addChefTag() {
        this.CMS.SO4.chefTag.push({
            id: 0
        })
    }
    addCuisinePop() {
        this.CMS.S14.cuisines.push({
            id: 0
        })
    }
    
    addChef() {
        this.newlyAddedChefIndex = this.CMS.S5.chefs.length;
        this.CMS.S5.chefs.push({
            image1: 'assets/img/default_banner.png',
            image2: 'assets/img/default_banner.png',
            quote: '',
            description: '',
            Rating:'',
            selectedFeatures:[],
            selectedDishes:[],
        })
        setTimeout(() => {
            this.scrollToNewlyAddedChef();
        }, 100);

    }
    scrollToNewlyAddedChef() {
    const sectionId = `#chefSection${this.newlyAddedChefIndex}`;
    const element = document.querySelector(sectionId);

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}
scrollToNewlyAddedFaq() {
    const sectionId = `#faqSection${this.newlyAddedFaqIndex}`;
    const element = document.querySelector(sectionId);

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

    onSelectChefImage(e, index, position) {
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            reader.readAsDataURL(target.files[0]);
            reader.onload = (e: any) => {
                let name = file.name;
                if (
                    name.toLowerCase().includes('.jpg')
                    || name.toLowerCase().includes('.jpeg')
                    || name.toLowerCase().includes('.png')
                ) {
                    let formData = new FormData();
                    formData.append('image', file);
                    this.apiService.postAPI(this.apiService.BASE_URL + 'common/homeCMSImages', formData).then((result) => {
                        if (result.status) {
                            if (position == 1) {
                                this.CMS.S5.chefs[index]['image1'] = result.result;
                            } else if (position == 2) {
                                this.CMS.S5.chefs[index]['image2'] = result.result;
                            }
                        } else {
                            alert(result.message);
                        }
                    }, (error) => {
                        console.log('error:-' + JSON.stringify(error));
                    })
                } else {
                    alert('Please upload image file');
                }
            }

        }
    }

    removeS7Banner1(i: number) {
        this.CMS.S7.banner1.splice(i, 1)
    }

    removeS7Banner2(i: number) {
        this.CMS.S7.banner2.splice(i, 1)
    }

    removeS7Banner3(i: number) {
        this.CMS.S7.banner3.splice(i, 1)
    }

    addFAQ() {
        if (this.CMS.S12faq == undefined) {
            this.CMS.S12faq = [];
        }
        this.newlyAddedFaqIndex = this.CMS.S12faq.length;
        this.CMS.S12faq.push({
            question: '',
            answer: ''
        })
        setTimeout(() => {
            this.scrollToNewlyAddedFaq();
        }, 100);
    }

    removeFAQ(i: number) {
        this.CMS.S12faq.splice(i, 1);
    }

    addTestimonial() {
        if (this.CMS.testimonials == undefined) {
            this.CMS.testimonials = [];
        }
        this.newlyAddedTestimonialsIndex = this.CMS.testimonials.length;
        this.CMS.testimonials.push({
            username: '',
            chefname: '',
            userimage: '',
            title: '',
            rating: '',
            description: ''
        })
        setTimeout(() => {
            this.scrollToNewlyAddedTestimonials();
        }, 100);
    }
    scrollToNewlyAddedTestimonials() {
        const sectionId = `#testimonialsSection${this.newlyAddedTestimonialsIndex}`;
        const element = document.querySelector(sectionId);
    
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    addDishes() {
        if (this.CMS.app_home_dishes == undefined) {
            this.CMS.app_home_dishes = [];
        }
        this.CMS.app_home_dishes.push({
            chef_id: -1,
            chef_items: [],
            item_id: -1
        })
    }
    updateSelectedDishes(index: number) {
        const item = this.CMS.S5.chefs[index];
        item.selectedDishes = item.selectedFeatures.filter((dish) => dish.selected);
    }
    removeTestimonial(i: number) {
        this.CMS.testimonials.splice(i, 1);
    }

  
    removeAppHomeDishes(i: number) {
        this.CMS.app_home_dishes.splice(i, 1);
    }

    onChefSelected(index) {
        setTimeout(() => {
            this.CMS.app_home_dishes[index].chef_items = [];
            this.CMS.app_home_dishes[index].item_id = -1;
            if (this.CMS.app_home_dishes[index].chef_id != -1) {
                this.apiService.postAPI(this.apiService.BASE_URL + 'items/getItemsByChefId', {
                    chef_id: this.CMS.app_home_dishes[index].chef_id
                }).then((result) => {
                    if (result.status) {
                        this.CMS.app_home_dishes[index].chef_items = result.result;
                        console.log('json:-' + JSON.stringify(this.CMS.app_home_dishes))
                    } else {
                        this.toaster.error('No Items found for this chef');
                    }
                }, (error) => {
                    this.toaster.error('Something went wrong');
                })
            }
        }, 300)
    }
    
    onChef(index) {
        console.log(index);
        
        setTimeout(() => {
            this.alldishes = [];
                this.apiService.postAPI(this.apiService.BASE_URL + 'items/getItemsByChefId', {
                    chef_id: index
                }).then((result) => {
                    if (result.status) {
                        this.alldishes = result.result;
                        this.CMS.S5.chefs.filter(x => x.chef_id == index)[0].selectedFeatures = this.alldishes;
                        this.getChefRating(index,index)
                    } else {
                        this.toaster.error('No Items found for this chef');
                    }
                }, (error) => {
                    this.toaster.error('Something went wrong');
                })
            }, 300)
    }
    
    slected(item,id){
            this.CMS.S5.chefs.filter(x => x.chef_id == id)[0].selectedDishes = item
            this.selectedFeatures = this.CMS.S5.chefs.filter(x => x.chef_id == id)[0].selectedDishes 
      }

      getChefRating(index,item) {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefRating/getChefRatingByUserId/' + index).then((result) => {
            if (result.status) {
                this.rating = result.result;
                this.rating = result.result;
                let rating = 0;
            for (let i = 0; i < result.result.length; i++) {
                rating += result.result[i].rating;
            }

                if (result.result.length != 0) {
                    this.avgRating = rating / result.result.length;
                    this.avgRating = Number(this.avgRating.toFixed(1));

                  

                }
                
               
                this.CMS.S5.chefs.filter(x => x.chef_id == index)[0].Rating = this.avgRating
                console.log(this.avgRating,"Total Rating chef");
                
              
                  
            }
            if(result.status== false){
                this.CMS.S5.chefs.filter(x => x.chef_id == index)[0].Rating = 5

            }
        });
    
    }
    savePopularDishes() {

      

        if (this.CMS.app_home_dishes.length != 0) {
            for (let i = 0; i < this.CMS.app_home_dishes.length; i++) {
                if (this.CMS.app_home_dishes[i].chef_id == -1) {
                    this.toaster.error('Please select chef at App Home Popular Dishes');
                    return;
                }
                if (this.CMS.app_home_dishes[i].item_id == -1) {
                    this.toaster.error('Please select Dish at App Home Popular Dishes');
                    return;
                }
            }
            
        }
    
        this.saveCMS()
    }

    removeHomeCMSChef(i: number) {
        this.CMS.S5.chefs.splice(i, 1);
    }
}
