import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.css']
})
export class PhoneVerificationComponent implements OnInit {

  verification_code = '';
  resend_disabled = true;
  @Input() userDetail;

  constructor(
      private modalService: NgbModal,
      private activeModal: NgbActiveModal,
      public utilService: UtilService,
      private apiService: ApiService,
      private toaster:ToastrService
  ) {
  }

  ngOnInit(): void {
    this.sendOTPTodevice();
  }

  timeLeft: number = 120000;
  interval;

  setResendEnableTimer() {
    this.timeLeft = 120000;
    this.interval = setInterval(() => {
      // console.log('timeleft:-' + this.timeLeft);
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1000;
        this.resend_disabled = true;
      } else {
        this.clearTimer();
        this.resend_disabled = false;
      }
    }, 1000);
  }

  clearTimer() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  sendOTPTodevice() {
    console.log("user Profile:-" + JSON.stringify(this.utilService.getUserProfile()));
    let profile = this.utilService.getUserProfile();
    if (profile.phone != '') {

      let userType = "chef";

      this.apiService.postAPI(this.apiService.BASE_URL + "user/generateOTP", {
        id: this.utilService.getUserID(),
        type: userType
      }).then((result) => {
        if (result.status) {
          this.toaster.success("OTP sent to phone");
          this.setResendEnableTimer();
        }
      }, (error) => {
        console.log("something went wrong");
      })
    }
  }


  submitOTP() {
    console.log("submit otp");
    if (this.verification_code.toString().length == 0) {
      this.toaster.error("please enter otp");
      return;
    }

    let userType = "chef";

    this.apiService.postAPI(this.apiService.BASE_URL + "user/verifyOTP", {
      id: this.utilService.getUserID(),
      type: userType,
      otp: this.verification_code
    }).then((result) => {
      if (result.status) {
        this.utilService.setItem(this.utilService.USER_LOGIN, '1');
        this.activeModal.close({
          status:true
        })
      } else {
        this.toaster.error(result.message);
      }
    }, (error) => {
      console.log("something went wrong");
    })

  }

  closeModal() {
    this.activeModal.close({
      status: false
    })
  }
}
