import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broadcast-coupon',
  templateUrl: './broadcast-coupon.component.html',
  styleUrls: ['./broadcast-coupon.component.css']
})
export class BroadcastCouponComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
