import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {CropImageComponent} from '../crop-image/crop-image.component';
import {ToastrService} from 'ngx-toastr';
import {UtilService} from '../../service/util.service';


@Component({
  selector: 'app-add-coupon-page',
  templateUrl: './add-coupon-page.component.html',
  styleUrls: ['./add-coupon-page.component.css']
})
export class AddCouponPageComponent implements OnInit {
 
  @Input() couponDetail;
  heading = '';
  coupon_desc = '';
  validity_end_date;
  validity_start_date;
  usage_limit;
  coupon_value;
  categoryList = [];
  default_image: any = 'assets/img/default_banner.png';
  type ='percent'; 
  usage_limit_type = 'single'; 
  usage_limit_value;
  status = 'active'; 
  minimum_value;
  loading: boolean = false; 
  constructor( private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private toaster: ToastrService,
    public utilService: UtilService) { }
  ngOnInit(): void {
    this.categoryList.push({id: '1', name: 'occassion'})
    this.categoryList.push({id: '2', name: 'collection'})
    if (this.couponDetail != null) {
      this.heading = this.couponDetail.coupon_code;
      this.coupon_desc = this.couponDetail.coupon_desc;
      this.usage_limit = this.couponDetail.usage_limit_value;
      this.coupon_value= this.couponDetail.coupon_value;
      this.default_image = this.couponDetail.image;
      this.validity_end_date = new Date(this.couponDetail.validity_end_date).toISOString().slice(0, 10);
      this.validity_start_date = new Date(this.couponDetail.validity_start_date).toISOString().slice(0, 10);
      this.status = this.couponDetail.status;
      this.usage_limit_type = this.couponDetail.usage_limit;
      this.minimum_value= this.couponDetail.minimum_value
this.type = this.couponDetail.type
      // this.type = this.couponDetail.type;
    
  }
  // console.log(this.couponDetail.validity_end_date,"inde3x");
  
  }
  closeModal() {
    this.activeModal.close();
}

saveOccassion() {
    if (this.couponDetail != null) {
        //update
        this.updateCoupon()
    } else {
        //add
        this.addCoupon()
    }
}

updateCoupon() {
    // if (this.heading == '') {
    //     this.toaster.error('Please enter name');
    // }
    // if (this.descrption == '') {
    //     this.toaster.error('Please enter tag');
    // }
  //   if (this.type == '') {
  //     this.toaster.error('Please enter tag');
  // }
    // if (this.default_image == 'assets/img/default_banner.png') {
    //     this.toaster.error('Please select image')
    // }

    let post = {
        id: this.couponDetail.id,
        coupon_code : this.heading,
      coupon_desc : this.coupon_desc,
      usage_limit : this.usage_limit_type,
      coupon_value: this.coupon_value,
      validity_end_date : new Date(this.validity_end_date).toISOString().slice(0, 10),
      validity_start_date : new Date(this.validity_start_date).toISOString().slice(0, 10),
      status : this.status,
      usage_limit_value : this.usage_limit,
      minimum_value:this.minimum_value,
type : this.type
    }

    this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/updateCoupon', post).then((result) => {
        if (result.status) {
            this.activeModal.close();
        } else {
            alert(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })

}

addCoupon() {
  if (this.heading == '') {
    this.toaster.error('Please enter Coupon');
}
if (this.coupon_desc == '') {
    this.toaster.error('Please enter coupn desc');
}
if (this.usage_limit_type == '') {
  this.toaster.error('Please enter usage limit type');
}

//     if (this.default_image == 'assets/img/default_banner.png') {
//         this.toaster.error('Please select image')
//     }

    let post = {
      coupon_code : this.heading,
      coupon_desc : this.coupon_desc,
      usage_limit : this.usage_limit_type,
      coupon_value: this.coupon_value,
      validity_end_date : new Date(this.validity_end_date).toISOString().slice(0, 10),
      validity_start_date : new Date(this.validity_start_date).toISOString().slice(0, 10),
      status : this.status,
      usage_limit_value : this.usage_limit,
      minimum_value:this.minimum_value,
type : this.type
    }
    this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/addCoupon', post).then((result) => {
        if (result.status) {
            this.activeModal.close();
        } else {
            alert(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })

}


openImageCropper(type, index) {
    let modal = this.modalService.open(CropImageComponent, {
        backdrop: 'static',
        size: 'xl',
        keyboard: false,
        centered: true
    })
    modal.closed.subscribe((result) => {
        if (result != null && result != undefined && result.hasOwnProperty('image')) {
            console.log('image:-' + result.image);
            this.uploadImage(type, result.image, index)
        }
    });

    if (type == 'OCCASSION') {
        modal.componentInstance.ratio = 1 / 1;
        modal.componentInstance.width = 300;
    }

}

uploadImage(type, file, index) {
    this.loading = true;
    let postData = {
        image: file,
    }

    // if (type == 'S1_IMAGE1') {
    //     postData['image_name'] = 's1b1.png';
    // } else if (type == 'S2_IMAGE') {
    //     postData['image_name'] = 's2.png';
    // }

    this.apiService.postAPI(this.apiService.BASE_URL + 'common/uploadChefTagImage', postData).then((result) => {
        if (result.status) {
            this.default_image = result.result;
            this.loading = false;
        } else {
            this.toaster.error(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })
}
}
