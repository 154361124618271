import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {CropImageComponent} from '../crop-image/crop-image.component';
import {ToastrService} from 'ngx-toastr';
import {UtilService} from '../../service/util.service';

@Component({
  selector: 'app-addupdateoccassion-and-collection',
  templateUrl: './addupdateoccassion-and-collection.component.html',
  styleUrls: ['./addupdateoccassion-and-collection.component.css']
})
export class AddupdateoccassionAndCollectionComponent implements OnInit {

 
  @Input() cuisineDetail;
  heading = '';
  descrption = '';
  type = '';
  categoryList = [];
  default_image: any = 'assets/img/default_banner.png';
  selectedFile;
  fileTarget;
  loading: boolean = false; 
  constructor( private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private toaster: ToastrService,
    public utilService: UtilService) { }
  ngOnInit(): void {
    this.categoryList.push({id: '1', name: 'occassion'})
    this.categoryList.push({id: '2', name: 'collection'})
    if (this.cuisineDetail != null) {
      this.heading = this.cuisineDetail.heading;
      this.descrption = this.cuisineDetail.descrption;
      this.default_image = this.cuisineDetail.image;
      // this.type = this.cuisineDetail.type;
    
  }
  console.log(this.cuisineDetail,"inde3x");
  
  }
  closeModal() {
    this.activeModal.close();
}

saveOccassion() {
    if (this.cuisineDetail != null) {
        //update
        this.updateCuisine()
    } else {
        //add
        this.addCuisine()
    }
}

updateCuisine() {
    if (this.heading == '') {
        this.toaster.error('Please enter name');
    }
    // if (this.descrption == '') {
    //     this.toaster.error('Please enter tag');
    // }
  //   if (this.type == '') {
  //     this.toaster.error('Please enter tag');
  // }
    if (this.default_image == 'assets/img/default_banner.png') {
        this.toaster.error('Please select image')
    }

    let post = {
        id: this.cuisineDetail.id,
        heading: this.heading,
        descrption: this.descrption,
        image: this.default_image,
        type: this.type
    }

    this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/updateChefTag', post).then((result) => {
        if (result.status) {
            this.activeModal.close();
        } else {
            alert(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })

}

addCuisine() {
  if (this.heading == '') {
    this.toaster.error('Please enter name');
}
// if (this.descrption == '') {
//     this.toaster.error('Please enter tag');
// }
// if (this.type == '') {
//   this.toaster.error('Please enter tag');
// }

    if (this.default_image == 'assets/img/default_banner.png') {
        this.toaster.error('Please select image')
    }

    let post = {
      heading: this.heading,
      descrption: this.descrption,
      image: this.default_image,
      type: this.type
    }
    this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/addChefTag', post).then((result) => {
        if (result.status) {
            this.activeModal.close();
        } else {
            alert(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })

}

// onselectimage(e) {
//     if (e.target.files) {
//         const target: DataTransfer = <DataTransfer>(e.target);
//         this.fileTarget = target;
//         const reader: FileReader = new FileReader();
//         let file = this.fileTarget.files[0];
//         reader.readAsDataURL(this.fileTarget.files[0]);
//         reader.onload = (e: any) => {
//             let name = this.fileTarget.files[0].name;
//             if (
//                 name.toLowerCase().includes('.jpg')
//                 || name.toLowerCase().includes('.jpeg')
//                 || name.toLowerCase().includes('.png')
//             ) {
//                 this.default_image = reader.result;
//             } else {
//                 alert('Please upload image file');
//             }
//         }
//     }
// }
openImageCropper(type, index) {
    let modal = this.modalService.open(CropImageComponent, {
        backdrop: 'static',
        size: 'xl',
        keyboard: false,
        centered: true
    })
    modal.closed.subscribe((result) => {
        if (result != null && result != undefined && result.hasOwnProperty('image')) {
            console.log('image:-' + result.image);
            this.uploadImage(type, result.image, index)
        }
    });

    if (type == 'OCCASSION') {
        modal.componentInstance.ratio = 1 / 1;
        modal.componentInstance.width = 300;
    }

}

uploadImage(type, file, index) {
    this.loading = true;
    let postData = {
        image: file,
    }

    // if (type == 'S1_IMAGE1') {
    //     postData['image_name'] = 's1b1.png';
    // } else if (type == 'S2_IMAGE') {
    //     postData['image_name'] = 's2.png';
    // }

    this.apiService.postAPI(this.apiService.BASE_URL + 'common/uploadChefTagImage', postData).then((result) => {
        if (result.status) {
            this.default_image = result.result;
            this.loading = false;
        } else {
            this.toaster.error(result.message);
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })
}
}
