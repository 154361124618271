import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ToastrModule} from 'ngx-toastr';

import {SidebarModule} from './sidebar/sidebar.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import {AppComponent} from './app.component';
import {AppRoutes} from './app.routing';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EditTeacherComponent} from './modals/edit-teacher/edit-teacher.component';
import {FormsModule} from '@angular/forms';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {ChefDetailComponent} from './pages/chef-detail/chef-detail.component';
import {ChefCategoryComponent} from './modals/chef-category/chef-category.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {CusisineComponent} from './pages/cusisine/cusisine.component';
import {AddUpdateCuisineComponent} from './modals/add-update-cuisine/add-update-cuisine.component';
import {AddUpdateItemComponent} from './modals/add-update-item/add-update-item.component';
import {OrdersComponent} from './pages/orders/orders.component';
import {CategoryComponent} from './pages/category/category.component';
import {FoodItemsComponent} from './pages/food-items/food-items.component';
import {AlertsComponent} from './pages/alerts/alerts.component';
import {ConfirmationModalComponent} from './modals/confirmation-modal/confirmation-modal.component';
import {EditOrderComponent} from './modals/edit-order/edit-order.component';
import {LoginComponent} from './pages/login/login.component';
import {LocationEditorComponent} from './modals/location-editor/location-editor.component';
import {HomeCMSComponent} from './pages/home-cms/home-cms.component';
import {BecomeChefCMSComponent} from './pages/become-chef-cms/become-chef-cms.component';
import {EmailVerificationComponent} from './modals/email-verification/email-verification.component';
import {ChefBusinessUpdateComponent} from './modals/chef-business-update/chef-business-update.component';
import {ChefProspectUpdateComponent} from './modals/chef-prospect-update/chef-prospect-update.component';
import {ChefCuisineComponent} from './pages/chef-cuisine/chef-cuisine.component';
import {AddChefCuisineComponent} from './modals/add-chef-cuisine/add-chef-cuisine.component';
import {ColorPipe} from './pages/color.pipe';
import {ConstantComponent} from './pages/constant/constant.component';
import {AddChefComponent} from './modals/add-chef/add-chef.component';
import {RolesUsersComponent} from './pages/roles-users/roles-users.component';
import {AddUpdateUserComponent} from './modals/add-update-user/add-update-user.component';
import {SubscribedUsersComponent} from './pages/subscribed-users/subscribed-users.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PhoneVerificationComponent} from './modals/phone-verification/phone-verification.component';
import {PasswordResetComponent} from './modals/password-reset/password-reset.component';
import {ReportComponent} from './pages/reports/report/report.component';
import {ServingGeofencingComponent} from './pages/serving-geofencing/serving-geofencing.component';
import { AddUpdateGeofenceComponent } from './modals/add-update-geofence/add-update-geofence.component';
import { OurStoryCMSComponent } from './pages/our-story-cms/our-story-cms.component';
import { OrderReportComponent } from './pages/order-report/order-report.component';
import { ChefProfileUpdateComponentComponent } from './pages/chef-profile-update-component/chef-profile-update-component.component';
import { ViewFailedOrderComponent } from './modals/view-failed-order/view-failed-order.component';
import { WaitingFoodItemModalComponent } from './modals/waiting-food-item-modal/waiting-food-item-modal.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PopUsersComponent } from './pop-users/pop-users.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { CropImageComponent } from './modals/crop-image/crop-image.component';
import { EventsComponent } from './pages/events/events.component';
import { AddUpdateEventComponent } from './modals/add-update-event/add-update-event.component';
import { EventOrdersComponent } from './pages/event-orders/event-orders.component';
import { BroadcastNotificationComponent } from './pages/broadcast-notification/broadcast-notification.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import {DatePipe} from '@angular/common';
import { NgxTrimModule } from 'ngx-trim';
import { ConfirmPopupComponent } from './modals/confirm-popup/confirm-popup.component';
import { BroadcastMessageComponent } from './pages/broadcast-message/broadcast-message.component';
import { OccassionAndCollectionComponent } from './pages/occassion-and-collection/occassion-and-collection.component';
import { AddupdateoccassionAndCollectionComponent } from './modals/addupdateoccassion-and-collection/addupdateoccassion-and-collection.component';
import { ChefTaggingComponent } from './pages/chef-tagging/chef-tagging.component';
import { AddUpdateChefTaggingComponent } from './modals/add-update-chef-tagging/add-update-chef-tagging.component';
import { LoaderComponent } from './loader/loader.component';
import { CouponPageComponent } from './pages/coupon-page/coupon-page.component';
import { AddCouponPageComponent } from './modals/add-coupon-page/add-coupon-page.component';
import { BroadcastCouponComponent } from './pages/broadcast-coupon/broadcast-coupon.component';
@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        EditTeacherComponent,
        ChefDetailComponent,
        ChefCategoryComponent,
        CusisineComponent,
        AddUpdateCuisineComponent,
        AddUpdateItemComponent,
        OrdersComponent,
        CategoryComponent,
        FoodItemsComponent,
        AlertsComponent,
        ConfirmationModalComponent,
        EditOrderComponent,
        LocationEditorComponent,
        HomeCMSComponent,
        BecomeChefCMSComponent,
        EmailVerificationComponent,
        ChefBusinessUpdateComponent,
        ChefProspectUpdateComponent,
        ChefCuisineComponent,
        AddChefCuisineComponent,
        ColorPipe,
        ConstantComponent,
        AddChefComponent,
        RolesUsersComponent,
        AddUpdateUserComponent,
        SubscribedUsersComponent,
        PhoneVerificationComponent,
        PasswordResetComponent,
        ReportComponent,
        ServingGeofencingComponent,
        AddUpdateGeofenceComponent,
        OurStoryCMSComponent,
        OrderReportComponent,
        ChefProfileUpdateComponentComponent,
        ViewFailedOrderComponent,
        WaitingFoodItemModalComponent,
        PopUsersComponent,
        CropImageComponent,
        EventsComponent,
        AddUpdateEventComponent,
        EventOrdersComponent,
        BroadcastNotificationComponent,
        ChangePasswordComponent,
        ConfirmPopupComponent,
        BroadcastMessageComponent,
        OccassionAndCollectionComponent,
        AddupdateoccassionAndCollectionComponent,
        ChefTaggingComponent,
        AddUpdateChefTaggingComponent,
        CouponPageComponent,
        AddCouponPageComponent,
        LoaderComponent,
        BroadcastCouponComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        // RouterModule.forRoot(AppRoutes, {
        //     useHash: true
        // }),
        NgxTrimModule,
        RouterModule.forRoot(AppRoutes),
        SidebarModule,
        NavbarModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        FooterModule,
        FixedPluginModule,
        NgbModule,
        FormsModule,
        GooglePlaceModule,
        UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#80FFA2',
            defaultBgColor: '#00ACFF',
            defaultBoColor: '#476EFF',
            checkedLabel: 'on',
            uncheckedLabel: 'off'
        }),
        NgMultiSelectDropDownModule.forRoot(),
        NgApexchartsModule,
        ImageCropperModule
    ],
    providers: [
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
