import {Component, OnInit} from '@angular/core';
import {UtilService} from '../service/util.service';
import {Router} from '@angular/router';
import { ApiService } from 'app/service/api.service';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const CHEF_ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: ''},
    {path: '/user', title: 'User Profile', icon: 'nc-single-02', class: ''},
    {path: '/category', title: 'category', icon: 'nc-spaceship', class: ''},
    {path: '/chef-cuisine', title: 'Chef Cuisine', icon: 'nc-spaceship', class: ''},
    // {path: '/chef-tagging', title: 'Chef Tagging', icon: 'nc-spaceship', class: ''},
    {path: '/food-items', title: 'Food Items', icon: 'nc-caps-small', class: ''},
    {path: '/orders', title: 'Orders', icon: 'nc-tile-56', class: ''},
    {path: '/alerts', title: 'Alerts', icon: 'nc-bell-55', class: ''},
];

export const ADMIN_ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: ''},
    {path: '/chef', title: 'Chef', icon: 'user-circle', class: ''},
    {path: '/driver/5', title: 'Drivers', icon: 'user-circle', class: ''},
    {path: '/finance/3', title: 'Finance', icon: 'user-circle', class: ''},
    {path: '/ops/4', title: 'Ops', icon: 'user-circle', class: ''},
    {path: '/cuisine', title: 'Cuisine', icon: 'cutlery', class: ''},
    {path: '/cheftaggingmaster', title: 'Chef Tagging master', icon: 'cutlery', class: ''},
    {path: '/orders', title: 'Orders', icon: 'coffee', class: ''},
    {path: '/event-orders', title: 'Event Orders', icon: 'coffee', class: ''},
    {path: '/events', title: 'Events', icon: 'coffee', class: ''},
    {path: '/alerts', title: 'Alerts', icon: 'nc-bell-55', class: ''},
    {path: '/notifications', title: 'Notifications', icon: 'nc-bell-55', class: '' },
    {path: '/home-cms', title: 'HOME CMS', icon: 'h-square', class: ''},
    {path: '/become-chef-cms', title: 'Become a Chef CMS', icon: 'user-plus', class: ''},
    {path: '/our-story-cms', title: 'Our Story CMS', icon: 'file', class: ''},
    {path: '/constant', title: 'Constant', icon: 'creative-commons', class: ''},
    {path: '/subscribed-users', title: 'Subscribed Users', icon: 'creative-commons', class: ''},
    {path: '/report', title: 'Report', icon: 'file', class: ''},
    {path: '/finance-report', title: 'Finance Report', icon: 'file', class: ''},
    {path: '/serving-geofence', title: 'Geofence', icon: 'file', class: ''},
    {path: '/popup-users', title: 'Popup Users', icon: 'file', class: ''},
    {path: '/broadcast-notification', title: 'Broadcast', icon: 'file', class: ''},
    {path: '/change-password', title: 'Change Password', icon: 'file', class: ''},
    {path: '/broadcast-message', title: 'Broadcast Message', icon: 'file', class: ''},
    {path: '/add-coupon', title: 'Add Coupon', icon: 'nc-bank', class: ''},

];

export const FINANCE_ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: ''},
    {path: '/chef', title: 'Chef', icon: 'user-circle', class: ''},
    {path: '/orders', title: 'Orders', icon: 'coffee', class: ''},
    {path: '/alerts', title: 'Alerts', icon: 'nc-bell-55', class: ''},
    {path: '/notifications', title: 'Notifications', icon: 'nc-bell-55', class: ''},
    {path: '/finance-report', title: 'Finance Report', icon: 'file', class: ''},
    {path: '/events', title: 'Events', icon: 'file', class: ''},
    {path: '/event-orders', title: 'Event Orders', icon: 'file', class: ''},
    {path: '/change-password', title: 'Change Password', icon: 'file', class: ''},
];

export const OPS_ROUTES: RouteInfo[] = [
    {path: '/home-cms', title: 'HOME CMS', icon: 'h-square', class: ''},
    {path: '/become-chef-cms', title: 'Become a Chef CMS', icon: 'user-plus', class: ''},
    {path: '/our-story-cms', title: 'Our Story CMS', icon: 'file', class: ''},
    {path: '/change-password', title: 'Change Password', icon: 'file', class: ''},
];

export let ROUTES: RouteInfo[] = [];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    alertCount:any;
    allAlerts = []
    declinedAlerts = []

    allTab = true;
    declinedTab = false;
    AlertCount=[]
    READ_NOTIFICATION_CSS = 'readNotification';
    UNREAD_NOTIFICATION_CSS = 'unreadNotification';

    constructor(public utilService: UtilService, private router: Router, public apiService : ApiService) {
    }

    ngOnInit() {
        if (this.utilService.isUserLoggedIn()) {
            if (this.utilService.DEFAULTSTORAGE == this.utilService.ADMIN_PANEL) {
                console.log('user type:-' + this.utilService.getUserTypeByLogin());
                if (this.utilService.getUserTypeByLogin() == '2') {
                    this.menuItems = ADMIN_ROUTES.filter(menuItem => menuItem);
                    ROUTES = ADMIN_ROUTES;
                } else if (this.utilService.getUserTypeByLogin() == '3') {
                    this.menuItems = FINANCE_ROUTES.filter(menuItem => menuItem);
                    ROUTES = FINANCE_ROUTES;
                } else if (this.utilService.getUserTypeByLogin() == '4') {
                    this.menuItems = OPS_ROUTES.filter(menuItem => menuItem);
                    ROUTES = OPS_ROUTES;
                }
            } else {
                this.menuItems = CHEF_ROUTES.filter(menuItem => menuItem);
                ROUTES = CHEF_ROUTES;
            }
            if (this.utilService.getUserType() == '2') {

            } else if (this.utilService.getUserType() == '1') {

            }
        } else {
            this.router.navigateByUrl('/login')
        }
        this.utilService.SharingAlertData.subscribe((value) => {
            // console.log(value,"dddd");
            // this.utilService.setItem("value",value)
            // this.count=this.utilService.getItem("value")
            this.alertCount= value
            console.log(this.alertCount,"alerts");
            
          })
this.getAlertsByUserId()
    }
    getAlertsByUserId() {
        let user_id = '';
        if (this.utilService.getUserType() == '1') {
            user_id = this.utilService.getUserID();
        } else {
            user_id = '0';
        }
        this.declinedAlerts = [];
        this.allAlerts = [];
        this.AlertCount=[]
        this.apiService.getAPI(this.apiService.BASE_URL + 'notification/getNotificationByUserId/' + user_id).then((result) => {
            if (result.status) {
                let notifications = [];
                if (this.utilService.getUserType() == '2') {
                    for (let i = 0; i < result.result.length; i++) {
                        if (
                            result.result[i].type == 1
                            || result.result[i].type == 2
                            || result.result[i].type == 3
                            || result.result[i].type == 4
                            || result.result[i].type == 5
                            || result.result[i].type == 14
                        ) {
                            notifications.push(result.result[i])
                        }
                    }
                } else {
                    notifications = result.result;
                }

                for (let i = 0; i < notifications.length; i++) {
                    if (notifications[i].type == 3) {
                        this.declinedAlerts.push(notifications[i]);
                    }
                    this.allAlerts.push(notifications[i]);
                    if (notifications[i].read_status == 0){
                        this.AlertCount.push(notifications[i])
                    console.log((this.AlertCount).length,"alerts");
                    }
                    this.utilService.SharingAlertData.next((this.AlertCount).length);
                }
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })

    }
}
