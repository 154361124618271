import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.css']
})
export class BroadcastMessageComponent implements OnInit {

  constructor(public utilService: UtilService,
    private apiService: ApiService,
    private toaster: ToastrService) { }
    messaage = '';
   title = '';
  ngOnInit(): void {
    this.getMessage();
  }

  getMessage(){
    this.apiService.getAPI(this.apiService.BASE_URL + 'common/getbroadcastMessage').then((result) => {
      if (result.status) {
          // this.toaster.success('Message sent');
          this.messaage = result.result.message;
          this.title = result.result.title;
      } else {
          this.toaster.error(result.message);
      }
  }, (error) => {
      console.log('error:-' + error.message);
      this.toaster.error('Something went wrong:-' + error.message);
  })

  }
  submitMessage() {

   

    // if (this.messaage == '') {
    //     this.toaster.error('Please enter message');
    //     return;
    // }

    this.apiService.postAPI(this.apiService.BASE_URL + 'common/addbroadcastMessage', {
        message: this.messaage,
        title: this.title
    }).then((result) => {
        if (result.status) {
            this.toaster.success('Broadcast message updated');
            // this.messaage = '';
            this.getMessage()
        } else {
            this.toaster.error(result.message);
        }
    }, (error) => {
        console.log('error:-' + error.message);
        this.toaster.error('Something went wrong:-' + error.message);
    })


}
}
