import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FixedPluginComponent} from './fixedplugin.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [RouterModule, CommonModule, NgbModule],
    declarations: [FixedPluginComponent],
    exports: [FixedPluginComponent]
})

export class FixedPluginModule {
}
