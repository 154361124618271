import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-chef-cuisine',
    templateUrl: './add-chef-cuisine.component.html',
    styleUrls: ['./add-chef-cuisine.component.css']
})
export class AddChefCuisineComponent implements OnInit {

    cuisineList = [];
    selectedCuisine = '';
    @Input() chef_id='';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal,
        private activeModal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
        this.getAllCuisine()
    }

    getAllCuisine() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefCuisine').then((result) => {
            if (result.status) {
                this.cuisineList = result.result;
                console.log(result.result,"result");
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
        console.log(this.cuisineList,"chef tag");
    }

    closeModal() {
        this.activeModal.close()
    }

    saveCuisine() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'chefcuisine/addChefCuisine', {
            user_id: this.chef_id,
            cuisine_id: this.selectedCuisine
        }).then((result) => {
            if (result.status) {
                this.closeModal()
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
}
