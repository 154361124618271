import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../service/api.service';
import { ToastrService } from 'ngx-toastr';
import { CropImageComponent } from '../crop-image/crop-image.component';
import { UtilService } from '../../service/util.service';

@Component({
    selector: 'app-add-update-item',
    templateUrl: './add-update-item.component.html',
    styleUrls: ['./add-update-item.component.css']
})
export class AddUpdateItemComponent implements OnInit {

    @Input() chef_id;
    @Input() itemDetail;
food_arrange ='';
numberArray: number[] = [];
    categoryList = [];
    cuisineList = [];
    foodTypeList = [];
    foodAvaibility = [];
    qtyTypeList = [];
    foodCategoryList = [];
    chefMenuList = [];
    chefMealList = [];
    chefDietList = [];
    chefTagList=[];
    chefMenu: any;
    chefMeal: any;
    chefDiet: any;
    chefTag: any;
    displayTag: any;
    name = '';
    category = '';
    food_type = '';
    food_avaibility = '';
    base_price = 0;
    person_service = 0;
    qty = 0;
    short_desc = '';
    long_desc = '';
    disclaimer = '';
    qty_type = '';
    prep_time = '';
    food_category = '';
    cuisine = '';
    fileEvent;
    special_item = false;
    spicy_level = '';
    calories = 0;
    uploadedFileList = [];
    itemsdsf: any;
    newFilterString: any;
    selectedValues: string[] = [];
    selectedMealValues: string[] = [];
    selectedDietValues: string[] = [];
    selectedTagValues: string[] = [];
    selectedDisplayTagValues: string[] = [];
    isLoading: boolean = false; // Variable to track loader state
    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        private toaster: ToastrService,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        // this.getCategories();
        this.getCuisines();
        this.getMenuList();
        this.getAllFoodType();
        this.getAllMealType();
        this.getAllDietType();
        this.getMasterTagging();
        this.populateNumberArray();
        // this.foodTypeList.push('Veg');
        // this.foodTypeList.push('Egg');
        // this.foodTypeList.push('Non Veg');
        // this.foodTypeList.push('Vegan');
        // this.foodTypeList.push('Keto');
        // const chefMenuIds = this.convertStringToArray(this.itemDetail.menu_list);
        this.categoryList.push({ id: '1', name: 'Packaged Food' })
        this.categoryList.push({ id: '2', name: 'Cooked Food' })
        this.foodAvaibility.push({ id: '1', name: 'Available' })
        this.foodAvaibility.push({ id: '0', name: 'Not serving this time' })
        this.qtyTypeList.push({ id: '1', name: 'gms' });
        this.qtyTypeList.push({ id: '2', name: 'kg' });
        this.qtyTypeList.push({ id: '3', name: 'pcs' });
        this.qtyTypeList.push({ id: '4', name: 'l' });
        this.qtyTypeList.push({ id: '5', name: 'ml' });
        this.qtyTypeList.push({ id: '6', name: 'in' });
        this.qtyTypeList.push({ id: '7', name: 'Combo Meal' });

        this.foodCategoryList.push({ id: '1', name: 'Beverage' });
        this.foodCategoryList.push({ id: '2', name: 'Bread' });
        this.foodCategoryList.push({ id: '3', name: 'Dessert' });
        this.foodCategoryList.push({ id: '4', name: 'Main Course' });
        this.foodCategoryList.push({ id: '6', name: 'Noodle' });
        this.foodCategoryList.push({ id: '7', name: 'Rice' });
        this.foodCategoryList.push({ id: '8', name: 'Starter' });

        // this.chefMenuList.push({id: '1', name: 'Appetisers'});
        // this.chefMenuList.push({id: '2', name: 'Mains'});
        // this.chefMenuList.push({id: '3', name: 'Bestsellers'});
        // this.chefMenuList.push({id: '4', name: 'Mutton'});
        // this.chefMenuList.push({id: '5', name: 'Desserts'});

        // this.itemsdsf= this.itemDetail.menu_list.filter(x => this.chefMenu?.includes(x.id));
        //         console.log(this.itemDetail.menu_list,"91")
        //    this.itemsdsf =this.itemDetail.menu_list
        //         console.log(this.itemsdsf,"93")
        //         const resultArray = this.convertStringToArray(this.itemDetail.menu_list);
        // console.log(resultArray,"result array");

        if (this.itemDetail != null) {
            this.name = this.itemDetail.name;
            this.category = this.itemDetail.category;
            if (this.itemDetail.images != null && this.itemDetail.images != '') {
                this.uploadedFileList = JSON.parse(this.itemDetail.images);
            }
            this.food_type = this.itemDetail.food_type;
            this.food_avaibility = this.itemDetail.availability;
            this.food_arrange= this.itemDetail.arrange_items;
            this.base_price = this.itemDetail.base_price;
            this.short_desc = this.itemDetail.short_desc;
            this.long_desc = this.itemDetail.long_desc;
            this.disclaimer = this.itemDetail.disclaimer;
            this.qty = this.itemDetail.qty;
            this.qty_type = this.itemDetail.qty_type;
            this.person_service = this.itemDetail.person_service;
            this.prep_time = this.itemDetail.prep_time;
            this.food_category = this.itemDetail.food_category;
            this.chefMenu = this.convertStringToArray(this.itemDetail.menu_list);
            this.chefMeal = this.convertStringToArray(this.itemDetail.meal_type);
            console.log(this.itemDetail, "itemdetail");

            this.chefDiet = this.convertStringToArray(this.itemDetail.diet_type);
            this.chefTag = this.convertStringToArray(this.itemDetail.master_tagging);
            this.displayTag =this.itemDetail.display_tag?.split(',');
            // this.displayTag = this.convertStringToArray(this.itemDetail.display_tag);
            this.cuisine = this.itemDetail.cuisine;
            this.special_item = false;
            this.spicy_level = this.itemDetail.spicy_level;

            //    if (this.itemDetail.menu_list != null){
            //     this.selectedValues.push(this.chefMenu)
            //    }
            //    console.log(this.selectedValues,"sewlec 125");

            if (this.itemDetail.is_special == 1) {
                this.special_item = true;
            }
            this.calories = this.itemDetail.calories;
        }

        this.chefFoodItemUpdateStatus();
        console.log("window locaton",window.location)

    }
    populateNumberArray(): void {
        for (let i = 1; i <= 100; i++) {
            this.numberArray.push(i);
        }
    }
    // convertStringToArray(inputString: string): number[] {
    //     const stringArray = inputString.split(','); 
    //     return stringArray.map(Number);
    //   }
    // getCategories() {
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'category/getAllCategory', {
    //         chef_id: this.chef_id
    //     }).then((result) => {
    //         if (result.status) {
    //             this.categoryList = result.result;
    //         }
    //     }, (error) => {
    //         console.log('something went wrong');
    //     })
    // }
    getMenuList() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllMenuList').then((result) => {
            if (result.status) {
                this.chefMenuList = result.result;
                // if (this.itemDetail && this.itemDetail.chefMenu) {
                //     this.selectedValues = this.itemDetail.chefMenu.split(',');
                //   } 
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    showLoader() {
        this.isLoading = true;
        console.log("loader")
      }
    
      // Function to hide loader
      hideLoader() {
        this.isLoading = false;
      }
    getAllFoodType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllFoodType').then((result) => {
            if (result.status) {
                this.foodTypeList = result.result;

            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    isSelected(menuId: number): boolean {
        return this.chefMenu?.includes(menuId);
    }
    isMealSelected(menuId: number): boolean {
        return this.chefMeal?.includes(menuId);
    }
    isDietSelected(menuId: number): boolean {
        return this.chefDiet?.includes(menuId);
    }
    isTagSelected(menuId: number): boolean {
        return this.chefTag?.includes(menuId);
    }
    isDisplayTagSelected(menuId: string): boolean {
        return this.displayTag?.includes(menuId);
    }
    convertStringToArray(inputString: string): number[] {
        const stringArray = inputString?.split(',');
        return stringArray?.map(Number);
    }

    getAllMealType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllmealtype').then((result) => {
            if (result.status) {
                this.chefMealList = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    getAllDietType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAlldiettype').then((result) => {
            if (result.status) {
                this.chefDietList = result.result;
                console.log(this.chefDietList, "Deal type list");
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    getMasterTagging() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefTagByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.chefTagList = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
    getCuisines() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefCuisineByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.cuisineList = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    onCheckboxChange(value: string) {
        if (this.itemDetail?.menu_list != undefined) {
            const menuId = Number(value);

            if (isNaN(menuId)) {
                return;
            }

            if (this.isSelected(menuId)) {
                this.chefMenu = this.chefMenu.filter((id) => id !== menuId);
            } else {
                this.chefMenu.push(menuId);
            }

            this.selectedValues = this.chefMenu.map(String);
        }
        else {
            if (this.selectedValues.includes(value)) {
                this.selectedValues = this.selectedValues.filter(val => val !== value);
            } else {
                this.selectedValues.push(value);
            }
        }
        console.log(this.selectedValues, "values");
    }

    onCheckboxChangeMeal(value: string) {
        if (this.itemDetail?.meal_type != undefined) {
            const menuId = Number(value);

            if (isNaN(menuId)) {
                return;
            }

            if (this.isMealSelected(menuId)) {
                this.chefMeal = this.chefMeal.filter((id) => id !== menuId);
            } else {
                this.chefMeal.push(menuId);
            }

            this.selectedMealValues = this.chefMeal.map(String);
        }
        else {
            if (this.selectedMealValues.includes(value)) {
                this.selectedMealValues = this.selectedMealValues.filter(val => val !== value);
            } else {
                this.selectedMealValues.push(value);
            }
        }
        console.log(this.selectedMealValues, "Meal values");
    }

    onCheckboxChangeDiet(value: string) {
        if (this.itemDetail?.diet_type != undefined) {
            const menuId = Number(value);

            if (isNaN(menuId)) {
                return;
            }

            if (this.isDietSelected(menuId)) {
                this.chefDiet = this.chefDiet.filter((id) => id !== menuId);
            } else {
                this.chefDiet.push(menuId);
            }

            this.selectedDietValues = this.chefDiet.map(String);
        }
        else {
            if (this.selectedDietValues.includes(value)) {
                this.selectedDietValues = this.selectedDietValues.filter(val => val !== value);
            } else {
                this.selectedDietValues.push(value);
            }
        }
        console.log(this.selectedDietValues, "Diet values");
    }

    onCheckboxChangeTag(value: string) {
        if (this.itemDetail?.master_tagging != undefined) {
            const menuId = Number(value);

            if (isNaN(menuId)) {
                return;
            }

            if (this.isTagSelected(menuId)) {
                this.chefTag = this.chefTag.filter((id) => id !== menuId);
            } else {
                this.chefTag.push(menuId);
            }

            this.selectedTagValues = this.chefTag.map(String);
        }
        else {
            if (this.selectedTagValues.includes(value)) {
                this.selectedTagValues = this.selectedTagValues.filter(val => val !== value);
            } else {
                this.selectedTagValues.push(value);
            }
        }
        console.log(this.selectedTagValues, "Diet values");
    }
    // onCheckboxChangeDisplayTag(value: string) {
    //     if (this.itemDetail?.display_tag != undefined) {
    //         const menuId = Number(value);

    //         if (isNaN(menuId)) {
    //             return;
    //         }

    //         if (this.isDisplayTagSelected(menuId)) {
    //             this.displayTag = this.displayTag.filter((id) => id !== menuId);
    //         } else {
    //             this.displayTag.push(menuId);
    //         }

    //         this.selectedDisplayTagValues = this.displayTag.map(String);
    //     }
    //     else {
    //         if (this.selectedDisplayTagValues.includes(value)) {
    //             this.selectedDisplayTagValues = this.selectedDisplayTagValues.filter(val => val !== value);
    //         } else {
    //             this.selectedDisplayTagValues.push(value);
    //         }
    //     }
    //     console.log(this.selectedDisplayTagValues, "Diet values");
    // }
    onCheckboxChangeDisplayTag(value: string) {
        if (this.itemDetail?.display_tag != undefined) {
            const menuId = String(value);
    
            // if (isNaN(menuId)) {
            //     return;
            // }
    
            if (this.isDisplayTagSelected(menuId)) {
                this.displayTag = this.displayTag.filter((id) => id !== menuId);
            } else {
                this.displayTag.push(menuId);
            }
    
            this.selectedDisplayTagValues = this.displayTag.map(String);
        }
        else {
            if (this.selectedDisplayTagValues.includes(value)) {
                this.selectedDisplayTagValues = this.selectedDisplayTagValues.filter(val => val !== value);
            } else {
                this.selectedDisplayTagValues.push(value);
            }
        }
        console.log(this.selectedDisplayTagValues, "Display Tag values");
    }
    


    closeModal() {
        this.activeModal.close();
    }

    saveItem() {

 // Check if any changes were made to chefMenu
 let chefMenuArray: string[];
 if (this.itemDetail?.menu_list != undefined) {
     chefMenuArray = this.chefMenu;
 } else {
     chefMenuArray = this.selectedValues;
 }

 // Check if any changes were made to chefMeal
 let chefMealArray: string[];
 if (this.itemDetail?.meal_type != undefined) {
     chefMealArray = this.chefMeal;
 } else {
     chefMealArray = this.selectedMealValues;
 }

 // Check if any changes were made to chefDiet
 let chefDietArray: string[];
 if (this.itemDetail?.diet_type != undefined) {
     chefDietArray = this.chefDiet;
 } else {
     chefDietArray = this.selectedDietValues;
 }
// Check if any changes were made to chefTag
let chefTagArray: string[];
if (this.itemDetail?.master_tagging != undefined) {
    chefTagArray = this.chefTag;
} else {
    chefTagArray = this.selectedTagValues;
}
let displayTagArray: string[];
if (this.itemDetail?.display_tag != undefined) {
    displayTagArray = this.displayTag;
} else {
    displayTagArray = this.selectedDisplayTagValues;
}

        if (this.name == '') {
            this.toaster.error('please enter name');
            return;
        }

        if (this.category == '') {
            this.toaster.error('please select category');
            return;
        }

        if (this.food_type == '') {
            this.toaster.error('please enter food type');
            return;
        }

        if (this.base_price == 0) {
            this.toaster.error('please enter base price');
            return;
        }

        if (this.qty == 0) {
            this.toaster.error('please enter quantity');
            return;
        }

        if (this.qty_type == '') {
            this.toaster.error('please select quantity type');
            return;
        }
        if (this.person_service == 0) {
            this.toaster.error('Please Add Serving Persons');
            return;
        }
        if (this.chefMenu == '') {
            this.toaster.error('Please Add Menu');
            return;
        }
        if (this.chefMeal == '') {
            this.toaster.error('Please Add Meal');
            return;
        }
        if (this.food_arrange == '') {
            this.toaster.error('Please Add Food Arrange');
            return;
        }
        if (this.food_avaibility == '') {
            this.toaster.error('Please Add Food Availability');
            return;
        }
       
        // if (this.chefDiet == '') {
        //     this.toaster.error('Please Add Diet');
        //     return;
        // }
        if (this.cuisine == '') {
            this.toaster.error('please select cuisine');
            return;
        }

        if (this.short_desc == '') {
            this.toaster.error('please enter short description');
            return;
        }

        if (this.long_desc == '') {
            this.toaster.error('please enter long description');
            return;
        }

        let special = 0;
        if (this.special_item) {
            special = 1;
        }
        // this.chefMenu = this.chefMenu.filter((id, index, self) => id && self.indexOf(id) === index);

        //     let chefMenuString = this.chefMenu.join(',');
        // const selectedValuesString = this.selectedValues.join(',');

        //     this.chefMenu = this.convertStringToArray(selectedValuesString);
        let postData = {
            name: this.name,
            category: this.category,
            images: JSON.stringify(this.uploadedFileList),
            food_type: this.food_type,
            food_avaibility: this.food_avaibility,
            food_arrange:this.food_arrange,
            base_price: this.base_price,
            short_desc: this.short_desc,
            long_desc: this.long_desc,
            disclaimer: this.disclaimer,
            qty: this.qty,
            qty_type: this.qty_type,
            person_service: this.person_service,
            prep_time: this.prep_time,
            food_category: this.food_category,
            chefMenu: chefMenuArray.join(','),
        chefMeal: chefMealArray.join(','),
        chefDiet: chefDietArray.join(','),
        chefTag: chefTagArray.join(','),
        displayTag: displayTagArray.join(','),
            cuisine: this.cuisine,
            chef_id: this.chef_id,
            is_special: special,
            calories: this.calories,
            spicy_level: this.spicy_level,
        }

        if (this.itemDetail != null && this.itemDetail != undefined) {
            postData['id'] = this.itemDetail.id;
        }

        

       if( (this.utilService.getUserType() == '2')){
        let post = {
            chef_id: this.chef_id,
            type: 3,
            status:1,
            admin_msg:"approved",
            data: {
                food_item: postData
            },
            chef_msg: '',
        }

        console.log('data:-' + JSON.stringify(post, null, 4));
        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/submitUpdatecheck', post).then((result) => {
            if (result.status) {
                this.toaster.success('food item updated by admin')
                this.chefFoodItemUpdateStatus();
                this.activeModal.close();
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
       
       
       
    
       }
       else {
        let post = {
            chef_id: this.chef_id,
            type: 3,
            data: {
                food_item: postData
            },
            chef_msg: '',
        }

        console.log('data:-' + JSON.stringify(post, null, 4));
        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/submitUpdate', post).then((result) => {
            if (result.status) {
                this.toaster.success('food item submitted for approval')
                this.chefFoodItemUpdateStatus();
                this.activeModal.close();
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
       }
        

        // this.apiService.postAPI(this.apiService.BASE_URL + 'items/addItem', postData).then((result) => {
        //     console.log('result:-' + JSON.stringify(result));
        //     if (result.status) {
        //         this.activeModal.close();
        //     } else {
        //         this.toaster.error(result.message)
        //     }
        // }, (error) => {
        //     console.log('something went wrong');
        //     this.toaster.error('something went wrong');
        // })

    }

    onFileChange(event: any) {
        // this.fileEvents.push({
        //     event: event
        // });
        this.fileEvent = event;

        if (this.fileEvent) {
            let headerJson = {};
            const target: DataTransfer = <DataTransfer>(this.fileEvent.target);
            const reader: FileReader = new FileReader();
            reader.readAsBinaryString(target.files[0]);
            reader.onload = (e: any) => {
                let name = target.files[0].name;
                const formData: FormData = new FormData();
                formData.append('image', target.files[0], name);
                return this.apiService.postAPI(this.apiService.BASE_URL + 'items/UploadBase64Image', formData).then((result) => {
                    console.log('result:-' + JSON.stringify(result));
                    if (result.status) {
                        this.uploadedFileList.push(result.result);
                    }
                    console.log(this.uploadedFileList);
                }, (error) => {
                    console.log('error:-' + JSON.stringify(error));
                });

                event.target.value = null;

            };
        }

        // this.checkImage();
    }

    // checkImage() {
    //     for (let i = 0; i < this.fileEvents.length; i++) {
    //         const target: DataTransfer = <DataTransfer>(this.fileEvents[i].event.target);
    //         const reader: FileReader = new FileReader();
    //         reader.readAsDataURL(target.files[0]);
    //
    //         reader.onload = (e: any) => {
    //             let name = target.files[0].name;
    //             this.fileEvents[i]['src'] = e.target.result;
    //         };
    //
    //         // this.fileEvents[i]['src']=window.URL.createObjectURL(this.fileEvents[i].event.target.files[0]);
    //         // this.fileEvents[i]['src'] = URL.createObjectURL(this.fileEvents[i].event.target.files[0])
    //
    //     }
    //
    //     console.log("fileevent:-" + JSON.stringify(this.fileEvents, null, 4));
    // }
    removeImage(i: number) {
        this.uploadedFileList.splice(i, 1);
    }

    public chefFoodItemUpdateStatus() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefstatus/getChefFoodItemUpdate/' + this.chef_id + '/3').then((result) => {
            if (result.status) {
                // 0=pending,1=approved,2=declined
                // if (result.result.status == 0) {
                //     this.submitProspect = false;
                // } else {
                //     this.submitProspect = true;
                // }
                // if (result.result.status == 2 || result.result.status == 0) {
                //     this.prosprectDetail = result.result;
                //     this.prosprectDetailData = JSON.parse(this.prosprectDetail.data).prospect;
                //     let cuisineNames = '';
                //     for (let i = 0; i < this.prosprectDetailData.cuisines.length; i++) {
                //         if (i == 0) {
                //             cuisineNames = this.prosprectDetailData.cuisines[i].name;
                //         } else {
                //             cuisineNames += ' , ' + this.prosprectDetailData.cuisines[i].name;
                //         }
                //     }
                //     this.chefPropectCuisines = cuisineNames;
                // }

            } else {
                // this.prosprectDetail = null;
                // this.prosprectDetailData = null;
                // this.submitProspect = true;
            }
        }, (error) => {
            // this.prosprectDetail = null;
            // this.prosprectDetailData = null;
            // this.submitProspect = true;
            console.log('error:-' + JSON.stringify(error));
        })
    }

    openImageCropper() {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.showLoader();
                let postData = {
                    image: result.image
                }

                this.apiService.postAPI(this.apiService.BASE_URL + 'items/UploadBase64Image', postData).then((result) => {
                    if (result.status) {
                        this.uploadedFileList.push(result.result);
                        this.hideLoader();
                    }
                }, (error) => {
                    console.log('error:-' + error.message);
                    this.toaster.error('Something went wrong');
                    this.hideLoader();
                })
            }
        });

        modal.componentInstance.ratio = 1 / 1;
        modal.componentInstance.width = 300;
    }
}
