import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AddUpdateCuisineComponent} from '../../modals/add-update-cuisine/add-update-cuisine.component';
import {UtilService} from '../../service/util.service';
import { ConfirmPopupComponent } from 'app/modals/confirm-popup/confirm-popup.component';

@Component({
    selector: 'app-cusisine',
    templateUrl: './cusisine.component.html',
    styleUrls: ['./cusisine.component.css']
})
export class CusisineComponent implements OnInit {

    cuisineList = [];

    constructor(
        private apiService: ApiService, private modalService: NgbModal, private router: Router,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.getAllCuisine();
    }

    getAllCuisine() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefCuisine').then((result) => {
            if (result.status) {
                this.cuisineList = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    addCuisine() {
        const modalRef = this.modalService.open(AddUpdateCuisineComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllCuisine()
        })
    }

    editCuisine(item) {
        const modalRef = this.modalService.open(AddUpdateCuisineComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllCuisine()
        })
        modalRef.componentInstance.cuisineDetail = item;
    }
    openDeleteCuisinePopup(item:any) {
        
        const modalRef = this.modalService.open(ConfirmPopupComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllCuisine()
        })
        modalRef.componentInstance.cuisineId = item.id;
        modalRef.componentInstance.DeleteText = `Do you really want to Delete ${item.name} Cuisine ?`;
        modalRef.componentInstance.BtnText = "OK";
        modalRef.componentInstance.title = "Cuisine";
        // modalRef.componentInstance.BtnFunction = this.deleteCuisine(item);
    }

    // deleteCuisine(item:any){
    //     console.log(item,"dsarfsd")
    //     let postData = {
    //         cuisineId : item
    //     } 
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/deleteAllAdminCuisine',postData).then((result) => {
    //         if (result.status) {
    //             this.getAllCuisine();
    //         }
    //     }, (error) => {
    //         console.log('error:-' + JSON.stringify(error));
    //     })
    // }
}
