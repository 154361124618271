import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {CropImageComponent} from '../crop-image/crop-image.component';
import {ToastrService} from 'ngx-toastr';
import {Address} from 'ngx-google-places-autocomplete/objects/address';

@Component({
    selector: 'app-add-update-event',
    templateUrl: './add-update-event.component.html',
    styleUrls: ['./add-update-event.component.css']
})
export class AddUpdateEventComponent implements OnInit {

    @Input() eventId;

    formatted_address = '';

    event = {
        name: '',
        description: '',
        price: '',
        delivery_charges: '',
        gst: '',
        delivery_gst: '',
        delivery_km: '',
        chefs: [],
        menu: [],
        selectedLocation: null,
        status: 1,
        thankyoumsg: '',
        // logo: '',
        logo: 'assets/img/default-avatar.png',
        // banner: ''
        banner: 'assets/img/default_banner.png'
    }

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster: ToastrService,
    ) {
    }

    ngOnInit(): void {
        console.log('eventId:-' + this.eventId);
        if (this.eventId != undefined) {
            this.getEventDetail();
        }
    }

    getEventDetail() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'events/getEventDetails/' + this.eventId).then((result) => {
            if (result.status) {
                this.event = {
                    name: result.result.name,
                    description: result.result.description,
                    price: result.result.price,
                    delivery_charges: result.result.delivery_charge,
                    gst: result.result.gst,
                    delivery_gst: result.result.delivey_gst,
                    delivery_km: result.result.delivery_per_km,
                    chefs: JSON.parse(result.result.chefs),
                    menu: JSON.parse(result.result.menu),
                    selectedLocation: {
                        place_id: result.result.place_id,
                        formatted_address: result.result.formatted_address,
                        location: {
                            lat: result.result.lat,
                            lng: result.result.lng
                        }
                    },
                    status: result.result.status,
                    thankyoumsg: result.result.thankyoumsg,
                    logo: result.result.logo,
                    banner: result.result.banner
                }
                if (this.event.logo == null || this.event.logo == undefined || this.event.logo == '') {
                    this.event.logo = 'assets/img/default-avatar.png'
                }
                if (this.event.banner == null || this.event.banner == undefined || this.event.banner == '') {
                    this.event.banner = 'assets/img/default_banner.png'
                }
                this.formatted_address = this.event.selectedLocation.formatted_address;
            } else {
                this.toaster.error('Event not found');
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })
    }

    closeModal() {
        this.activeModal.close({
            status: false
        })
    }

    addChef() {
        this.event.chefs.push({
            name: '',
            description: '',
            profile_pic: 'assets/img/default-avatar.png'
        })
    }

    openImageCropper(index) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage('other', result.image, index);
            }
        });
        modal.componentInstance.ratio = 1 / 1;
        modal.componentInstance.width = 300;
    }

    openLogoCropper(type) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage(type, result.image, 0);
            }
        });
        if (type == 'logo') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 300;
        } else if (type == 'banner') {
            modal.componentInstance.ratio = 1.5 / 1;
            modal.componentInstance.width = 1440;
        }

    }

    uploadImage(type, file, index) {
        let postData = {
            image: file
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'items/UploadBase64Image', postData).then((result) => {
            if (result.status) {
                if (type == 'logo') {
                    // this.event.logo = this.apiService.BASE_IMAGE_URL + result.result;
                    this.event.logo = result.result;
                } else if (type == 'banner') {
                    // this.event.banner = this.apiService.BASE_IMAGE_URL + result.result;
                    this.event.banner = result.result;
                } else {
                    // this.event.chefs[index].profile_pic = this.apiService.BASE_IMAGE_URL + result.result;
                    this.event.chefs[index].profile_pic = result.result;
                }
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })
    }

    addEvent() {
        console.log('Event Detail:-' + JSON.stringify(this.event, null, 4));
        if (this.event.name == '') {
            this.toaster.error('Please enter name');
            return;
        }
        if (this.event.description == '') {
            this.toaster.error('Please enter description');
            return;
        }
        if (this.event.price == '') {
            this.toaster.error('Please enter price');
            return;
        }
        if (this.event.delivery_charges == '') {
            this.toaster.error('Please enter delivery charges');
            return;
        }
        if (this.event.gst == '') {
            this.toaster.error('Please enter gst');
            return;
        }
        if (this.event.delivery_gst == '') {
            this.toaster.error('Please enter delivery gst');
            return;
        }
        if (this.event.delivery_km == '') {
            this.toaster.error('Please enter delivery per km');
            return;
        }
        if (this.event.chefs.length == 0) {
            this.toaster.error('Please add chef');
            return;
        }
        for (let i = 0; i < this.event.chefs.length; i++) {
            if (this.event.chefs[i].name == '') {
                this.toaster.error('Please enter chef name at position ' + (i + 1));
                return;
            }
        }
        if (this.event.menu.length == 0) {
            this.toaster.error('Please add menu');
            return;
        }
        for (let i = 0; i < this.event.menu.length; i++) {
            // if (this.event.menu[i].menu_title == '') {
            //     this.toaster.error('Please enter menu title at position ' + (i + 1));
            //     return;
            // }
            if (this.event.menu[i].food_items.length == 0) {
                this.toaster.error('Please add menu food items');
                return;
            }

            for (let j = 0; j < this.event.menu[i].food_items.length; j++) {
                if (this.event.menu[i].food_items[j].name == '') {
                    this.toaster.error('Please enter menu at position ' + (i + 1) + ' item name at position ' + (j + 1));
                    return;
                }
            }
        }
        if (this.event.selectedLocation == null) {
            this.toaster.error('Please add location');
            return;
        }
        if (this.event.thankyoumsg == null) {
            this.toaster.error('Please enter thank you message');
            return;
        }
        if (this.event.logo == null || this.event.logo == undefined || this.event.logo == '' || this.event.logo == 'assets/img/default-avatar.png') {
            this.toaster.error('please select logo');
            return;
        }
        if (this.event.banner == null || this.event.banner == undefined || this.event.banner == '' || this.event.banner == 'assets/img/default_banner.png') {
            this.toaster.error('please select banner');
            return;
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'events/createEvent', this.event).then((result) => {
            console.log('result:-' + JSON.stringify(result));
            if (result.status) {
                this.activeModal.close({
                    status: true
                });
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })


    }

    addMenu() {
        this.event.menu.push({
            menu_title: '',
            food_items: []
        })
    }

    addFoodItem(i: number) {
        this.event.menu[i].food_items.push({
            name: '',
            instruction: ''
        })
    }

    removeFoodItem(i: number, j: number) {
        this.event.menu[i].food_items.splice(j, 1);
    }

    updateEvent() {
        console.log('Event Detail:-' + JSON.stringify(this.event, null, 4));
        if (this.event.name == '') {
            this.toaster.error('Please enter name');
            return;
        }
        if (this.event.description == '') {
            this.toaster.error('Please enter description');
            return;
        }
        if (this.event.price == '') {
            this.toaster.error('Please enter price');
            return;
        }
        if (this.event.delivery_charges == '') {
            this.toaster.error('Please enter delivery charges');
            return;
        }
        if (this.event.gst == '') {
            this.toaster.error('Please enter gst');
            return;
        }
        if (this.event.delivery_gst == '') {
            this.toaster.error('Please enter delivery gst');
            return;
        }
        if (this.event.delivery_km == '') {
            this.toaster.error('Please enter delivery per km');
            return;
        }
        if (this.event.chefs.length == 0) {
            this.toaster.error('Please add chef');
            return;
        }
        for (let i = 0; i < this.event.chefs.length; i++) {
            if (this.event.chefs[i].name == '') {
                this.toaster.error('Please enter chef name at position ' + (i + 1));
                return;
            }
        }
        if (this.event.menu.length == 0) {
            this.toaster.error('Please add menu');
            return;
        }
        for (let i = 0; i < this.event.menu.length; i++) {
            // if (this.event.menu[i].menu_title == '') {
            //     this.toaster.error('Please enter menu title at position ' + (i + 1));
            //     return;
            // }
        //    if(this.event.menu[i].menu_title = ''){
        //     this.event.menu[i].menu_title.trim()
        //     return console.log(this.event.menu[i].menu_title,"dfsd");
        //    }
            if (this.event.menu[i].food_items.length == 0) {
                this.toaster.error('Please add menu food items');
                return;
            }

            for (let j = 0; j < this.event.menu[i].food_items.length; j++) {
                if (this.event.menu[i].food_items[j].name == '') {
                    this.toaster.error('Please enter menu at position ' + (i + 1) + ' item name at position ' + (j + 1));
                    return;
                }
            }
        }
        if (this.event.selectedLocation == null) {
            this.toaster.error('Please add location');
            return;
        }
        if (this.event.thankyoumsg == null) {
            this.toaster.error('Please enter thank you message');
            return;
        }
        if (this.event.logo == null || this.event.logo == undefined || this.event.logo == '' || this.event.logo == 'assets/img/default-avatar.png') {
            this.toaster.error('please select logo');
            return;
        }
        if (this.event.banner == null || this.event.banner == undefined || this.event.banner == '' || this.event.banner == 'assets/img/default_banner.png') {
            this.toaster.error('please select banner');
            return;
        }

        this.event['id'] = this.eventId;

        this.apiService.postAPI(this.apiService.BASE_URL + 'events/updateEvent', this.event).then((result) => {
            console.log('result:-' + JSON.stringify(result));
            if (result.status) {
                this.activeModal.close({
                    status: true
                });
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong');
        })

    }

    public handleAddressChange(address: Address) {
        console.log('address:-' + JSON.stringify(address));

        this.event.selectedLocation = {
            place_id: address.place_id,
            formatted_address: address.formatted_address,
            location: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            }
        }
    }

    removeChef(i: number) {
        this.event.chefs.splice(i, 1);
    }

    removeCourse(i: number) {
        this.event.menu.splice(i, 1);
    }
}
