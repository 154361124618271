import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {UtilService} from '../../service/util.service';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {ToastrService} from 'ngx-toastr';

declare var google;

@Component({
    selector: 'app-add-update-geofence',
    templateUrl: './add-update-geofence.component.html',
    styleUrls: ['./add-update-geofence.component.css']
})
export class AddUpdateGeofenceComponent implements OnInit {

    @ViewChild('gmap', {static: false}) gmapElement: ElementRef;
    map: any;
    geofence;
    currentLat = 28.5272803;
    currentLng = 77.0688997;

    profile_location = '';
    selectedLocation = null;
    radius = 5;
    name = '';

    @Input() geofenceDetail;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        public utilService: UtilService,
        private toaster: ToastrService
    ) {

    }

    ngOnInit(): void {
        console.log('this.geofenceDetail:-' + JSON.stringify(this.geofenceDetail));
        if (this.geofenceDetail != null) {
            this.name = this.geofenceDetail.name;
            this.currentLat = JSON.parse(this.geofenceDetail.location).center.lat
            this.currentLng = JSON.parse(this.geofenceDetail.location).center.lng
            this.radius = JSON.parse(this.geofenceDetail.location).radius
        } else {
            this.getLocation();
        }
    }

    ngAfterViewInit() {
        const mapOptions = {
            zoom: 12,
            center: new google.maps.LatLng(this.currentLat, this.currentLng)
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
        this.map.addListener('click', (mapsMouseEvent) => {
            let latlng = mapsMouseEvent.latLng;
            this.currentLat = latlng.lat()
            this.currentLng = latlng.lng()
            console.log(latlng.lat() + ',' + latlng.lng());
            this.showMarkerOnMap(latlng.lat(), latlng.lng(), true)
        });
        if (
            this.currentLat != null && this.currentLat != undefined
            && this.currentLng != null && this.currentLng != undefined
        ) {
            this.handleRadius();
            this.showMarkerOnMap(this.currentLat, this.currentLng, true);
        }
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                    if (position) {
                        console.log('Latitude: ' + position.coords.latitude +
                            'Longitude: ' + position.coords.longitude);
                        // this.searchTeacher();
                        this.currentLat = position.coords.latitude;
                        this.currentLng = position.coords.longitude;
                        if (this.map != null) {
                            this.showMarkerOnMap(this.currentLat, this.currentLng, true);
                        }
                    }
                },
                (error) => {
                    console.log(error);
                });
        }
    }

    showMarkerOnMap(lat, lng, searchAddress) {
        if (this.geofence != null) {
            this.geofence.setMap(null);
        }
        const myLatLng = {lat: lat, lng: lng}
        this.map.setCenter(myLatLng);
        const geofence = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            center: myLatLng,
            radius: this.radius * 1000,
        });

        geofence.setMap(this.map)

        this.geofence = geofence;
    }

    public handleAddressChange(address: Address) {
        console.log('address:-' + JSON.stringify(address));

        this.selectedLocation = {
            place_id: address.place_id,
            formatted_address: address.formatted_address,
            location: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            }
        }
        this.currentLat = address.geometry.location.lat()
        this.currentLng = address.geometry.location.lng()

        this.showMarkerOnMap(address.geometry.location.lat(), address.geometry.location.lng(), false)
    }

    saveLocation() {
        if (
            this.name == null || this.name == undefined || this.name == ''
        ) {
            this.toaster.error('please enter name');
            return;
        }
        if (
            this.radius == null || this.radius == undefined || this.radius == 0
        ) {
            this.toaster.error('please enter radius');
            return;
        }
        if (
            this.currentLat == null || this.currentLat == undefined
            || this.currentLng == null || this.currentLng == undefined
        ) {
            this.toaster.error('please select geofence');
            return;
        }
        if (
            this.geofence.getMap()==null||this.geofence.getMap()==undefined
        ) {
            this.toaster.error('please select geofence');
            return;
        }

        this.activeModal.close({
            center: {
                lat: this.currentLat,
                lng: this.currentLng
            },
            radius: this.radius,
            name: this.name
        })
    }

    closeModal() {
        this.activeModal.close();
    }

    onRadiusSelected() {
        setTimeout(()=>{
            this.radiusSelection();
        },300);
    }

    radiusSelection(){
        this.handleRadius()
        if (
            this.currentLat != null && this.currentLat != undefined
            && this.currentLng != null && this.currentLng != undefined
            && this.radius != undefined && this.radius != 0
        ) {
            this.showMarkerOnMap(this.currentLat, this.currentLng, false)
        }
    }

    handleRadius(){
        if(this.map!=null){
            if(this.radius==0&&this.geofence!=null&&this.geofence.getMap()!=null){
                this.geofence.setMap(null);
                return;
            }
            if(this.radius<=10){
                this.map.setZoom(11);
            }else if(this.radius>10&&this.radius<=20){
                this.map.setZoom(10);
            }else if(this.radius>20&&this.radius<=30){
                this.map.setZoom(9);
            }else if(this.radius>30){
                this.map.setZoom(8);
            }
        }
    }

    clearMap() {
        if(this.geofence!=null&&this.geofence.getMap()!=null){
            this.geofence.setMap(null);
        }
    }
}
