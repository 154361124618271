import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';

@Component({
    selector: 'app-chef-category',
    templateUrl: './chef-category.component.html',
    styleUrls: ['./chef-category.component.css']
})
export class ChefCategoryComponent implements OnInit {

    name = '';
    enable = false;
    @Input() chefCategory = null;
    @Input() chefId;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService
    ) {
    }

    close() {
        this.activeModal.close();
    }

    ngOnInit(): void {
        if (this.chefCategory != null) {
            this.name = this.chefCategory.name;
            if (this.chefCategory.status == '1') {
                this.enable = true;
            } else {
                this.enable = false;
            }
        }
    }

    saveChefCategory() {
        if (this.chefCategory != null) {
            let status = '0';
            if (this.enable) {
                status = '1';
            }

            this.apiService.postAPI(this.apiService.BASE_URL + 'category/updateCategory', {
                name: this.name,
                chef_id: this.chefId,
                id: this.chefCategory.id,
                status: status
            }).then((result) => {
                if (result.status) {
                    this.activeModal.close();
                } else {
                    alert(result.message);
                }

            }, (error) => {
                alert('something went wrong');
            })
        } else {
            this.apiService.postAPI(this.apiService.BASE_URL + 'category/addCategory', {
                name: this.name,
                chef_id: this.chefId
            }).then((result) => {
                if (result.status) {
                    this.activeModal.close();
                } else {
                    alert(result.message);
                }
            }, (error) => {
                alert('something went wrong');
            })
        }
    }

}
