import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {CropImageComponent} from '../../modals/crop-image/crop-image.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-our-story-cms',
    templateUrl: './our-story-cms.component.html',
    styleUrls: ['./our-story-cms.component.css']
})
export class OurStoryCMSComponent implements OnInit {

    DEFAULT_BANNER = 'assets/img/default_banner.png';
    allCuisines = [];
    isLoading: boolean = false; // Variable to track loader state
    CMS14 = {
        S10: {
            heading: '',
            cuisines: []
        },
    }
    cms = {
        S1_BANNER: this.DEFAULT_BANNER,
        S2: {
            title: '',
            descriptions: [],
            image: ''
        },
        S3: {
            heading: '',
            C1: '',
            C2: '',
            C3: '',
            C4: ''
        },
        S10: {
            heading: '',
            cuisines: []
        },
        S4_BANNER: '',
        S5: {
            C1_heading: '',
            C1_title: '',
            C1_description: '',
            C2_heading: '',
            C2_title: '',
            C2_description: '',
        },
        S6: {
            s6_1_title: '',
            S6_1_IMAGE: '',
            s6_2_title: '',
            S6_2_IMAGE: '',
        },
        S7: {
            data1: '',
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            label1: '',
            label2: '',
            label3: '',
            label4: '',
            label5: '',
        },
        S8: {
            heading: '',
            S8_1_title: '',
            S8_1_IMAGE: '',
            S8_2_title: '',
            S8_2_IMAGE: '',
            S8_3_title: '',
            S8_3_IMAGE: '',
        },
        S14: {
            heading: '',
            cuisines: []
        },
        S9_BANNER: ''
    }


    constructor(
        public apiService: ApiService,
        private toaster: ToastrService,
        private modalService: NgbModal,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.getCMS()
        this.getAllCuisines();
    }
    showLoader() {
        this.isLoading = true;
        console.log("loader")
      }
    
      // Function to hide loader
      hideLoader() {
        this.isLoading = false;
      }
    getCMS() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/getAllCMS', {
            names: ['OURSTORY']
        }).then((result) => {
            if (result.status) {
                if (result.result[0].content != null && result.result[0].content != undefined && result.result[0].content != '') {
                    this.cms = JSON.parse(result.result[0].content);
                    // this.cms.S10 = this.CMS14.S10;
                }
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
    addCuisinePop() {
        this.cms.S10.cuisines.push({
            id: 0
        })
        console.log(this.cms.S10.cuisines,"436")
    }
    removeCuisinesPop(index) {
        this.cms.S10.cuisines.splice(index, 1);
    }
    getAllCuisines() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefCuisine').then((result) => {
            if (result.status) {
                this.allCuisines = result.result;
                console.log(result.result,"occassion");
            }
        })
    }
    saveCMS() {
        // console.log("cms:-"+JSON.stringify(this.cms,null,4));
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/addCMS', {
            name: 'OURSTORY',
            content: JSON.stringify(this.cms)
        }).then((result) => {
            if (result.status) {
                this.toaster.success('Data saved');
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    // onselectimage(e, type) {
    //     if (e.target.files) {
    //         const target: DataTransfer = <DataTransfer>(e.target);
    //         const reader: FileReader = new FileReader();
    //         let file = target.files[0];
    //         if (
    //             type == 'S1_BANNER' || type == 'S2_IMAGE' || type == 'S4_BANNER' || type == 'S6_2_IMAGE' || type == 'S6_1_IMAGE' || type == 'S8_1_IMAGE' || type == 'S8_2_IMAGE' || type == 'S8_3_IMAGE' || type == 'S9_BANNER'
    //         ) {
    //             reader.readAsDataURL(target.files[0]);
    //             reader.onload = (e: any) => {
    //                 let name = file.name;
    //                 this.uploadImage(type, file)
    //             }
    //             e.target.value = null;
    //         } else if (type == 'S5_VIDEO') {
    //             reader.readAsDataURL(target.files[0]);
    //             reader.onload = (e: any) => {
    //                 let name = file.name;
    //                 if (
    //                     name.toLowerCase().includes('.mp4')
    //                 ) {
    //                     this.uploadImage(type, file)
    //                 } else {
    //                     alert('Please upload png image file');
    //                 }
    //             }
    //             e.target.value = null;
    //         }
    //     }
    // }

    // uploadImage(type, file) {
    //     let formData = new FormData();
    //     formData.append('image', file);
    //
    //     this.apiService.postAPI(this.apiService.BASE_URL + 'common/ourStoryImages', formData).then((result) => {
    //         if (result.status) {
    //             this.toaster.success('File uploaded');
    //             if (type == 'S1_BANNER') {
    //                 this.cms.S1_BANNER = result.result;
    //                 this.saveCMS()
    //             } else if (type == 'S2_IMAGE') {
    //                 this.cms.S2.image = result.result;
    //             } else if (type == 'S4_BANNER') {
    //                 this.cms.S4_BANNER = result.result;
    //                 this.saveCMS();
    //             } else if (type == 'S6_1_IMAGE') {
    //                 this.cms.S6.S6_1_IMAGE = result.result;
    //             } else if (type == 'S6_2_IMAGE') {
    //                 this.cms.S6.S6_2_IMAGE = result.result;
    //             } else if (type == 'S8_1_IMAGE') {
    //                 this.cms.S8.S8_1_IMAGE = result.result;
    //             } else if (type == 'S8_2_IMAGE') {
    //                 this.cms.S8.S8_2_IMAGE = result.result;
    //             } else if (type == 'S8_3_IMAGE') {
    //                 this.cms.S8.S8_3_IMAGE = result.result;
    //             } else if (type == 'S9_BANNER') {
    //                 this.cms.S9_BANNER = result.result;
    //                 this.saveCMS()
    //             }
    //         } else {
    //             this.toaster.error(result.message);
    //         }
    //     }, (error) => {
    //         console.log('error:-' + JSON.stringify(error));
    //     })
    // }


    addS2Description() {
        // if(this.cms.hasOwnProperty('S2'))
        if (this.cms.S2.descriptions == null || this.cms.S2.descriptions == undefined) {
            this.cms.S2.descriptions = [];
        }
        this.cms.S2.descriptions.push({
            data: ''
        });
    }

    deleteS2Description(i: any) {
        this.cms.S2.descriptions.splice(i, 1);
    }


    openImageCropper(type, index) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage(type, result.image, index)
            }
        });

        if (type == 'S1_BANNER') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'S2_IMAGE') {
            modal.componentInstance.ratio = 2 / 1;
            modal.componentInstance.width = 773;
        } else if (type == 'S4_BANNER') {
            modal.componentInstance.ratio = 10 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'S6_1_IMAGE') {
            modal.componentInstance.ratio = 2.2 / 1;
            modal.componentInstance.width = 773;
        } else if (type == 'S6_2_IMAGE') {
            modal.componentInstance.ratio = 2.2 / 1;
            modal.componentInstance.width = 773;
        } else if (type == 'S8_1_IMAGE') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 534;
        } else if (type == 'S8_2_IMAGE') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 534;
        } else if (type == 'S8_3_IMAGE') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 534;
        } else if (type == 'S9_BANNER') {
            modal.componentInstance.ratio = 10 / 1;
            modal.componentInstance.width = 1920;
        }

    }

    uploadImage(type, file, index) {
        this.showLoader();
        let postData = {
            image: file,
        }

        // if (type == 'S1_IMAGE1') {
        //     postData['image_name'] = 's1b1.png';
        // } else if (type == 'S2_IMAGE') {
        //     postData['image_name'] = 's2.png';
        // }

        this.apiService.postAPI(this.apiService.BASE_URL + 'common/becomeAChefCMSBase64', postData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                if (type == 'S1_BANNER') {
                    this.cms.S1_BANNER = result.result;
                    this.saveCMS()
                } else if (type == 'S2_IMAGE') {
                    this.cms.S2.image = result.result;
                    this.saveCMS()
                } else if (type == 'S4_BANNER') {
                    this.cms.S4_BANNER = result.result;
                    this.saveCMS()
                } else if (type == 'S6_1_IMAGE') {
                    this.cms.S6.S6_1_IMAGE = result.result;
                } else if (type == 'S6_2_IMAGE') {
                    this.cms.S6.S6_2_IMAGE = result.result;
                } else if (type == 'S8_1_IMAGE') {
                    this.cms.S8.S8_1_IMAGE = result.result;
                } else if (type == 'S8_2_IMAGE') {
                    this.cms.S8.S8_2_IMAGE = result.result;
                } else if (type == 'S8_3_IMAGE') {
                    this.cms.S8.S8_3_IMAGE = result.result;
                } else if (type == 'S9_BANNER') {
                    this.cms.S9_BANNER = result.result;
                    this.saveCMS()
                } else if (type == 'S9_BANNER') {
                    this.cms.S9_BANNER = result.result;
                    this.saveCMS()
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
		this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
}
