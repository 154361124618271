import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { AddCouponPageComponent } from '../../modals/add-coupon-page/add-coupon-page.component';
// import {AddUpdateCuisineComponent} from '../../modals/add-update-cuisine/add-update-cuisine.component';
import {UtilService} from '../../service/util.service';
import { ConfirmPopupComponent } from 'app/modals/confirm-popup/confirm-popup.component';
@Component({
  selector: 'app-coupon-page',
  templateUrl: './coupon-page.component.html',
  styleUrls: ['./coupon-page.component.css']
})
export class CouponPageComponent implements OnInit {
  couponList = [];
  constructor(  private apiService: ApiService, private modalService: NgbModal, private router: Router,
    public utilService: UtilService) { }

  ngOnInit(): void {
    this.getAllCoupon();
  }
  getAllCoupon() {
    this.apiService.getAPI(this.apiService.BASE_URL + 'chefstatus/getAllCouponCode').then((result) => {
        if (result.status) {
            this.couponList = result.result;
        }
    }, (error) => {
        console.log('error:-' + JSON.stringify(error));
    })
}
onCheckboxChange(selectedCoupon: any) {
    const isShow = selectedCoupon.isShow ? 0 : 1; // Toggle the value
    let post = {
        id: selectedCoupon.id,
        isShow: isShow,
    }
    // Call the API to update the isShow property
    // this.updateCouponIsShow(selectedCoupon.id, isShow).subscribe(
    //   response => {
        this.apiService.postAPI(this.apiService.BASE_URL + 'chefStatus/updateCouponIsShow',post).then((result) => {
        if (result.status) {
          // Update the local list to reflect the change
          this.couponList.forEach(coupon => {
            if (coupon.id === selectedCoupon.id) {
              coupon.isShow = isShow;
            } else if (isShow === 1) {
              coupon.isShow = 0; // Reset other coupons if setting isShow to 1
            }
          });
        } else {
          console.error('Failed to update coupon');
        }
      },
      error => {
        console.error('Error updating coupon:', error);
      }
    );
  }
  updateCouponIsShow(id: number, isShow: number) {
    return this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/updateCouponIsShow', { id, isShow });
  }
addCoupon() {
    const modalRef = this.modalService.open(AddCouponPageComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCoupon()
    })
}

editCoupon(item) {
    const modalRef = this.modalService.open(AddCouponPageComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCoupon()
    })
    modalRef.componentInstance.couponDetail = item;
}
openDeleteCuisinePopup(item:any) {
    
    const modalRef = this.modalService.open(ConfirmPopupComponent, {
        backdrop: 'static',
        size: <any>'profiletutor',
        keyboard: false,
        centered: true
    });
    modalRef.closed.subscribe((result) => {
        console.log('dismissed:-' + JSON.stringify(result));
        this.getAllCoupon()
    })
    modalRef.componentInstance.tagId = item.id;
    modalRef.componentInstance.DeleteText = `Do you really want to Delete ${item.heading} tag ?`;
    modalRef.componentInstance.BtnText = "OK";
    modalRef.componentInstance.title = "Chef Tag Master";
    // modalRef.componentInstance.BtnFunction = this.deleteCuisine(item);
}
isExpired(expiryDate: string): boolean {
    const today = new Date();
    const expiry = new Date(expiryDate);
    return expiry < today;
  }
}
