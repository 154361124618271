import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-update-chef-tagging',
  templateUrl: './add-update-chef-tagging.component.html',
  styleUrls: ['./add-update-chef-tagging.component.css']
})
export class AddUpdateChefTaggingComponent implements OnInit {

  chefTag = [];
  selectedTag = '';
  @Input() chef_id='';

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      public utilService: UtilService,
      private apiService: ApiService,
      private modalService: NgbModal,
      private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
      this.getAllChefTag()
  }

  getAllChefTag() {
      this.apiService.getAPI(this.apiService.BASE_URL + 'cuisine/getAllAdminChefTag').then((result) => {
          if (result.status) {
              this.chefTag = result.result;
              console.log(result.result,"result");
              
          }
      }, (error) => {
          console.log('error:-' + JSON.stringify(error));
      })
      console.log(this.chefTag,"chef tag");
      
  }

  closeModal() {
      this.activeModal.close()
  }

  saveTag() {
      this.apiService.postAPI(this.apiService.BASE_URL + 'chefcuisine/addPerChefTagging', {
          chef_id: this.chef_id,
          chef_tag_id: this.selectedTag
      }).then((result) => {
          if (result.status) {
              this.closeModal()
          } else {
              alert(result.message);
          }
      }, (error) => {
          console.log('error:-' + JSON.stringify(error));
      })
  }
}
