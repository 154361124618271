import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {AddUpdateUserComponent} from '../../modals/add-update-user/add-update-user.component';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-roles-users',
    templateUrl: './roles-users.component.html',
    styleUrls: ['./roles-users.component.css']
})
export class RolesUsersComponent implements OnInit {

    userRole;
    users = [];

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService,
        private route: ActivatedRoute,
        private toaster: ToastrService
    ) {
        this.userRole = this.route.snapshot.paramMap.get('role');
        console.log('user role:-' + this.userRole);
    }

    ngOnInit(): void {
        this.getAllUser()
    }

    getAllUser() {
        this.users = [];
        let postData = {
            'type': this.userRole
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'userRole/getAllUserByRole', postData).then((result) => {
            if (result.status) {
                this.users = result.result;
            } else {
                // this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    editUser(item: any) {
        const modalRef = this.modalService.open(AddUpdateUserComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.userType = this.userRole;
        modalRef.componentInstance.userDetail = item;
        modalRef.closed.subscribe((result) => {
            this.getAllUser()
        })
    }

    addUser() {
        const modalRef = this.modalService.open(AddUpdateUserComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.userType = this.userRole;
        modalRef.closed.subscribe((result) => {
            this.getAllUser()
        })
    }

    deleteUser(item: any) {
        console.log("delete user");
        let postData = {
            user_id: item.id
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/removeUser', postData).then((result) => {
            if (result.status) {
                this.getAllUser()
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
}
