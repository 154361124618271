import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditOrderComponent} from '../../modals/edit-order/edit-order.component';
import {AddUpdateChefTaggingComponent} from '../../modals/add-update-chef-tagging/add-update-chef-tagging.component';

@Component({
  selector: 'app-chef-tagging',
  templateUrl: './chef-tagging.component.html',
  styleUrls: ['./chef-tagging.component.css']
})
export class ChefTaggingComponent implements OnInit {

  chef_id;
    chefTagging = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.chef_id = this.utilService.getUserID();
        this.getAllChefTag();
    }

    getAllChefTag() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefTagByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.chefTagging = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    addchefTag() {
        const modalRef = this.modalService.open(AddUpdateChefTaggingComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.chef_id = this.utilService.getUserID();
        modalRef.closed.subscribe((result) => {
            this.getAllChefTag()
        })
    }

    deleteCheftag(chef_tagging_id: any, i: number) {
        let text ="are you sure want to delete this tag" 
        if (window.confirm(text) == true){
        this.apiService.deleteAPI(this.apiService.BASE_URL + 'chefcuisine/deleteCheftag/' + chef_tagging_id).then((result) => {
            if (result.status) {
                this.chefTagging.splice(i, 1);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
}
}

