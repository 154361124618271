import {Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-add-update-user',
    templateUrl: './add-update-user.component.html',
    styleUrls: ['./add-update-user.component.css']
})
export class AddUpdateUserComponent implements OnInit {

    @Input() userType;
    @Input() userDetail;
    name = '';
    email = '';
    password = '';
    phone = '';

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private activeModal: NgbActiveModal,
        private toaster: ToastrService
    ) {
    }

    ngOnInit(): void {
        if (this.userDetail != null) {
            this.name = this.userDetail.name;
            this.email = this.userDetail.email;
            this.phone = this.userDetail.phone;
        }
    }

    saveUser() {
        if (this.name == '') {
            this.toaster.error('please enter name');
            return;
        }
        if (this.email == '') {
            this.toaster.error('please enter email');
            return;
        }
        if (!this.utilService.validateEmail(this.email)) {
            this.toaster.error('please enter valid email');
            return;
        }
        if (this.password == '') {
            this.toaster.error('please enter password');
            return;
        }
        if (this.password.toString().length < 8) {
            this.toaster.error('password must be 8 or more characters long');
            return;
        }
        if (this.phone == '') {
            this.toaster.error('please enter phone');
            return;
        }
        if (this.phone.toString().length != 10) {
            this.toaster.error('please enter valid phone');
            return;
        }


        if (this.userDetail != null) {
            this.updateUser();
            return;
        }

        let userType = 'CUSTOMER';

        this.email = this.email.toLowerCase()

        let postData = {
            type: userType,
            email: this.email,
            phone: this.phone
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'user/getUserByEmailPhone', postData).then((result) => {
            if (result.status) {
                if (result.result.emailUser != null) {
                    this.toaster.error('Email already exist');
                    return
                }
                if (result.result.phoneUser != null) {
                    this.toaster.error('Phone number already exist');
                    return
                }
                // this.signupCognito()
                this.signupAPI()
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    signupCognito() {
        this.apiService.chefSignUp(this.name, this.email.toLowerCase(), this.password, this.phone).then((result) => {
            console.log('result:-' + JSON.stringify(result));
            // this.utilService.setItem(this.utilService.COGNITO_PROFILE, JSON.stringify(result));
            this.signupAPI();
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
            this.toaster.error(error.message);
        });
    }

    signupAPI() {
        let postData = {
            'name': this.name,
            'email': this.email,
            'type': this.userType,
            'password': this.password,
            'phone': this.phone
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'userRole/addUpdateUser', postData).then((result) => {
            if (result.status) {
                this.toaster.success('user registered');
                this.activeModal.close()
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    updateUser() {
        let postData = {
            'name': this.name,
            'email': this.email,
            'type': this.userType,
            'password': this.password,
            'phone': this.phone,
            'id': this.userDetail.id
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'userRole/addUpdateUser', postData).then((result) => {
            if (result.status) {
                this.toaster.success('user updated');
                this.activeModal.close()
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    close() {
        this.activeModal.close();
    }
}
