import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-broadcast-notification',
    templateUrl: './broadcast-notification.component.html',
    styleUrls: ['./broadcast-notification.component.css']
})
export class BroadcastNotificationComponent implements OnInit {

    title = '';
    messaage = '';

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster: ToastrService
    ) {
    }

    ngOnInit(): void {
    }

    submitMessage() {

        if (this.title == '') {
            this.toaster.error('Please enter title');
            return;
        }

        if (this.messaage == '') {
            this.toaster.error('Please enter message');
            return;
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'common/sendBroadcastMessage', {
            title: this.title,
            message: this.messaage
        }).then((result) => {
            if (result.status) {
                this.toaster.success('Message sent');
                this.title = '';
                this.messaage = '';
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + error.message);
            this.toaster.error('Something went wrong:-' + error.message);
        })


    }

}
