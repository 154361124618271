import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'app/service/util.service';
import {ApiService} from '../../service/api.service';

@Component({
    selector: 'app-chef-profile-update-component',
    templateUrl: './chef-profile-update-component.component.html',
    styleUrls: ['./chef-profile-update-component.component.css']
})
export class ChefProfileUpdateComponentComponent implements OnInit {

    @Input() chefStatusId;
    admin_msg_prospect = ''
    profile_pic=""
    image_week=""
    banner3=""
    banner2=""
    banner1=""

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.getChefProfileSubmittedStatus()
    }

    profileDetail = null;
    profileDetailData = null;

    getChefProfileSubmittedStatus() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefstatus/getChefStatusById/' + this.chefStatusId).then((result) => {
            console.log('chefupdated:-' + JSON.stringify(result));
            if (result.status) {
                this.profileDetail = result.result;
                this.profileDetailData = JSON.parse(result.result.data).profile;
                this.admin_msg_prospect = result.result.admin_msg
                if((this.profileDetailData.profile_pic).includes("uploads")){
                    this.profile_pic = this.apiService.PROFILE_BASE_IMAGE_URL + this.profileDetailData.profile_pic
                }
                else {
                    this.profile_pic = this.utilService.getImageCDNURL(this.profileDetailData.profile_pic)
                }
                if((this.profileDetailData.image_week).includes("uploads")){
                    this.image_week = this.apiService.PROFILE_BASE_IMAGE_URL + this.profileDetailData.image_week
                }
                else {
                    this.image_week = this.utilService.getImageCDNURL(this.profileDetailData.image_week)
                }
                if((this.profileDetailData.banner3).includes("uploads")){
                    this.banner3 = this.apiService.PROFILE_BASE_IMAGE_URL + this.profileDetailData.banner3
                }
                else {
                    this.banner3 = this.utilService.getImageCDNURL(this.profileDetailData.banner3)
                }
                if((this.profileDetailData.banner2).includes("uploads")){
                    this.banner2 = this.apiService.PROFILE_BASE_IMAGE_URL + this.profileDetailData.banner2
                }
                else {
                    this.banner2 = this.utilService.getImageCDNURL(this.profileDetailData.banner2)
                }
                if((this.profileDetailData.banner1).includes("uploads")){
                    this.banner1 = this.apiService.PROFILE_BASE_IMAGE_URL + this.profileDetailData.banner1
                }
                else {
                    this.banner1 = this.utilService.getImageCDNURL(this.profileDetailData.banner1)
                }
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    changeChefUpdateStatus(status: number) {
        if (this.profileDetail != null) {
            if (status == 2 && this.admin_msg_prospect == '') {
                alert('Please enter admin message');
                return;
            }
            this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/adminSubmitStatus', {
                status: status,
                admin_msg: this.admin_msg_prospect,
                id: this.chefStatusId
            }).then((result) => {
                console.log('chefupdated:-' + JSON.stringify(result));
                if (result.status) {
                    this.activeModal.close();
                }
            }, (error) => {
                console.log('error:-' + JSON.stringify(error));
            })
        }
    }

    closeModal() {
        this.activeModal.close();
    }
}
