import {Component, OnInit} from '@angular/core';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    old_password = '';
    new_password = '';
    confirm_password = '';

    constructor(
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster: ToastrService,
        private router:Router
    ) {
    }

    ngOnInit(): void {

    }

    validate() {

        console.log(this.old_password)
        console.log(this.new_password)
        console.log(this.confirm_password)

        if (this.old_password == null || this.old_password == undefined || this.old_password == '') {
            this.toaster.error('Please enter old password')
            return false;
        }
        if (this.new_password == null || this.new_password == undefined || this.new_password == '' || this.new_password.toString().length < 8) {
            this.toaster.error('New password must be 8 or more chars long')
            return false;
        }
        if (this.confirm_password == null || this.confirm_password == undefined || this.confirm_password == '' || this.confirm_password != this.new_password) {
            this.toaster.error('New password and Confirm password do not match')
            return false;
        }
        return true;
    }

    update() {
        if (this.validate()) {
            this.apiService.postAPI(this.apiService.BASE_URL + 'user/changeAdminPassword', {
                old_password: this.old_password,
                new_password: this.new_password,
                email: this.utilService.getUserProfile().email
            }).then((result) => {
                if (result.status) {
                    this.old_password = '';
                    this.new_password = '';
                    this.toaster.success("Password changed successfully! Please login again to continue.");
                    this.utilService.setItem(this.utilService.USER_LOGIN, '0');
                    this.router.navigateByUrl('/login');
                } else {
                    this.toaster.error(result.message);
                }

            }, (error) => {
                console.log('something went wrong');
            })
        }
    }

}
