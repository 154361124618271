import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChefCategoryComponent} from '../../modals/chef-category/chef-category.component';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

    chef_id;
    categoryList = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.chef_id = this.utilService.getUserID();
        if (this.chef_id != null && this.chef_id != undefined) {
            this.getAllChefCategory();
        }
    }

    getAllChefCategory() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'category/getAllCategory', {
            chef_id: this.chef_id
        }).then((result) => {
            if (result.status) {
                this.categoryList = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    addCategory() {
        const modalRef = this.modalService.open(ChefCategoryComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllChefCategory()
        })
        modalRef.componentInstance.chefId = this.chef_id;
    }

    editCategory(category) {
        const modalRef = this.modalService.open(ChefCategoryComponent, {
            backdrop: 'static',
            size: <any>'profiletutor',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getAllChefCategory()
        })
        modalRef.componentInstance.chefId = this.chef_id;
        modalRef.componentInstance.chefCategory = category;
    }

    deleteCategory(id) {

    }

}
