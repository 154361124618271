import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {AddUpdateGeofenceComponent} from '../../modals/add-update-geofence/add-update-geofence.component';

declare var google;

@Component({
    selector: 'app-serving-geofencing',
    templateUrl: './serving-geofencing.component.html',
    styleUrls: ['./serving-geofencing.component.css']
})
export class ServingGeofencingComponent implements OnInit {

    geofences = [];
    @ViewChild('gmap', {static: false}) gmapElement: ElementRef;
    map: any;
    currentLat = 28.5272803;
    currentLng = 77.0688997;
    geofencesMap = [];

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        private router: Router,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {

    }

    getAllGeofences() {

        for (let i = 0; i < this.geofencesMap.length; i++) {
            if (this.geofencesMap[i] != null && this.geofencesMap[i].getMap() != null) {
                this.geofencesMap[i].setMap(null);
            }
        }

        this.geofencesMap = [];
        this.geofences = [];

        this.apiService.getAPI(this.apiService.BASE_URL + 'geofence/getAllGeofence').then((result) => {
            if (result.status) {
                this.geofences = result.result;
                var bounds = new google.maps.LatLngBounds();
                for (let i = 0; i < this.geofences.length; i++) {
                    let lat = JSON.parse(this.geofences[i].location).center.lat
                    let lng = JSON.parse(this.geofences[i].location).center.lng
                    let radius = JSON.parse(this.geofences[i].location).radius
                    bounds.extend({lat: lat, lng: lng});
                    this.showMarkerOnMap(lat, lng, radius);
                }

                this.map.fitBounds(bounds);

            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    showMarkerOnMap(lat, lng, radius) {
        const myLatLng = {lat: lat, lng: lng}
        this.map.setCenter(myLatLng);
        const geofence = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            center: myLatLng,
            radius: radius * 1000,
        });

        geofence.setMap(this.map)

        this.geofencesMap.push(geofence);
    }

    addGeofence() {
        const modalRef = this.modalService.open(AddUpdateGeofenceComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            if (result != null && result != undefined) {
                console.log('geofence:-' + JSON.stringify(result));

                this.apiService.postAPI(this.apiService.BASE_URL + 'Geofence/saveGeofence', {
                    name: result.name,
                    location: JSON.stringify(result),
                }).then((result) => {
                    if (result.status) {
                        this.getAllGeofences()
                    }
                }, (error) => {
                    console.log('error:-' + JSON.stringify(error));
                })

            }
        })
    }

    ngAfterViewInit() {
        const mapOptions = {
            zoom: 12,
            center: new google.maps.LatLng(this.currentLat, this.currentLng)
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

        this.getAllGeofences()
    }

    edit(item: any) {
        const modalRef = this.modalService.open(AddUpdateGeofenceComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            if (result != null && result != undefined) {
                console.log('geofence:-' + JSON.stringify(result));

                this.apiService.postAPI(this.apiService.BASE_URL + 'Geofence/updateGeofence', {
                    name: result.name,
                    location: JSON.stringify(result),
                    id: item.id
                }).then((result) => {
                    if (result.status) {
                        this.getAllGeofences()
                    }
                }, (error) => {
                    console.log('error:-' + JSON.stringify(error));
                })

            }
        })
        modalRef.componentInstance.geofenceDetail = item;
    }

    delete(id: any) {
        this.apiService.postAPI(this.apiService.BASE_URL + 'Geofence/deleteGeofence', {
            id: id
        }).then((result) => {
            if (result.status) {
                this.getAllGeofences()
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })

    }
}
