import { Component,  Input, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/service/api.service';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopupComponent implements OnInit {
@Input() cuisineId;
@Input() DeleteText;
@Input() BtnText;
@Input() user_id;
@Input() tagId;
@Input() title;
  constructor(private activeModal: NgbActiveModal, public apiService:ApiService, private toaster: ToastrService) { }
  closeModal() {
    this.activeModal.close()
}
  ngOnInit(): void {
  }
deleteCuisine(){
        if (this.cuisineId == undefined && this.tagId == undefined){
          let postData = {
            user_id : this.user_id
          } 
          this.apiService.postAPI(this.apiService.BASE_URL + 'events/deleteEventStatus' ,postData).then((result) => {
            console.log('result:-' + JSON.stringify(result));
            if (result.status) {
              this.toaster.success("Successfully Delete");
                this.activeModal.close();
              }
          }, (error) => {
              // console.log('error:-' + JSON.stringify(error));
              this.toaster.error("Event not Delete");
              this.activeModal.close();

          })
        }
        else if (this.tagId == undefined && this.user_id == undefined){
          let postData = {
            cuisineId : this.cuisineId
        } 
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/deleteAllAdminCuisine',postData).then((result) => {
            if (result.status) {
              this.toaster.success("Successfully Delete");
              this.activeModal.close();
            }
        }, (error) => {
            // console.log('error:-' + JSON.stringify(error));
            this.toaster.error("Cuisine not Delete");
              this.activeModal.close();
        })
        }
        else if (this.cuisineId == undefined && this.user_id == undefined){
          let postData = {
            tagId : this.tagId
        } 
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/deleteAllAdminChefTag',postData).then((result) => {
            if (result.status) {
              this.toaster.success("Successfully Delete");
              this.activeModal.close();
            }
        }, (error) => {
            // console.log('error:-' + JSON.stringify(error));
            this.toaster.error("Cuisine not Delete");
              this.activeModal.close();
        })
    } 

    }
   
 


}
