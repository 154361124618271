import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

    submitted = false;
    otpVerified = false;
    phone;
    userProfile;

    verification_code = '';
    resend_disabled = true;

    new_password='';
    confirm_password='';

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        public utilService: UtilService,
        private apiService: ApiService,
        private toaster: ToastrService
    ) {
    }

    timeLeft: number = 120000;
    interval;

    setResendEnableTimer() {
        this.timeLeft = 120000;
        this.interval = setInterval(() => {
            // console.log('timeleft:-' + this.timeLeft);
            if (this.timeLeft > 0) {
                this.timeLeft = this.timeLeft - 1000;
                this.resend_disabled = true;
            } else {
                this.clearTimer();
                this.resend_disabled = false;
            }
        }, 1000);
    }

    clearTimer() {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
    }

    ngOnInit(): void {
    }

    submit() {
        if (this.phone == undefined || this.phone.toString().length != 10) {
            this.toaster.error('Please enter valid phone');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'user/generateResetPassword', {
            phone: this.phone,
            type: 'chef'
        }).then((result) => {
            if (result.status) {
                this.toaster.success('OTP sent to phone');
                this.userProfile = result.result;
                this.submitted = true;
                this.setResendEnableTimer();
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    submitOTP() {
        console.log("submit otp");
        if (this.verification_code.toString().length == 0) {
            this.toaster.error("please enter otp");
            return;
        }

        let userType = "chef";

        this.apiService.postAPI(this.apiService.BASE_URL + "user/verifyResetOTP", {
            id: this.userProfile.id,
            type: userType,
            otp: this.verification_code
        }).then((result) => {
            if (result.status) {
                this.otpVerified=true;
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log("something went wrong");
        })

    }

    confirmPassword() {
        if (this.new_password.length==0) {
            this.toaster.error("please enter new password");
            return;
        }
        if (this.new_password.length<8) {
            this.toaster.error("password must be 8 or more characters");
            return;
        }
        if (this.confirm_password.length==0) {
            this.toaster.error("please enter confirm password");
            return;
        }
        if (this.confirm_password.length<8) {
            this.toaster.error("password must be 8 or more characters");
            return;
        }

        if(this.new_password.toString()!=this.confirm_password.toString()){
            this.toaster.error("Please enter same new and confirm password");
            return;
        }

        let userType = "chef";

        this.apiService.postAPI(this.apiService.BASE_URL + "user/resetPassword", {
            id: this.userProfile.id,
            type: userType,
            password: this.new_password
        }).then((result) => {
            if (result.status) {
                this.utilService.setItem(this.utilService.USER_LOGIN, '1');
                this.utilService.setItem(this.utilService.USER_PROFILE, JSON.stringify(result.result));
                this.activeModal.close();
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log("something went wrong");
        })
    }

    closeModal() {
        this.activeModal.close();
    }
}
