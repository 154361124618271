import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../service/api.service';
import {CropImageComponent} from '../crop-image/crop-image.component';
import {ToastrService} from 'ngx-toastr';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-add-update-cuisine',
    templateUrl: './add-update-cuisine.component.html',
    styleUrls: ['./add-update-cuisine.component.css']
})
export class AddUpdateCuisineComponent implements OnInit {

    @Input() cuisineDetail;
    name = '';
    tag = '';
    loading: boolean = false; 
    default_image: any = 'assets/img/default_banner.png';
    selectedFile;
    fileTarget;

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        private toaster: ToastrService,
        public utilService: UtilService
    ) {

    }

    ngOnInit(): void {
        if (this.cuisineDetail != null) {
            this.name = this.cuisineDetail.name;
            this.tag = this.cuisineDetail.tag;
            this.default_image = this.cuisineDetail.image;
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    saveCuisine() {
        if (this.cuisineDetail != null) {
            //update
            this.updateCuisine()
        } else {
            //add
            this.addCuisine()
        }
    }

    updateCuisine() {
        if (this.name == '') {
            this.toaster.error('Please enter name');
        }
        if (this.tag == '') {
            this.toaster.error('Please enter tag');
        }

        if (this.default_image == 'assets/img/default_banner.png') {
            this.toaster.error('Please select image')
        }

        let post = {
            id: this.cuisineDetail.id,
            name: this.name,
            tag: this.tag,
            image: this.default_image
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/updateCuisine', post).then((result) => {
            if (result.status) {
                this.activeModal.close();
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })

    }

    addCuisine() {
        if (this.name == '') {
            this.toaster.error('Please enter name');
        }
        if (this.tag == '') {
            this.toaster.error('Please enter tag');
        }

        if (this.default_image == 'assets/img/default_banner.png') {
            this.toaster.error('Please select image')
        }

        let post = {
            name: this.name,
            tag: this.tag,
            image: this.default_image
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'cuisine/addCuisine', post).then((result) => {
            if (result.status) {
                this.activeModal.close();
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })

    }

    // onselectimage(e) {
    //     if (e.target.files) {
    //         const target: DataTransfer = <DataTransfer>(e.target);
    //         this.fileTarget = target;
    //         const reader: FileReader = new FileReader();
    //         let file = this.fileTarget.files[0];
    //         reader.readAsDataURL(this.fileTarget.files[0]);
    //         reader.onload = (e: any) => {
    //             let name = this.fileTarget.files[0].name;
    //             if (
    //                 name.toLowerCase().includes('.jpg')
    //                 || name.toLowerCase().includes('.jpeg')
    //                 || name.toLowerCase().includes('.png')
    //             ) {
    //                 this.default_image = reader.result;
    //             } else {
    //                 alert('Please upload image file');
    //             }
    //         }
    //     }
    // }
    openImageCropper(type, index) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage(type, result.image, index)
            }
        });

        if (type == 'CUISINE') {
            modal.componentInstance.ratio = 1 / 1;
            modal.componentInstance.width = 300;
        }

    }

    uploadImage(type, file, index) {
        this.loading = true;
        let postData = {
            image: file,
        }

        // if (type == 'S1_IMAGE1') {
        //     postData['image_name'] = 's1b1.png';
        // } else if (type == 'S2_IMAGE') {
        //     postData['image_name'] = 's2.png';
        // }

        this.apiService.postAPI(this.apiService.BASE_URL + 'common/uploadCuisineImage', postData).then((result) => {
            if (result.status) {
                this.default_image = result.result;
                this.loading = false;
            } else {
                this.toaster.error(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

}
