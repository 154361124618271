import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../service/api.service';
import {ToastrService} from 'ngx-toastr';
import {CropImageComponent} from '../../modals/crop-image/crop-image.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../service/util.service';

@Component({
    selector: 'app-become-chef-cms',
    templateUrl: './become-chef-cms.component.html',
    styleUrls: ['./become-chef-cms.component.css']
})
export class BecomeChefCMSComponent implements OnInit {
    isLoading: boolean = false; // Variable to track loader state
    cms = {
        s1_title1: '',
        s1_image: '',
        s1_title2: '',
        s1_title3: '',
        s2_title: '',
        s2_image: '',
        s2_descriptions: [],
        s3_heading: '',
        s3c1title: '',
        s3c1description: '',
        s3c3title: '',
        s3c3description: '',
        s3c2title: '',
        s3c2description: '',
        s3c4title: '',
        s3c4description: '',
        s4_heading: '',
        s4c1title: '',
        s4c1description: '',
        s4c2title: '',
        s4c2description: '',
        s4c3title: '',
        s4c3description: '',
        S5: {
            heading: '',
            title1: '',
            title2: '',
            title3: '',
            title4: '',
            description1: '',
            description2: '',
            description3: '',
            description4: '',
        },
        s6_heading: '',
        s6description: '',
        S7: {
            heading: '',
            chefs: []
        },
        s8_heading: '',
        S9: {
            heading: '',
            data: []
        },
        S10: {
            heading: ''
        }
    }

    // S1_IMAGE1 = this.apiService.BASE_IMAGE_URL + '/uploads/bac/s1b1.png';
    // S1_IMAGE1 = '';
    // S1_IMAGE2 = this.apiService.BASE_IMAGE_URL + '/uploads/bac/s1b2.png';
    // S1_IMAGE3 = this.apiService.BASE_IMAGE_URL + '/uploads/bac/s1b3.png';

    // s2Image = this.apiService.BASE_IMAGE_URL + '/uploads/bac/s2.png';

    allChefs = [];

    constructor(
        private apiService: ApiService,
        private toaster: ToastrService,
        private modalService: NgbModal,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.getCMS()
        this.getAllChefs();
    }
    showLoader() {
        this.isLoading = true;
        console.log("loader")
      }
    
      // Function to hide loader
      hideLoader() {
        this.isLoading = false;
      }
    getAllChefs() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'user/getAllChefs').then((result) => {
            if (result.status) {
                this.allChefs = result.result;
                console.log('allchefs:-' + JSON.stringify(this.allChefs));
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    getCMS() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/getAllCMS', {
            names: ['BAC']
        }).then((result) => {
            if (result.status) {
                if (result.result[0].content != null && result.result[0].content != undefined && result.result[0].content != '') {
                    console.log('cms:-' + JSON.stringify(JSON.parse(result.result[0].content), null, 4));
                    this.cms = JSON.parse(result.result[0].content);

                }
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    saveCMS() {
        // console.log("cms:-"+JSON.stringify(this.cms,null,4));
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/addCMS', {
            name: 'BAC',
            content: JSON.stringify(this.cms)
        }).then((result) => {
            if (result.status) {
                this.toaster.success('Data saved');
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    openImageCropper(type, index) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadImage(type, result.image, index)
            }
        });

        if (type == 'S1_IMAGE1') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'S2_IMAGE') {
            modal.componentInstance.ratio = 4 / 1;
            modal.componentInstance.width = 1920;
        } else if (type == 'S9') {
            modal.componentInstance.ratio = 7 / 3;
            modal.componentInstance.width = 700;
        }

    }

    onselectimage(e, type) {
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            if (
                type == 'S2_IMAGE' || type == 'S7C1_IMAGE' || type == 'S7C2_IMAGE' || type == 'S1_IMAGE1' || type == 'S1_IMAGE2' || type == 'S1_IMAGE3'
            ) {
                reader.readAsDataURL(target.files[0]);
                reader.onload = (e: any) => {
                    let name = file.name;
                    if (
                        name.toLowerCase().includes('.png')
                    ) {
                        this.uploadImageView(type, file)
                    } else {
                        alert('Please upload png image file');
                    }
                }
                e.target.value = null;
            } else if (type == 'S6_VIDEO') {
                reader.readAsDataURL(target.files[0]);
                reader.onload = (e: any) => {
                    let name = file.name;
                    if (
                        name.toLowerCase().includes('.mp4')
                    ) {
                        this.uploadImageView(type, file)
                    } else {
                        alert('Please upload png image file');
                    }
                }
                e.target.value = null;
            }

        }
    }

    onS9ImageSelected(e, index) {
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            reader.readAsDataURL(target.files[0]);
            reader.onload = (e: any) => {
                let name = file.name;
                if (
                    name.toLowerCase().includes('.png')
                    || name.toLowerCase().includes('.jpg')
                    || name.toLowerCase().includes('.jpeg')
                ) {
                    let formData = new FormData();
                    formData.append('image', file);
                    this.apiService.postAPI(this.apiService.BASE_URL + 'common/bacImagesName', formData).then((result) => {
                        if (result.status) {
                            this.cms.S9.data[index].image = result.result;
                        } else {
                            alert(result.message);
                        }
                    }, (error) => {
                        console.log('error:-' + JSON.stringify(error));
                    })
                } else {
                    alert('Please upload png image file');
                }
            }
            e.target.value = null;
        }
    }

    openChefImageCropper(index, position) {
        let modal = this.modalService.open(CropImageComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        })
        modal.closed.subscribe((result) => {
            if (result != null && result != undefined && result.hasOwnProperty('image')) {
                console.log('image:-' + result.image);
                this.uploadChefImage(result.image, index, position);
            }
        });

        modal.componentInstance.ratio = 3 / 4;
        modal.componentInstance.width = 384;

    }


    uploadChefImage(image, index, position) {
        this.showLoader();
        let postData = {
            image: image
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/becomeAChefCMSBase64', postData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');

                if (position == 1) {
                    this.cms.S7.chefs[index]['image1'] = result.result;
                } else if (position == 2) {
                    this.cms.S7.chefs[index]['image2'] = result.result;
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
                this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    uploadImageView(type, file) {
        let formData = new FormData();
        formData.append('image', file);
        if (type == 'S2_IMAGE') {
            formData.append('file_name', 's2.png')
        }
        if (type == 'S7C1_IMAGE') {
            formData.append('file_name', 's7_1.png')
        }
        if (type == 'S1_IMAGE1') {
            formData.append('file_name', 's1b1.png')
        }
        if (type == 'S1_IMAGE2') {
            formData.append('file_name', 's1b2.png')
        }
        if (type == 'S1_IMAGE3') {
            formData.append('file_name', 's1b3.png')
        }
        if (type == 'S7C2_IMAGE') {
            formData.append('file_name', 's7_2.png')
        }
        if (type == 'S6_VIDEO') {
            formData.append('file_name', 's5_video.mp4')
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'common/bacImages', formData).then((result) => {
            if (result.status) {
                alert('file uploaded')
            } else {
                alert(result.message);
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    uploadImage(type, file, index) {
        this.showLoader();
        let postData = {
            image: file,
        }

        if (type == 'S1_IMAGE1') {
            postData['image_name'] = 's1b1.png';
        } else if (type == 'S2_IMAGE') {
            postData['image_name'] = 's2.png';
        }

        this.apiService.postAPI(this.apiService.BASE_URL + 'common/becomeAChefCMSBase64', postData).then((result) => {
            if (result.status) {
                this.toaster.success('File uploaded');
                if (type == 'S1_IMAGE1') {
                    // this.S1_IMAGE1 = this.apiService.BASE_IMAGE_URL + result.result;
                    // console.log(this.S1_IMAGE1);
                    this.cms.s1_image = result.result;
                    this.saveCMS()
                } else if (type == 'S2_IMAGE') {
                    // this.s2Image = this.apiService.BASE_IMAGE_URL + result.result;
                    // console.log(this.s2Image);
                    this.cms.s2_image = result.result;
                    this.saveCMS();
                } else if (type == 'S9') {
                    this.cms.S9.data[index]['image'] = result.result;
                    console.log(JSON.stringify(this.cms.S9.data, null, 4));
                }
                this.hideLoader();
            } else {
                this.toaster.error(result.message);
                this.hideLoader();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })


    }

    onSelectChefImage(e, index, position) {
        this.showLoader();
        if (e.target.files) {
            const target: DataTransfer = <DataTransfer>(e.target);
            const reader: FileReader = new FileReader();
            let file = target.files[0];
            reader.readAsDataURL(target.files[0]);
            reader.onload = (e: any) => {
                let name = file.name;
                if (
                    name.toLowerCase().includes('.jpg')
                    || name.toLowerCase().includes('.jpeg')
                    || name.toLowerCase().includes('.png')
                ) {
                    let formData = new FormData();
                    formData.append('image', file);
                    this.apiService.postAPI(this.apiService.BASE_URL + 'common/homeCMSImages', formData).then((result) => {
                        if (result.status) {
                            if (position == 1) {
                                this.cms.S7.chefs[index]['image1'] = result.result;
                            } else if (position == 2) {
                                this.cms.S7.chefs[index]['image2'] = result.result;
                            }
                            this.hideLoader();
                        } else {
                            alert(result.message);
                            this.hideLoader();
                        }
                    }, (error) => {
                        console.log('error:-' + JSON.stringify(error));
                    })
                } else {
                    alert('Please upload image file');
                }
            }
        }
    }

    addChef() {
        this.cms.S7.chefs.push({
            image1: 'assets/img/default_banner.png',
            image2: 'assets/img/default_banner.png',
            quote: '',
            description: ''
        })
    }

    addS2Description() {
        this.cms.s2_descriptions.push({
            data: ''
        });
    }

    removeS2Description(i) {
        this.cms.s2_descriptions.splice(i, 1);
    }

    addS9Data() {
        this.cms.S9.data.push({
            title: '',
            description: ''
        })
    }

    removeHomeCMSChef(i: number) {
        this.cms.S7.chefs.splice(i, 1);
    }


}
