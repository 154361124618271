import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UtilService} from '../../service/util.service';
import {AddUpdateCuisineComponent} from '../../modals/add-update-cuisine/add-update-cuisine.component';

@Component({
  selector: 'app-subscribed-users',
  templateUrl: './subscribed-users.component.html',
  styleUrls: ['./subscribed-users.component.css']
})
export class SubscribedUsersComponent implements OnInit {

  subscription = [];
date:any
  constructor(
      private apiService: ApiService, private modalService: NgbModal, private router: Router,
      public utilService: UtilService
  ) {
  }

  ngOnInit(): void {
    this.getAllSubscription();
  }

  getAllSubscription() {
    this.apiService.getAPI(this.apiService.BASE_URL + 'common/getAllSubscribedUsers').then((result) => {
      if (result.status) {
        this.subscription = result.result;
        console.log(result.created_at,"result")
        this.date =new Date(result.created_at)
        console.log(this.date,"date");
        
      }
    }, (error) => {
      console.log('error:-' + JSON.stringify(error));
    })
  }

}
