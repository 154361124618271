import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChefCategoryComponent} from '../../modals/chef-category/chef-category.component';
import {AddUpdateItemComponent} from '../../modals/add-update-item/add-update-item.component';
import {AddChefCuisineComponent} from '../../modals/add-chef-cuisine/add-chef-cuisine.component';
import {UtilService} from '../../service/util.service';
import {AddUpdateChefTaggingComponent} from '../../modals/add-update-chef-tagging/add-update-chef-tagging.component';
@Component({
    selector: 'app-chef-detail',
    templateUrl: './chef-detail.component.html',
    styleUrls: ['./chef-detail.component.css']
})
export class ChefDetailComponent implements OnInit {

    chef_id;
    chefCuisines = [];
    items = [];
    chefTagging = [];
    showAccordion: boolean[] = [false, false]; 
    chef_name;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private modalService: NgbModal,
        public utilService: UtilService
    ) {
        // this.activatedRoute.queryParams.subscribe(params => {
        //   if (this.router.getCurrentNavigation().extras) {
        //     this.chef_id = this.router.getCurrentNavigation().extras;
        //     console.log("chef_id:-"+this.chef_id);
        //   }
        // });
        this.chef_id = this.activatedRoute.snapshot.paramMap.get('id');
        console.log('chef_id:-' + this.chef_id);
        this.chef_name=localStorage.getItem("chef_name")
    }

    ngOnInit(): void {
        console.log('chef_id:-' + this.chef_id);
        if (this.chef_id != null && this.chef_id != undefined) {
            this.getAllChefCuisines();
            this.getChefItems();
            this.getAllChefTag();
        }
    }
    showToggle(index: number) {
        this.showAccordion[index] = !this.showAccordion[index];
        for (let i = 0; i < this.showAccordion.length; i++) {
            if (i !== index) {
                this.showAccordion[i] = false;
            }
        }
    }
    getAllChefCuisines() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefCuisineByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.chefCuisines = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    addCuisine() {
        const modalRef = this.modalService.open(AddChefCuisineComponent, {
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.chef_id = this.chef_id;
        modalRef.closed.subscribe((result) => {
            this.getAllChefCuisines()
        })
    }

    deleteChefCuisine(chef_cuisine_id: any, i: number) {
        let text ="are you sure want to delete this cuisine" 
        if (window.confirm(text) == true){
        this.apiService.deleteAPI(this.apiService.BASE_URL + 'chefcuisine/deleteChefCuisine/' + chef_cuisine_id).then((result) => {
            if (result.status) {
                this.chefCuisines.splice(i, 1);
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    }

    getChefItems() {
        this.apiService.postAPI(this.apiService.BASE_URL + 'items/getItemsByChefId', {
            chef_id: this.chef_id
        }).then((result) => {
            if (result.status) {
                this.items = result.result;
            }
        }, (error) => {
            console.log('something went wrong');
        })
    }

    // addCategory() {
    //     const modalRef = this.modalService.open(ChefCategoryComponent, {
    //         backdrop: 'static',
    //         size: <any>'profiletutor',
    //         keyboard: false,
    //         centered: true
    //     });
    //     modalRef.closed.subscribe((result) => {
    //         console.log('dismissed:-' + JSON.stringify(result));
    //         this.getAllChefCategory()
    //     })
    //     modalRef.componentInstance.chefId = this.chef_id;
    // }
    //
    // editCategory(category) {
    //     const modalRef = this.modalService.open(ChefCategoryComponent, {
    //         backdrop: 'static',
    //         size: <any>'profiletutor',
    //         keyboard: false,
    //         centered: true
    //     });
    //     modalRef.closed.subscribe((result) => {
    //         console.log('dismissed:-' + JSON.stringify(result));
    //         this.getAllChefCategory()
    //     })
    //     modalRef.componentInstance.chefId = this.chef_id;
    //     modalRef.componentInstance.chefCategory = category;
    // }
    //
    // deleteCategory(id) {
    //
    // }

    addItem() {
        const modalRef = this.modalService.open(AddUpdateItemComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getChefItems()
        })
        modalRef.componentInstance.chef_id = this.chef_id;
        // modalRef.componentInstance.chefCategory = category;
    }

    editItem(item: any) {
        const modalRef = this.modalService.open(AddUpdateItemComponent, {
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            centered: true
        });
        modalRef.closed.subscribe((result) => {
            console.log('dismissed:-' + JSON.stringify(result));
            this.getChefItems()
        })
        modalRef.componentInstance.chef_id = this.chef_id;
        modalRef.componentInstance.itemDetail = item;
    }

    deleteItem(id: any) {
        let text ="are you sure want to delete this item" 
        if (window.confirm(text) == true){
            this.apiService.deleteAPI(this.apiService.BASE_URL + 'items/deleteItems/' + id).then((result) => {
                if (result.status) {
                    this.getChefItems()
                }
            }, (error) => {
                console.log('something went wrong');
            })
        }
        } 


        getAllChefTag() {
            this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefTagByUserId/' + this.chef_id).then((result) => {
                if (result.status) {
                    this.chefTagging = result.result;
                }
            }, (error) => {
                console.log('something went wrong');
            })
        }
    
        addchefTag() {
            const modalRef = this.modalService.open(AddUpdateChefTaggingComponent, {
                backdrop: 'static',
                size: 'lg',
                keyboard: false,
                centered: true
            });
            modalRef.componentInstance.chef_id = this.chef_id;
            modalRef.closed.subscribe((result) => {
                this.getAllChefTag()
            })
        }
    
        deleteCheftag(chef_tagging_id: any, i: number) {
            let text ="are you sure want to delete this tag" 
            if (window.confirm(text) == true){
            this.apiService.deleteAPI(this.apiService.BASE_URL + 'chefcuisine/deleteCheftag/' + chef_tagging_id).then((result) => {
                if (result.status) {
                    this.chefTagging.splice(i, 1);
                }
            }, (error) => {
                console.log('something went wrong');
            })
        }
    }
}
