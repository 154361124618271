import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../service/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../service/util.service';

@Component({
    selector: 'app-waiting-food-item-modal',
    templateUrl: './waiting-food-item-modal.component.html',
    styleUrls: ['./waiting-food-item-modal.component.css']
})
export class WaitingFoodItemModalComponent implements OnInit {

    @Input() itemStatus;

    chef_id = '';
    numberArray: number[] = [];
    food_arrange;
    categoryList = [];
    cuisineList = [];
    foodTypeList = [];
    qtyTypeList = [];
    foodCategoryList = [];
    foodAvailability = [];
    chefMenuList = [];
    chefDietList = [];
    chefMenu: any;
    chefMealList = [];
    chefTagList = [];
    chefMeal: any;
    chefDiet: any;
    chefTag: any;
    displayTag: any;
    name = '';
    category = '';
    food_type = '';
    food_availablity = '';
    admin_msg = '';
    base_price = 0;
    person_service = 0;
    qty = 0;
    short_desc = '';
    long_desc = '';
    disclaimer = '';
    qty_type = '';
    prep_time = '';
    food_category = '';
    cuisine = '';
    fileEvent;
    special_item = false;
    spicy_level = '';
    calories = 0;
    uploadedFileList = [];
    disabled: any;
    declined: any;
    itemsdsf: any;
    newFilterString: any;
    selectedValues: string[] = [];
    selectedMealValues: string[] = [];
    selectedDietValues: string[] = [];
    selectedTagValues: string[] = [];
    selectedDisplayTagValues: string[] = [];
    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private apiService: ApiService,
        public toaster: ToastrService,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        // this.getCategories();

        if (this.itemStatus != null) {
            this.chef_id = this.itemStatus.chef_id;
        }
        this.getMenuList();
        this.getCuisines();
        this.getAllFoodType();
        this.getAllMealType();
        this.getAllDietType();
        this.getMasterTagging();
        this.populateNumberArray();
        // this.foodTypeList.push('Veg');
        // this.foodTypeList.push('Egg');
        // this.foodTypeList.push('Non Veg');
        // this.foodTypeList.push('Vegan');
        // this.foodTypeList.push('Keto');

        this.categoryList.push({ id: '1', name: 'Packaged Food' })
        this.categoryList.push({ id: '2', name: 'Cooked Food' })
        this.foodAvailability.push({ id: '1', name: 'Available' })
        this.foodAvailability.push({ id: '0', name: 'Not serving this time' })
        this.qtyTypeList.push({ id: '1', name: 'gms' });
        this.qtyTypeList.push({ id: '2', name: 'kg' });
        this.qtyTypeList.push({ id: '3', name: 'pcs' });
        this.qtyTypeList.push({ id: '4', name: 'l' });
        this.qtyTypeList.push({ id: '5', name: 'ml' });
        this.qtyTypeList.push({ id: '6', name: 'in' });
        this.qtyTypeList.push({ id: '7', name: 'Combo Meal' });

        this.foodCategoryList.push({ id: '1', name: 'Beverage' });
        this.foodCategoryList.push({ id: '2', name: 'Bread' });
        this.foodCategoryList.push({ id: '3', name: 'Dessert' });
        this.foodCategoryList.push({ id: '4', name: 'Main Course' });
        this.foodCategoryList.push({ id: '6', name: 'Noodle' });
        this.foodCategoryList.push({ id: '7', name: 'Rice' });
        this.foodCategoryList.push({ id: '8', name: 'Starter' });

        // this.chefMenuList.push({id: '1', name: 'Appetisers'});
        // this.chefMenuList.push({id: '2', name: 'Mains'});
        // this.chefMenuList.push({id: '3', name: 'Bestsellers'});
        // this.chefMenuList.push({id: '4', name: 'Mutton'});
        // this.chefMenuList.push({id: '6', name: 'Desserts'});

        console.log('itemstatus:-' + JSON.stringify(this.itemStatus));

        if (this.itemStatus != null) {
            let itemDetail = JSON.parse(this.itemStatus.data).food_item;
            this.chef_id = this.itemStatus.chef_id;
            this.name = itemDetail.name;
            this.category = itemDetail.category;
            if (itemDetail.images != null && itemDetail.images != '') {
                this.uploadedFileList = JSON.parse(itemDetail.images);
            }
            this.food_type = itemDetail.food_type;
            console.log(itemDetail,"itemDetail");
            
            this.food_availablity = itemDetail.food_avaibility;
            this.food_arrange=itemDetail.food_arrange;
            this.base_price = itemDetail.base_price;
            this.short_desc = itemDetail.short_desc;
            this.long_desc = itemDetail.long_desc;
            this.disclaimer = itemDetail.disclaimer;
            this.qty = itemDetail.qty;
            this.qty_type = itemDetail.qty_type;
            this.person_service = itemDetail.person_service;
            this.prep_time = itemDetail.prep_time;
            this.food_category = itemDetail.food_category;
            console.log(itemDetail, "menu list array");

            this.chefMenu = this.convertStringToArray(itemDetail.chefMenu);
            this.chefMeal = this.convertStringToArray(itemDetail.chefMeal);
            this.chefDiet = this.convertStringToArray(itemDetail.chefDiet);
            this.chefTag = this.convertStringToArray(itemDetail.chefTag);
            this.displayTag = itemDetail.displayTag?.split(',');
            // this.displayTag = this.convertStringToArray(itemDetail.displayTag);
            // console.log(this.displayTag,"deepak");
            
            this.cuisine = itemDetail.cuisine;
            this.special_item = false;
            this.spicy_level = itemDetail.spicy_level;
            this.disabled = this.itemStatus.status
            if (itemDetail.is_special == 1) {
                this.special_item = true;
            }
            this.calories = itemDetail.calories;
            if (this.itemStatus.admin_msg != null) {
                this.admin_msg = this.itemStatus.admin_msg;
            }
        }
    }
    populateNumberArray(): void {
        for (let i = 1; i <= 100; i++) {
            this.numberArray.push(i);
        }
    }
    getMenuList() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllMenuList').then((result) => {
            if (result.status) {
                this.chefMenuList = result.result;
                console.log(this.chefMenuList, "menu list");

            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    getAllMealType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllmealtype').then((result) => {
            if (result.status) {
                this.chefMealList = result.result;
                console.log(this.chefMealList, "Neal type list");

            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    getAllFoodType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAllFoodType').then((result) => {
            if (result.status) {
                this.foodTypeList = result.result;
                console.log(this.foodTypeList, "food type list");

            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    getAllDietType() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'items/getAlldiettype').then((result) => {
            if (result.status) {
                this.chefDietList = result.result;
                console.log(this.chefDietList, "food type list");

            }
        }, (error) => {
            console.log('something went wrong');
        })
    }
    isMealSelected(menuId: number): boolean {
        return this.chefMeal?.includes(menuId);
    }
    isSelected(menuId: number): boolean {
        return this.chefMenu?.includes(menuId);
    }
    isDietSelected(menuId: number): boolean {
        return this.chefDiet?.includes(menuId);
    }
    isTagSelected(menuId: number): boolean {
        return this.chefTag?.includes(menuId);
    }
    // isDisplayTagSelected(menuId: number): boolean {
    //     return this.displayTag?.includes(menuId.toString());
    // }
    isDisplayTagSelected(tagname: string): boolean {
        // const displayTags = this.displayTag?.split(',').map(tag => tag.trim());
        return this.displayTag?.includes(tagname);
    }
    convertStringToArray(inputString: string): number[] {
        console.log(inputString, "inpout");

        const stringArray = inputString?.split(',');
        return stringArray?.map(Number);
    }
    getCuisines() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefCuisineByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.cuisineList = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }

    getMasterTagging() {
        this.apiService.getAPI(this.apiService.BASE_URL + 'chefcuisine/getChefTagByUserId/' + this.chef_id).then((result) => {
            if (result.status) {
                this.chefTagList = result.result;
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
    onCheckboxChange(value: string) {
        if (this.selectedValues.includes(value)) {
            this.selectedValues = this.selectedValues.filter(val => val !== value);
        } else {
            this.selectedValues.push(value);
        }
    }
    onCheckboxChangeMeal(value: string) {
        if (this.selectedMealValues.includes(value)) {
            this.selectedMealValues = this.selectedMealValues.filter(val => val !== value);
        } else {
            this.selectedMealValues.push(value);
        }

    }
    onCheckboxChangeDiet(value: string) {
        if (this.selectedDietValues.includes(value)) {
            this.selectedDietValues = this.selectedDietValues.filter(val => val !== value);
        } else {
            this.selectedDietValues.push(value);
        }

    }
    onCheckboxChangeTag(value: string) {
        if (this.selectedTagValues.includes(value)) {
            this.selectedTagValues = this.selectedTagValues.filter(val => val !== value);
        } else {
            this.selectedTagValues.push(value);
        }

    }
    onCheckboxChangeDisplayTag(value: string) {
        if (this.selectedDisplayTagValues.includes(value)) {
            this.selectedDisplayTagValues = this.selectedDisplayTagValues.filter(val => val !== value);
        } else {
            this.selectedDisplayTagValues.push(value);
        }

    }
    closeModal() {
        this.activeModal.close();
    }


    updateStatus(status: number) {
        if (this.admin_msg == '') {
            alert('Please enter admin message');
            return;
        }
        this.apiService.postAPI(this.apiService.BASE_URL + 'chefstatus/adminSubmitStatus', {
            status: status,
            admin_msg: this.admin_msg,
            id: this.itemStatus.id
        }).then((result) => {
            console.log('chefupdated:-' + JSON.stringify(result));
            if (result.status) {
                this.activeModal.close();
            }
        }, (error) => {
            console.log('error:-' + JSON.stringify(error));
        })
    }
}
